// Import
import { Button, ButtonGroup, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  InputCst,
  SelectCst,
  AutocompleteCst,
  MultiSelectCst,
  RadioCst,
  DataPickerCst,
  SingleCheckboxCst,
  MultiTextCst,
} from "@components/InputCst";
import { ColumnInterface } from "@components/Table/types";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImageCst from "@components/BackgroundImageCst";
import { defaultValidation, isTrueValue } from "@utils/utilsValidators";
import ButtonCst from "@components/ButtonCst";
import DownloaderUploaderCst from "@components/DownloaderUploaderCst";
import { useNavigate, useParams } from "react-router-dom";
import InputChipsCst from "@components/InputCst/InpuctChipsCst";
import InputAssociationChipsCst from "@components/InputCst/InputAssociationChipsCst";
import AssociationLibraryCst from "@components/AssociationLibraryCst";
import AssociationTableCst from "@components/AssociationTableCst";
import { PagesID } from "@utils/utilsConfigurations";
import { ServicesURL } from "@utils/utilsApi";
import ListDraggable from "@components/InputCst/LIstDraggable";
import { parseString } from "../utils";
import dayjs from "dayjs";
import { MediaType } from "src/interfaces/Media";
import { scrollToElement } from "@utils/utilsScrollToElement";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import BackButtonWarning from "@components/BackButtonWarning";
import StaticOptions from "../../../../../../assets/static/options.json";

// Interface
interface CoursesFormCstInterface {
  row?: any;
  columns: ColumnInterface[];
  additionalColumns?: ColumnInterface[];
  actionsForForms?: ColumnInterface[];
  onSubmit: (inputForm: any, fileUploaded: any) => void;
  formActionType?: string[];
  dragDrop?: boolean;
  backButton?: () => any;
}
interface IAssociations {
  id: number;
  label: string;
}

// eslint-disable-next-line no-empty-pattern
const CoursesFormCst: React.FC<CoursesFormCstInterface> = ({
  row,
  columns,
  additionalColumns = [],
  actionsForForms = [],
  dragDrop,
  onSubmit,
  backButton,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation(["form", "ecm_roles", "breadcrumb"]);
  const navigate = useNavigate();
  const [openAssociationActivity, setOpenAssociationActivity] = useState(false);
  const [openAssociationCover, setOpenAssociationCover] = useState(false);
  const [openAssociationVideo, setOpenAssociationVideo] = useState(false);
  const [openAssociationLearning, setOpenAssociationLearning] = useState(false);
  const [isStandalone, setIsStandalone] = useState<boolean>(false);
  const [openAssociationMaterials, setOpenAssociationMaterials] =
    useState(false);
  const [openAssociationBadge, setOpenAssociationBadge] = useState(false);
  const [openAssociationCertificate, setOpenAssociationCertificate] =
    useState(false);
  const [openAssociationKnowledgeDomain, setOpenAssociationKnowledgeDomain] =
    useState(false);

  const [openAssociationLegalSignature, setOpenAssociationLegalSignature] =
    useState(false);
  const [openAssociationSurvey, setOpenAssociationSurvey] = useState(false);
  const [openAssociationUser, setOpenAssociationUser] = useState(false);

  const { id = "1" } = useParams();
  const [activityAssociation, setActivityAssociation] = useState<any>([]);
  const [coverAssociation, setCoverAssociation] = useState<IAssociations[]>([]);
  const [videoAssociation, setVideoAssociation] = useState<IAssociations[]>([]);
  const [materialsAssociation, setMaterialsAssociation] = useState([]);
  const [testAssociation, setTestAssociation] = useState<IAssociations[]>([]);
  const [badgeAssociation, setBadgesAssociation] = useState<any>([]);
  const [certificatesAssociation, setCertificatesAssociation] = useState<any>(
    [],
  );
  const [knowledgeDomainAssociation, setKnowledgeDomainAssociation] =
    useState<any>([]);

  const [legalSignatureAssociation, setLegalSignatureAssociation] =
    useState<any>([]);
  const [surveyAssociation, setSurveyAssociation] = useState<IAssociations[]>(
    [],
  );
  const [userAssociation, setUserAssociation] = useState<any>([]);

  const [inputForm, setInputForm] = useState<any>({});
  const [errorInputForm, setErrorInputForm] = useState<any>({});
  const [fileUploaded, setFileUploaded] = useState<any>(null);
  const [associationAccesorKey, setAssociationAccesorKey] = useState<
    string | null
  >(null);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isBlocked, setIsBlocked] = useState(false);

  const IncludesChildrenColumns = useMemo(() => {
    if (columns) {
      return [
        ...columns.map((columnEl: any) => {
          if (
            Object.keys(columnEl).includes("form_box") &&
            columnEl?.form_box.length > 0
          ) {
            return [columnEl, ...columnEl.form_box];
          } else {
            return columnEl;
          }
        }),
      ].flat();
    } else {
      return [];
    }
  }, [columns]);
  const getBase64 = (file: any, callBack: any) => {
    let reader = new FileReader();
    if (!file) return;
    reader.readAsDataURL(file);
    reader.onload = function () {
      callBack(reader.result);
    };
    reader.onerror = function (error) {
      console.error("getBase64 error: ", error);
    };
  };
  const getInitialData = () => {
    let initialState: any = {};

    IncludesChildrenColumns?.forEach(({ accessorKey }) => {
      switch (accessorKey) {
        case "didactical_activity":
          // TODO: CHANGE LABEL
          const activities = row[accessorKey] || [];
          setActivityAssociation(
            [
              ...activities.map((elem: any) => {
                return {
                  id: elem.id,
                  label: `${elem.label}`,
                };
              }),
            ] || [],
          );
          break;
        /*     case "course_activities":
  setActivityAssociation(row[accessorKey]);
  break; */
        case "cover_id":
          row[accessorKey] && setCoverAssociation([row[accessorKey]]);
          break;
        case "test_id":
          row[accessorKey] && setTestAssociation([row[accessorKey]]);
          break;
        case "video_id":
          row[accessorKey] && setVideoAssociation([row[accessorKey]]);
          break;
        case "didactical_material":
          row[accessorKey] && setMaterialsAssociation(row[accessorKey]);
          break;
        case "badge_id":
          row[accessorKey] && setBadgesAssociation([row[accessorKey]]);
          break;
        case "certificate_id":
          row[accessorKey] && setCertificatesAssociation([row[accessorKey]]);
          break;
        case "knowledge_domain":
          row[accessorKey] && setKnowledgeDomainAssociation([row[accessorKey]]);
          break;
        case "legal_representative_signature":
          row[accessorKey] && setLegalSignatureAssociation([row[accessorKey]]);
          break;
        case "survey_id":
          row[accessorKey] && setSurveyAssociation([row[accessorKey]]);
          break;
      }

      if (accessorKey === "expiration_date_flag") {
        if (
          row["fixed_expiration"] === true &&
          row["expiration_date"] === 253402214400000 &&
          row["soft_expiring"] === true &&
          row["expected_compl_time"] === 1
        ) {
          initialState = {
            ...initialState,
            [accessorKey]: "false",
          };
        } else {
          initialState = {
            ...initialState,
            [accessorKey]: "true",
          };
        }
      } else if (accessorKey === "soft_expiring") {
        initialState = {
          ...initialState,
          [accessorKey]: !row[accessorKey],
        };
      } else if (accessorKey === "duration") {
        initialState = {
          ...initialState,
          [accessorKey]: row[accessorKey] / 60,
        };
      } else {
        initialState = {
          ...initialState,
          [accessorKey]: row[accessorKey],
        };
      }
    });
    setIsStandalone(!!initialState["is_standalone"]);
    initialState["propaedeutic_logics"] = row?.propaedeutic_logics;
    initialState["badge_id"] = row?.badge_id ? row?.badge_id.id : "";
    initialState["certificate_id"] = row?.certificate_id
      ? row?.certificate_id.id
      : "";
    initialState["specializations"] =
      row?.toj_specialization === true
        ? "TOJ"
        : row?.ecm_specialization === true
          ? "ECM"
          : "NONE";
    return initialState;
  };

  const resetInputForm = () => {
    let initialState = {};

    if (row) {
      setInputForm(getInitialData);
    } else {
      setActivityAssociation([]);
      setCoverAssociation([]);
      setVideoAssociation([]);
      setTestAssociation([]);
      setMaterialsAssociation([]);
      setBadgesAssociation([]);
      setCertificatesAssociation([]);
      setKnowledgeDomainAssociation([]);
      setLegalSignatureAssociation([]);
      setSurveyAssociation([]);
      setUserAssociation([]);
      setInputForm(initialState);
    }
  };

  useEffect(() => {
    if (
      row &&
      Object.keys(row).length > 0 &&
      columns?.length > 0 &&
      Object.keys(inputForm).length === 0
    ) {
      const initialState = getInitialData;
      setInputForm(initialState);
    }
  }, [row]);

  //TODO: refactor validation v.1
  const onConfirm = () => {
    const formValue = { ...inputForm };

    formValue["didactical_activity"] =
      activityAssociation?.length > 0
        ? activityAssociation.map(
            (activity: { id: string; label: string }) => +activity.id,
          )
        : null;

    formValue["video_id"] = videoAssociation[0]?.id || null;
    formValue["cover_id"] = coverAssociation[0]?.id || null;
    formValue["test_id"] = testAssociation[0]?.id || null;
    formValue["badge_id"] =
      badgeAssociation.length > 0 ? badgeAssociation[0].id : null;
    formValue["certificate_id"] =
      certificatesAssociation.length > 0 ? certificatesAssociation[0].id : null;
    formValue["knowledge_domain"] =
      knowledgeDomainAssociation.length > 0
        ? Number(knowledgeDomainAssociation[0].id)
        : null;

    formValue["legal_representative_signature"] =
      legalSignatureAssociation[0]?.id || null;
    formValue["survey_id"] = surveyAssociation[0]?.id || null;

    formValue["propaedeutic_logics"] =
      formValue?.["propaedeutic_logics"]?.toUpperCase();

    if (materialsAssociation.length > 0)
      formValue["didactical_material"] = materialsAssociation.map(
        (material: any) => Number(material.id),
      );

    formValue["persona"] = formValue["persona"]?.[0]?.value
      ? formValue["persona"].map(
          (persona: { label: string; value: string }) => persona.value,
        )
      : null;

    let columnsToValidate: any = IncludesChildrenColumns.filter(
      (columns: any) => {
        return !columns.removed;
      },
    );

    const { canBeSubmit, showError } = defaultValidation(
      columnsToValidate,
      formValue,
    );

    if (!!showError) {
      const firstErrorId = Object.keys(showError)[0];
      scrollToElement(firstErrorId);
    }

    // if both fields is empty shows a error message
    if (
      !formValue["lo_to_complete"] &&
      !formValue["min_completition_perc"] &&
      !formValue["completition_minutes"] &&
      !formValue["completition_minutes_perc"]
    ) {
      setErrorInputForm({
        ...showError,
        lo_to_complete: {
          msg: "invalid-lo_to_complete-or-min_completition_perc-field",
        },
        min_completition_perc: {
          msg: "invalid-lo_to_complete-or-min_completition_perc-field",
        },
        completition_minutes: {
          msg: "invalid-lo_to_complete-or-min_completition_perc-field",
        },
        completition_minutes_perc: {
          msg: "invalid-lo_to_complete-or-min_completition_perc-field",
        },
      });
      return;
    }

    setErrorInputForm(showError);

    const finalForm: any = {};
    if (canBeSubmit) {
      for (const key in formValue) {
        if (
          formValue[key] !== null &&
          formValue[key] !== undefined &&
          formValue[key] !== ""
        ) {
          finalForm[key] = formValue[key];
        }
      }
      setIsBlocked(false);
      onSubmit(finalForm, fileUploaded);
    }
  };

  const tableAssociation = ["activity-association", "didactical_activity"];

  const getErrMsg = (accessorKey: string): string => {
    return errorInputForm[accessorKey]?.msg || errorInputForm[accessorKey];
  };

  const minLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const maxLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const isSelectRequired = (accessorKey: string): boolean => {
    const compulsoryIfStandalone = [
      "highlight_for_you",
      "highlight_explore",
      "soft_expiring",
    ];
    return compulsoryIfStandalone.includes(accessorKey) && isStandalone;
  };

  const openAssociation =
    openAssociationActivity ||
    openAssociationCover ||
    openAssociationVideo ||
    openAssociationLearning ||
    openAssociationBadge ||
    openAssociationCertificate ||
    openAssociationMaterials ||
    openAssociationKnowledgeDomain ||
    openAssociationLegalSignature ||
    openAssociationUser ||
    openAssociationSurvey;

  const setOpenAssociation = (accessorKey: string, value: boolean) => {
    switch (accessorKey) {
      case "course_activities":
        setOpenAssociationActivity(value);
        setOpenAssociationCover(value ? !value : openAssociationCover);
        setOpenAssociationVideo(value ? !value : openAssociationVideo);
        setOpenAssociationMaterials(value ? !value : openAssociationMaterials);
        setOpenAssociationLearning(value ? !value : openAssociationLearning);
        setOpenAssociationBadge(value ? !value : openAssociationBadge);
        setOpenAssociationCertificate(
          value ? !value : openAssociationCertificate,
        );
        setOpenAssociationKnowledgeDomain(
          value ? !value : openAssociationKnowledgeDomain,
        );
        setOpenAssociationLegalSignature(
          value ? !value : openAssociationLegalSignature,
        );
        setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
        break;
      case "activity-association":
        setOpenAssociationActivity(value);
        setOpenAssociationCover(value ? !value : openAssociationCover);
        setOpenAssociationVideo(value ? !value : openAssociationVideo);
        setOpenAssociationMaterials(value ? !value : openAssociationMaterials);
        setOpenAssociationLearning(value ? !value : openAssociationLearning);
        setOpenAssociationBadge(value ? !value : openAssociationBadge);
        setOpenAssociationCertificate(
          value ? !value : openAssociationCertificate,
        );
        setOpenAssociationKnowledgeDomain(
          value ? !value : openAssociationKnowledgeDomain,
        );
        setOpenAssociationLegalSignature(
          value ? !value : openAssociationLegalSignature,
        );
        setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
        break;
      case "cover_id":
        setOpenAssociationCover(value);
        setOpenAssociationActivity(value ? !value : openAssociationActivity);
        setOpenAssociationVideo(value ? !value : openAssociationVideo);
        setOpenAssociationMaterials(value ? !value : openAssociationMaterials);
        setOpenAssociationLearning(value ? !value : openAssociationLearning);
        setOpenAssociationBadge(value ? !value : openAssociationBadge);
        setOpenAssociationCertificate(
          value ? !value : openAssociationCertificate,
        );
        setOpenAssociationKnowledgeDomain(
          value ? !value : openAssociationKnowledgeDomain,
        );
        setOpenAssociationLegalSignature(
          value ? !value : openAssociationLegalSignature,
        );
        setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
        break;
      case "test_id":
        setOpenAssociationLearning(value);
        setOpenAssociationActivity(value ? !value : openAssociationActivity);
        setOpenAssociationVideo(value ? !value : openAssociationVideo);
        setOpenAssociationMaterials(value ? !value : openAssociationMaterials);
        setOpenAssociationBadge(value ? !value : openAssociationBadge);
        setOpenAssociationCertificate(
          value ? !value : openAssociationCertificate,
        );
        setOpenAssociationKnowledgeDomain(
          value ? !value : openAssociationKnowledgeDomain,
        );
        setOpenAssociationLegalSignature(
          value ? !value : openAssociationLegalSignature,
        );
        setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
        break;
      case "video_id":
        setOpenAssociationVideo(value);
        setOpenAssociationActivity(value ? !value : openAssociationActivity);
        setOpenAssociationCover(value ? !value : openAssociationCover);
        setOpenAssociationMaterials(value ? !value : openAssociationMaterials);
        setOpenAssociationLearning(value ? !value : openAssociationLearning);
        setOpenAssociationBadge(value ? !value : openAssociationBadge);
        setOpenAssociationCertificate(
          value ? !value : openAssociationCertificate,
        );
        setOpenAssociationKnowledgeDomain(
          value ? !value : openAssociationKnowledgeDomain,
        );
        setOpenAssociationLegalSignature(
          value ? !value : openAssociationLegalSignature,
        );
        setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
        break;
      case "didactical_material":
        setOpenAssociationMaterials(value);
        setOpenAssociationActivity(value ? !value : openAssociationActivity);
        setOpenAssociationCover(value ? !value : openAssociationCover);
        setOpenAssociationVideo(value ? !value : openAssociationVideo);
        setOpenAssociationLearning(value ? !value : openAssociationLearning);
        setOpenAssociationBadge(value ? !value : openAssociationBadge);
        setOpenAssociationCertificate(
          value ? !value : openAssociationCertificate,
        );
        setOpenAssociationKnowledgeDomain(
          value ? !value : openAssociationKnowledgeDomain,
        );
        setOpenAssociationLegalSignature(
          value ? !value : openAssociationLegalSignature,
        );
        setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
        break;
      case "badge_id":
        setOpenAssociationBadge(value);
        setOpenAssociationCertificate(
          value ? !value : openAssociationCertificate,
        );
        setOpenAssociationActivity(value ? !value : openAssociationActivity);
        setOpenAssociationLearning(value ? !value : openAssociationLearning);
        setOpenAssociationCover(value ? !value : openAssociationCover);
        setOpenAssociationVideo(value ? !value : openAssociationVideo);
        setOpenAssociationMaterials(value ? !value : openAssociationMaterials);
        setOpenAssociationKnowledgeDomain(
          value ? !value : openAssociationKnowledgeDomain,
        );
        setOpenAssociationLegalSignature(
          value ? !value : openAssociationLegalSignature,
        );
        setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
        break;
      case "certificate_id":
        setOpenAssociationCertificate(value);
        setOpenAssociationBadge(value ? !value : openAssociationBadge);
        setOpenAssociationActivity(value ? !value : openAssociationActivity);
        setOpenAssociationLearning(value ? !value : openAssociationLearning);
        setOpenAssociationCover(value ? !value : openAssociationCover);
        setOpenAssociationVideo(value ? !value : openAssociationVideo);
        setOpenAssociationMaterials(value ? !value : openAssociationMaterials);
        setOpenAssociationKnowledgeDomain(
          value ? !value : openAssociationKnowledgeDomain,
        );
        setOpenAssociationLegalSignature(
          value ? !value : openAssociationLegalSignature,
        );
        setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
        break;
      case "knowledge_domain":
        setOpenAssociationKnowledgeDomain(value);
        setOpenAssociationBadge(value ? !value : openAssociationBadge);
        setOpenAssociationActivity(value ? !value : openAssociationActivity);
        setOpenAssociationLearning(value ? !value : openAssociationLearning);
        setOpenAssociationCover(value ? !value : openAssociationCover);
        setOpenAssociationVideo(value ? !value : openAssociationVideo);
        setOpenAssociationMaterials(value ? !value : openAssociationMaterials);
        setOpenAssociationCertificate(
          value ? !value : openAssociationCertificate,
        );
        setOpenAssociationLegalSignature(
          value ? !value : openAssociationLegalSignature,
        );
        setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
        break;
      case "legal_representative_signature":
        setOpenAssociationLegalSignature(value);
        setOpenAssociationBadge(value ? !value : openAssociationBadge);
        setOpenAssociationActivity(value ? !value : openAssociationActivity);
        setOpenAssociationLearning(value ? !value : openAssociationLearning);
        setOpenAssociationCover(value ? !value : openAssociationCover);
        setOpenAssociationVideo(value ? !value : openAssociationVideo);
        setOpenAssociationMaterials(value ? !value : openAssociationMaterials);
        setOpenAssociationCertificate(
          value ? !value : openAssociationCertificate,
        );
        setOpenAssociationKnowledgeDomain(
          value ? !value : openAssociationKnowledgeDomain,
        );
        setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
        break;
      case "survey_id":
        setOpenAssociationSurvey(value);
        setOpenAssociationLegalSignature(
          value ? !value : openAssociationLegalSignature,
        );
        setOpenAssociationBadge(value ? !value : openAssociationBadge);
        setOpenAssociationActivity(value ? !value : openAssociationActivity);
        setOpenAssociationLearning(value ? !value : openAssociationLearning);
        setOpenAssociationCover(value ? !value : openAssociationCover);
        setOpenAssociationVideo(value ? !value : openAssociationVideo);
        setOpenAssociationMaterials(value ? !value : openAssociationMaterials);
        setOpenAssociationCertificate(
          value ? !value : openAssociationCertificate,
        );
        setOpenAssociationKnowledgeDomain(
          value ? !value : openAssociationKnowledgeDomain,
        );
        break;
      case "user_id":
        setOpenAssociationUser(value);
        setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
        setOpenAssociationLegalSignature(
          value ? !value : openAssociationLegalSignature,
        );
        setOpenAssociationBadge(value ? !value : openAssociationBadge);
        setOpenAssociationActivity(value ? !value : openAssociationActivity);
        setOpenAssociationLearning(value ? !value : openAssociationLearning);
        setOpenAssociationCover(value ? !value : openAssociationCover);
        setOpenAssociationVideo(value ? !value : openAssociationVideo);
        setOpenAssociationMaterials(value ? !value : openAssociationMaterials);
        setOpenAssociationCertificate(
          value ? !value : openAssociationCertificate,
        );
        setOpenAssociationKnowledgeDomain(
          value ? !value : openAssociationKnowledgeDomain,
        );
    }
  };

  const handleDrag = (accessorKey: string, newItems: any) => {
    switch (accessorKey) {
      case "didactical_activity":
        setActivityAssociation(newItems);
        break;
    }
  };

  const handleDelete = (accessorKey: string, e: { id: string }) => {
    switch (accessorKey) {
      /*       case "didactical_activity":
  setCourseActivityAssociation(
    courseActivityAssociation.filter(
      (element: any) => element.id !== e.id
    )
  );
  break; */
      case "didactical_activity":
        setActivityAssociation(
          activityAssociation.filter((element: any) => element.id !== e.id),
        );
        break;
      case "cover_id":
        setCoverAssociation(
          coverAssociation.filter((element: any) => element?.id !== e?.id),
        );
        break;
      case "test_id":
        setTestAssociation(
          testAssociation.filter((element: any) => element.id !== e.id),
        );
        break;
      case "video_id":
        setVideoAssociation(
          videoAssociation?.filter((element: any) => element?.id !== e?.id),
        );
        break;
      case "didactical_material":
        setMaterialsAssociation(
          materialsAssociation.filter((element: any) => element.id !== e.id),
        );
        break;
      case "knowledge_domain":
        setKnowledgeDomainAssociation([]);
        break;
      case "legal_representative_signature":
        setLegalSignatureAssociation([]);
        break;
      case "survey_id":
        setSurveyAssociation(
          surveyAssociation.filter((element: any) => element.id !== e.id),
        );
        break;
    }
  };

  const getValues = useCallback(
    (accessorKey: string) => {
      switch (accessorKey) {
        /*     case "didactical_activity":
return courseActivityAssociation; */
        case "didactical_activity":
          return activityAssociation;
        case "cover_id":
          return coverAssociation;
        case "test_id":
          return testAssociation;
        case "video_id":
          return videoAssociation;
        case "didactical_material":
          return materialsAssociation;
        case "badge_id":
          return badgeAssociation;
        case "certificate_id":
          return certificatesAssociation;
        case "knowledge_domain":
          return knowledgeDomainAssociation;
        case "legal_representative_signature":
          return legalSignatureAssociation;
        case "survey_id":
          return surveyAssociation;
        case "user_id":
          return userAssociation;
      }
    },
    [
      activityAssociation,
      coverAssociation,
      materialsAssociation,
      videoAssociation,
      testAssociation,
      certificatesAssociation,
      badgeAssociation,
      knowledgeDomainAssociation,
      legalSignatureAssociation,
      surveyAssociation,
      userAssociation,
    ],
  );

  const getInputField = useMemo(() => {
    return IncludesChildrenColumns?.map((col: any) => {
      let RemoveECMInputs = [
        "event_code",
        "edition_code",
        "promoter_code",
        "provider",
        "legal_representative",
        "legal_representative_signature",
        "accreditor_code",
        "event_start_date",
        "event_end_date",
        "duration",
        "event_credits",
        "ecm_type",
        "detail_ecm_type",
        "event_type",
        "training_goal",
        "event_training_type",
        "special_cases",
        "sponsors",
        "tutors",
        "teachers",
        "lecturers",
        "moderators",
        "scientific_managers",
      ];

      let RemoveStandaloneInputs = [
        "publish_date",
        "obsolescent_date",
        "highlight_for_you",
        "highlight_explore",
      ];

      if (RemoveECMInputs.includes(col.accessorKey)) {
        if (inputForm.specializations === "ECM") {
          col.removed = false;
        } else {
          col.removed = true;
        }
      }

      if (RemoveStandaloneInputs.includes(col.accessorKey)) {
        if (isTrueValue(inputForm.is_standalone)) {
          col.removed = false;
        } else {
          col.removed = true;
        }
      }

      //TO REMOVE DYNAMICALLY INPUTS
      if (!col.removed) {
        if (
          inputForm.lo_to_complete ||
          inputForm.min_completition_perc ||
          inputForm.completition_minutes ||
          inputForm.completition_minutes_perc
        ) {
          if (inputForm.lo_to_complete) {
            const fieldsDisabled = [
              "min_completition_perc",
              "completition_minutes",
              "completition_minutes_perc",
            ];
            if (fieldsDisabled.includes(col.accessorKey)) {
              col.disabled = true;
            }
          }
          if (inputForm.min_completition_perc) {
            const fieldsDisabled = [
              "lo_to_complete",
              "completition_minutes",
              "completition_minutes_perc",
            ];
            if (fieldsDisabled.includes(col.accessorKey)) {
              col.disabled = true;
            }
          }
          if (inputForm.completition_minutes) {
            const fieldsDisabled = [
              "lo_to_complete",
              "min_completition_perc",
              "completition_minutes_perc",
            ];
            if (fieldsDisabled.includes(col.accessorKey)) {
              col.disabled = true;
            }
          }
          if (inputForm.completition_minutes_perc) {
            const fieldsDisabled = [
              "lo_to_complete",
              "completition_minutes",
              "min_completition_perc",
            ];
            if (fieldsDisabled.includes(col.accessorKey)) {
              col.disabled = true;
            }
          }
        } else {
          const fieldsDisabled = [
            "lo_to_complete",
            "min_completition_perc",
            "completition_minutes",
            "completition_minutes_perc",
          ];

          if (fieldsDisabled.includes(col.accessorKey)) {
            col.disabled = false;
          }
        }

        let fieldsToDisablePostPublish = ["publish_date", "survey_id"];

        if (
          row &&
          Object.keys(row).length > 0 &&
          IncludesChildrenColumns?.length > 0 &&
          dayjs().isAfter(dayjs(row["publish_date"]))
        ) {
          if (["test_id"].includes(col.accessorKey)) {
            col.disabled = true;
          }
          if (col.accessorKey === "didactical_activity") {
            col.children.map((el: any) => {
              return (el.disabled = true);
            });
          }

          if (fieldsToDisablePostPublish.includes(col.accessorKey)) {
            col.disabled = true;
          }
        } else {
          if (["test_id"].includes(col.accessorKey)) {
            col.disabled = false;
          }
          if (col.accessorKey === "didactical_activity") {
            col.children.map((el: any) => {
              return (el.disabled = false);
            });
          }
          if (fieldsToDisablePostPublish.includes(col.accessorKey)) {
            col.disabled = false;
          }
        }

        if (col.accessorKey === "max_attempt") {
          col.disabled = true;
          col.required = false;
        }

        if (inputForm.test_logic === "FREE") {
          if (col.accessorKey === "max_attempt") {
            col.disabled = true;
            col.required = false;
          }
        } else if (inputForm.test_logic === "WITH_ZEROING") {
          if (col.accessorKey === "max_attempt") {
            col.disabled = false;
            col.required = true;
          }
        }

        if (col.accessorKey === "detail_ecm_type") {
          switch (inputForm.ecm_type) {
            case "BLENDED":
              col.disabled = false;
              break;
            default:
              col.disabled = true;
              break;
          }
        }

        if (col.accessorKey === "test_min_score") {
          if (testAssociation && testAssociation.length > 0) {
            col.disabled = false;
          } else {
            col.disabled = true;
          }
        }

        switch (col.type) {
          case "select_with_association": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={12}>
                <Box
                  sx={{
                    border: "1px solid white",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    borderRadius: "4px",
                    borderColor: !!errorInputForm[col.children[0].accessorKey]
                      ? "#EB5757"
                      : "inherit",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      padding: "5px",
                      position: "absolute",
                      top: 0,
                      left: "10px",
                      backgroundColor: theme?.palette.background.paper,
                      transform: "translate(0, -50%)",
                    }}
                  >
                    {col.header}
                  </div>
                  <ButtonGroup
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    variant="text"
                    aria-label="outlined primary button group"
                  >
                    {col.association.map((elem: string) => {
                      return (
                        <Button
                          id={elem}
                          onClick={() => {
                            setIsBlocked(true);
                            setOpenAssociation(elem, true);
                          }}
                          disabled={col.children[0].disabled}
                        >
                          {t(elem)}
                        </Button>
                      );
                    })}
                  </ButtonGroup>
                  <ListDraggable
                    key={col.children[0].accessorKey}
                    id={col.children[0].accessorKey}
                    disabled={col.children[0].disabled}
                    value={getValues(col.children[0].accessorKey)}
                    error={!!errorInputForm[col.children[0].accessorKey]}
                    required={col.children[0].required}
                    errorMessage={
                      t(
                        `error.field.${getErrMsg(col.children[0].accessorKey)}`,
                        {
                          min: minLength(col.children[0].accessorKey),
                          max: maxLength(col.children[0].accessorKey),
                        },
                      ) || "Error"
                    }
                    onDelete={(e: any) => {
                      setIsBlocked(true);
                      handleDelete(col.children[0].accessorKey, e);
                    }}
                    labelKey={
                      tableAssociation.includes(col.children[0].accessorKey)
                        ? "label"
                        : "name"
                    }
                    onDrag={(newList: any) => {
                      setIsBlocked(true);
                      handleDrag(col.children[0].accessorKey, newList);
                    }}
                  />
                  <SelectCst
                    sx={{
                      margin: "5px 0px",
                    }}
                    id={col.children[1].accessorKey}
                    label={col.children[1].labelHeader[i18n.language]}
                    value={inputForm[col.children[1].accessorKey]}
                    optionsKey={col.children[1].optionsKey}
                    required={col.children[1].required}
                    onChange={(e: any) => {
                      setIsBlocked(true);
                      delete errorInputForm?.[col.children[1].accessorKey] &&
                        setErrorInputForm(errorInputForm);
                      setInputForm({
                        ...inputForm,
                        ...{ [col.children[1].accessorKey]: e },
                      });
                    }}
                    options={col?.options}
                    error={!!errorInputForm[col.children[1].accessorKey]}
                    errorMessage={
                      t(
                        `error.field.${getErrMsg(col.children[1].accessorKey)}`,
                        {
                          min: minLength(col.children[1].accessorKey),
                          max: maxLength(col.children[1].accessorKey),
                        },
                      ) || "Error"
                    }
                    disabled={col.children[1].disabled}
                  />
                  {col.accessorKey === "mediaType" && (
                    <Grid
                      item
                      sm={12}
                      sx={{ paddingTop: "0 !important" }}
                    ></Grid>
                  )}
                </Box>
              </Grid>
            );
          }
          case "association": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <InputAssociationChipsCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  label={col.header}
                  value={getValues(col.accessorKey)}
                  required={col.required}
                  error={!!errorInputForm[col.accessorKey]}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  textTooltip={col.tooltip}
                  onDelete={(e: any) => handleDelete(col.accessorKey, e)}
                  onRedirect={() => {
                    setIsBlocked(true);
                    setOpenAssociation(col.accessorKey, true);
                  }}
                  labelKey={col.labelKey}
                  onDrag={(newList: any) => {
                    handleDrag(col.accessorKey, newList);
                  }}
                  disabled={col.disabled}
                />
              </Grid>
            );
          }
          case "select-checkbox": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <MultiSelectCst
                  id={col.accessorKey}
                  label={col.header}
                  optionsKey={col.optionsKey}
                  value={inputForm[col.accessorKey]}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    delete errorInputForm?.[col.accessorKey] &&
                      setErrorInputForm(errorInputForm);
                    setInputForm({
                      ...inputForm,
                      ...{
                        [col.accessorKey]: e,
                      },
                    });
                  }}
                  options={col.options}
                  required={col.required}
                  disabled={col.disabled}
                />
              </Grid>
            );
          }
          case "singleCheckbox": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <SingleCheckboxCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  value={inputForm[col.accessorKey]}
                  label={col.header}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    delete errorInputForm?.[col.accessorKey] &&
                      setErrorInputForm(errorInputForm);
                    setInputForm({
                      ...inputForm,
                      ...{ [col.accessorKey]: e.target.checked },
                    });
                  }}
                  error={!!errorInputForm[col.accessorKey]}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                />
              </Grid>
            );
          }
          case "text": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <InputCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  label={col.header}
                  value={inputForm[col.accessorKey]}
                  required={col.required}
                  textTooltip={col.textTooltip}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    if (
                      col?.rules?.maxValue?.value &&
                      e.target.value.length > col?.rules?.max?.value
                    ) {
                      return;
                    }
                    delete errorInputForm?.[col.accessorKey] &&
                      setErrorInputForm(errorInputForm);
                    setInputForm({
                      ...inputForm,
                      ...{
                        [col.accessorKey]:
                          col?.inpuType === "number"
                            ? Number(e.target.value)
                            : e.target.value,
                      },
                    });
                  }}
                  maxValue={col?.rules?.max?.value}
                  multiline={col.multiline}
                  rows={col.rows}
                  error={!!errorInputForm[col.accessorKey]}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  disabled={col.disabled}
                  type={col.inpuType || "text"}
                />
              </Grid>
            );
          }
          case "chips": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <InputChipsCst
                  label={col.header}
                  id={col.accessorKey}
                  value={inputForm[col.accessorKey] || []}
                  maxLength={col?.rules?.max?.value || false}
                  required={col.required}
                  disabled={col.disabled}
                  onAdd={(list: any[]) => {
                    setIsBlocked(true);
                    delete errorInputForm?.[col.accessorKey] &&
                      setErrorInputForm(errorInputForm);
                    setInputForm({
                      ...inputForm,
                      ...{ [col.accessorKey]: list },
                    });
                  }}
                />
              </Grid>
            );
          }
          case "select": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <SelectCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  optionsKey={col.optionsKey}
                  label={col.header}
                  value={inputForm[col.accessorKey]?.toString()}
                  service={col.service}
                  required={isSelectRequired(col.accessorKey) || col.required}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    delete errorInputForm?.[col.accessorKey] &&
                      setErrorInputForm(errorInputForm);
                    if (col.accessorKey === "is_standalone")
                      setIsStandalone(e as boolean);
                    setInputForm({
                      ...inputForm,
                      ...{ [col.accessorKey]: e },
                    });
                  }}
                  options={col?.options}
                  textTooltip={col.tooltip}
                  error={!!errorInputForm[col.accessorKey]}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  disabled={col.disabled}
                />
              </Grid>
            );
          }
          case "autocomplete": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <AutocompleteCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  optionsKey={col.optionsKey}
                  label={col.header}
                  value={inputForm[col.accessorKey]}
                  required={col.required}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    delete errorInputForm?.[col.accessorKey] &&
                      setErrorInputForm(errorInputForm);
                    setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                  }}
                  options={col?.options}
                  error={!!errorInputForm[col.accessorKey]}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  disabled={col.disabled}
                />
              </Grid>
            );
          }
          case "multiCheckbox": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <MultiSelectCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  optionsKey={col.optionsKey}
                  label={col.header}
                  value={inputForm[col.accessorKey]}
                  textTooltip={col.tooltip}
                  required={col.required}
                  service={col.service}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    delete errorInputForm?.[col.accessorKey] &&
                      setErrorInputForm(errorInputForm);
                    setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                  }}
                  options={col?.options}
                  error={!!errorInputForm[col.accessorKey]}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  disabled={col.disabled}
                />
              </Grid>
            );
          }
          case "radio": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <RadioCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  label={col.header}
                  value={inputForm[col.accessorKey]}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    delete errorInputForm?.[col.accessorKey] &&
                      setErrorInputForm(errorInputForm);
                    setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                  }}
                  options={col.options}
                  error={!!errorInputForm[col.accessorKey]}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  disabled={col.disabled}
                />
              </Grid>
            );
          }
          case "dateTime": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <DataPickerCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  label={col.header}
                  value={inputForm[col.accessorKey]}
                  defaultValue={col.defaultValue || null}
                  required={col.required}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    delete errorInputForm?.[col.accessorKey] &&
                      setErrorInputForm(errorInputForm);
                    try {
                      const date = new Date(e);
                      const dateUTC = Date.UTC(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                        0,
                        0,
                        0,
                      );
                      setInputForm({
                        ...inputForm,
                        ...{
                          [col.accessorKey]: new Date(dateUTC).toISOString(),
                        },
                      });
                    } catch (e) {
                      setInputForm({
                        ...inputForm,
                        ...{ [col.accessorKey]: undefined },
                      });
                    }
                  }}
                  error={!!errorInputForm[col.accessorKey]}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  disabled={col.disabled}
                />
              </Grid>
            );
          }
          case "multi-text": {
            return (
              <MultiTextCst
                label={col.header}
                fields={col.fields}
                value={inputForm[col.accessorKey]}
                initialAccessorKey={col.accessorKey}
                selectedAccesorKey={associationAccesorKey}
                callBack={col.callBack}
                fieldsHasType={col.fieldsHasType}
                fieldsToShow={col.fieldsToShow}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({
                    ...inputForm,
                    ...{ [col.accessorKey]: e },
                  });
                }}
                onAssociationOpen={(key: any) => {
                  setAssociationAccesorKey(col.accessorKey);
                  setOpenAssociation(key, true);
                  setUserAssociation([]);
                }}
                associationValue={userAssociation}
                removeAssociation={setUserAssociation}
              />
            );
          }
        }
      }
    });
  }, [
    handleDelete,
    setOpenAssociation,
    openAssociation,
    getValues,
    columns,
    inputForm,
    errorInputForm,
    t,
  ]);

  const showFormAction = (field: ColumnInterface) => {
    return (
      field?.type?.includes("upload") ||
      field?.type?.includes("download") ||
      field?.type?.includes("template")
    );
  };

  const onUserAssociation = (selected: any) => {
    const objToUserAssociation: any = Object.keys(selected).map(
      (key: string) => {
        if (key.includes("||")) {
          return {
            id: key.split("||")[0],
            label: key.split("||")[1],
          };
        }
      },
    );
    const userIdSelected = objToUserAssociation[0].id;
    const listToControl = [
      "teachers",
      "tutors",
      "lecturers",
      "moderators",
      "scientific_managers",
    ];

    let isAlreadySet = false;
    listToControl.forEach((section) => {
      const isSelected = inputForm[section]?.filter(
        (item: any) =>
          Number(item.user_id.id) === Number(userIdSelected) ||
          Number(item.user_id) === Number(userIdSelected),
      );

      if (isSelected?.length > 0) {
        isAlreadySet = true;
        setOpenErrorModal(true);
        setErrorMessage(
          `${t("ecm-duplicate-user-text")}: ${t(section, { ns: "ecm_roles" })}`,
        );
      }
    });

    if (!!isAlreadySet) return;

    setUserAssociation(objToUserAssociation);
    scrollToElement("user_id");
    setOpenAssociation("user_id", false);
  };

  return (
    <>
      {/* <BackButtonWarning isBlocked={isBlocked} /> */}
      {!openAssociation && (
        <Grid container>
          <Grid item xs={0} lg={4} xl={3}>
            <BackgroundImageCst
              image="section-internal-form.jpg"
              disableGradient
            />
          </Grid>
          <Grid item xs={12} lg={8} xl={9}>
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                padding: "32px",
                minHeight: "495px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Grid container spacing={3}>
                {getInputField}
                {/* {standaloneActivityFields} */}
                {actionsForForms?.map((field) => {
                  return (
                    <Grid key={field.accessorKey} item xs={12} sm={6}>
                      {showFormAction(field) && (
                        <DownloaderUploaderCst
                          disabled={field.disabled}
                          name={field.header}
                          accept={field?.accept}
                          maxSize={field?.maxSize}
                          required={field?.required}
                          url={field.service}
                          dragDrop={dragDrop}
                          fileName={field.fileName}
                          type={Object.keys(inputForm).length === 0}
                          downloadDisabled={field.downloadDisabled}
                          onChange={(e: any) => {
                            if (field?.uploadCallback) {
                              if (!e) return;
                              const formData = new FormData();
                              formData.append("file", e);
                              setFileUploaded({
                                ...fileUploaded,
                                [field.accessorKey]: {
                                  file: formData,
                                },
                              });
                            } else {
                              getBase64(e, (result: any) => {
                                setFileUploaded({
                                  ...fileUploaded,
                                  [field.accessorKey]: {
                                    file: e,
                                    base64: result,
                                  },
                                });
                              });
                            }
                          }}
                          onDownload={
                            field.downloadCallback && field.downloadCallback
                          }
                          onTemplateDownload={
                            field.templateCallback && field.templateCallback
                          }
                          onDelete={
                            field.deleteCallback &&
                            (() => {
                              setFileUploaded(null);
                              return (
                                field.deleteCallback && field.deleteCallback()
                              );
                            })
                          }
                          textTooltip={field?.tooltip}
                          tooltipFile={field?.tooltipFile}
                          tooltipFileIta={field?.tooltipFileIta}
                          tooltipFileEn={field?.tooltipFileEn}
                        />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: "32px",
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    justifyContent: "space-between",
                    [theme.breakpoints.down("sm")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <ButtonCst
                    id={"back"}
                    variant={"outlined"}
                    size={"medium"}
                    sx={{
                      minWidth: "150px",
                      mr: "auto",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={() => {
                      if (backButton) {
                        backButton();
                      } else {
                        navigate(-1);
                      }
                    }}
                  >
                    {t("backButton")}
                  </ButtonCst>
                  <ButtonCst
                    id={"reset"}
                    variant={"outlined"}
                    size={"medium"}
                    sx={{
                      minWidth: "150px",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={resetInputForm}
                  >
                    {t("resetButton")}
                  </ButtonCst>
                  <ButtonCst
                    id={"confirm"}
                    variant={"contained"}
                    size={"medium"}
                    disabled={
                      actionsForForms.length > 0 && !fileUploaded ? true : false
                    }
                    sx={{
                      minWidth: "150px",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={() => onConfirm()}
                  >
                    {t("saveButton")}
                  </ButtonCst>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      {openAssociationActivity && (
        <AssociationTableCst
          association={activityAssociation}
          setAssociation={(activities: any) => {
            delete errorInputForm?.["didactical_activity"] &&
              setErrorInputForm(errorInputForm);
            const objToActivityAssociation: any = Object.keys(activities).map(
              (key: string) => {
                if (key.includes("||")) {
                  return {
                    id: key.split("||")[0],
                    label: key.split("||")[1],
                  };
                } else {
                  return activityAssociation.find(
                    (elem: any) => elem.id === +key,
                  );
                }
              },
            );
            setActivityAssociation(objToActivityAssociation);
          }}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation("course_activities", value)
          }
          service={`${ServicesURL["learning-catalogue"]}/${
            id || 1
          }/learning-objects/didactical-activities`}
          defaultFilters={
            inputForm.specializations === "TOJ"
              ? {}
              : {
                  type: [
                    "DA_XAPI",
                    "DA_SCORM",
                    "DA_PHYSICAL_CLASS",
                    "DA_VIRTUAL_CLASS",
                    "DA_EXTERNAL_RES",
                    "DA_YOUTUBE",
                    "DA_LINKEDIN",
                    "DA_SURVEY",
                    "DA_URL_RES",
                  ],
                }
          }
          manageConfigs={
            inputForm.specializations === "TOJ"
              ? {}
              : {
                  type: {
                    optionsToDisable: [
                      "DA_FINAL_BALANCE",
                      "DA_GOALS",
                      "DA_MEETING",
                      "DA_MATERIAL",
                    ],
                  },
                }
          }
          pageID={PagesID["corporate.catalog.activities"]}
          title={t("activity-association")}
        />
      )}
      {openAssociationCover && (
        <AssociationLibraryCst
          association={coverAssociation}
          setAssociation={(cover: any) => {
            delete errorInputForm?.["cover_id"] &&
              setErrorInputForm(errorInputForm);
            setCoverAssociation(cover);
          }}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation("cover_id", value)
          }
          filters={[{ field: "type", value: MediaType.COVER }]}
          title={t("cover-association")}
        />
      )}
      {openAssociationLearning && (
        <AssociationTableCst
          association={testAssociation}
          setAssociation={(test: any) => {
            delete errorInputForm?.["test_id"] &&
              setErrorInputForm(errorInputForm);
            const objToActivityAssociation: any = Object.keys(test).map(
              (key: string) => {
                if (key.includes("||")) {
                  return {
                    id: key.split("||")[0],
                    label: key.split("||")[1],
                  };
                } else {
                  return activityAssociation.find(
                    (elem: any) => elem.id === +key,
                  );
                }
              },
            );
            setTestAssociation(objToActivityAssociation);
          }}
          setCloseAssociation={(value: boolean) => {
            setOpenAssociation("test_id", value);
          }}
          service={`${ServicesURL["learning-catalogue"]}/${
            id || 1
          }/learning-objects/didactical-activities`}
          defaultFilters={{ type: "DA_XAPI,DA_SCORM", is_standalone: false }}
          manageConfigs={{
            type: {
              optionsToDisable: [
                "DA_PHYSICAL_CLASS",
                "DA_VIRTUAL_CLASS",
                "DA_EXTERNAL_RES",
                "DA_YOUTUBE",
                "DA_LINKEDIN",
              ],
            },
            is_standalone: {
              optionsToDisable: ["true"],
            },
          }}
          pageID={PagesID["corporate.catalog.activities"]}
          title={t("activity-association")}
          filter="learning_objects"
          enableMultiRowSelection={false}
        />
      )}
      {openAssociationVideo && (
        <AssociationLibraryCst
          association={videoAssociation}
          setAssociation={(video: any) => {
            delete errorInputForm?.["video_id"] &&
              setErrorInputForm(errorInputForm);
            setVideoAssociation(video);
          }}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation("video_id", value)
          }
          filters={[{ field: "type", value: MediaType.VIDEO }]}
          title={t("video-association")}
        />
      )}
      {openAssociationMaterials && (
        <AssociationLibraryCst
          association={materialsAssociation}
          setAssociation={(materials: any) => {
            delete errorInputForm?.["didactical_material"] &&
              setErrorInputForm(errorInputForm);
            setMaterialsAssociation(materials);
          }}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation("didactical_material", value)
          }
          filters={[{ field: "type", value: MediaType.DIDACTICAL_MATERIAL }]}
          multiple
          title={t("teaching-materials-association")}
        />
      )}
      {openAssociationBadge && (
        <AssociationTableCst
          association={badgeAssociation}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation("badge_id", value)
          }
          setAssociation={(badge: any) => {
            delete errorInputForm?.["badge_id"] &&
              setErrorInputForm(errorInputForm);
            const objToBadgeAssociation: any = Object.keys(badge).map(
              (key: string) => {
                if (key.includes("||")) {
                  return {
                    id: key.split("||")[0],
                    label: key.split("||")[1],
                  };
                } else {
                  return badgeAssociation.find((elem: any) => elem.id === +key);
                }
              },
            );
            setBadgesAssociation(objToBadgeAssociation);
          }}
          title={t("badge-association")}
          service={`${ServicesURL.getBadges}?corporateId=${id}`}
          pageID={PagesID["badge"]}
          // title={t("activity-association")}
          enableMultiRowSelection={false}
        />
      )}
      {openAssociationCertificate && (
        <AssociationTableCst
          association={certificatesAssociation}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation("certificate_id", value)
          }
          setAssociation={(certificate: any) => {
            delete errorInputForm?.["certificate_id"] &&
              setErrorInputForm(errorInputForm);
            const objToCertificateAssociation: any = Object.keys(
              certificate,
            ).map((key: string) => {
              if (key.includes("||")) {
                return {
                  id: key.split("||")[0],
                  label: key.split("||")[1],
                };
              } else {
                return certificatesAssociation.find(
                  (elem: any) => elem.id === +key,
                );
              }
            });
            setCertificatesAssociation(objToCertificateAssociation);
          }}
          title={t("certificate-association")}
          service={`${ServicesURL.getCertificates}?corporateId=${id}`}
          pageID={PagesID["certificates"]}
          // title={t("activity-association")}
          enableMultiRowSelection={false}
        />
      )}
      {openAssociationKnowledgeDomain && (
        <AssociationTableCst
          association={knowledgeDomainAssociation}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation("knowledge_domain", value)
          }
          setAssociation={(knowledge_domain: any) => {
            delete errorInputForm?.["knowledge_domain"] &&
              setErrorInputForm(errorInputForm);
            const objToknowledgeDomainAssociation: any = Object.keys(
              knowledge_domain,
            ).map((key: string) => {
              if (key.includes("||")) {
                return {
                  id: key.split("||")[0],
                  label: key.split("||")[1],
                };
              } else {
                return knowledgeDomainAssociation.find(
                  (elem: any) => elem.id === +key,
                );
              }
            });
            setKnowledgeDomainAssociation(objToknowledgeDomainAssociation);
          }}
          title={t("knowledge-domains-association", { ns: "breadcrumb" })}
          service={`${ServicesURL.getKnowledgeDomainsAll.replace(
            ":idCorporate",
            id ? id : "1",
          )}`}
          pageID={PagesID["skills.knowledge-domains"]}
          // title={t("activity-association")}
          enableMultiRowSelection={false}
        />
      )}
      {openAssociationLegalSignature && (
        <AssociationLibraryCst
          association={legalSignatureAssociation}
          setAssociation={(cover: any) => {
            delete errorInputForm?.["legal_representative_signature"] &&
              setErrorInputForm(errorInputForm);
            setLegalSignatureAssociation(cover);
          }}
          setCloseAssociation={(value: boolean) => {
            scrollToElement("legal_representative_signature");
            setOpenAssociation("legal_representative_signature", value);
          }}
          filters={[{ field: "type", value: MediaType.OTHER }]}
          title={t("legal-signature-association")}
        />
      )}
      {openAssociationSurvey && (
        <AssociationTableCst
          association={surveyAssociation}
          setAssociation={(test: any) => {
            delete errorInputForm?.["survey_id"] &&
              setErrorInputForm(errorInputForm);
            const objSurveyAssociation: any = Object.keys(test).map(
              (key: string) => {
                return {
                  id: key.split("||")[0],
                  label: key.split("||")[1],
                };
              },
            );
            setSurveyAssociation(objSurveyAssociation);
          }}
          setCloseAssociation={(value: boolean) => {
            scrollToElement("survey_id");
            setOpenAssociation("survey_id", value);
          }}
          service={`${ServicesURL["learning-catalogue"]}/${
            id || 1
          }/learning-objects/didactical-activities`}
          defaultFilters={{ type: "DA_SURVEY", is_standalone: false }}
          manageConfigs={{
            type: {
              optionsToDisable: [
                "DA_PHYSICAL_CLASS",
                "DA_VIRTUAL_CLASS",
                "DA_EXTERNAL_RES",
                "DA_YOUTUBE",
                "DA_LINKEDIN",
              ],
            },
            is_standalone: {
              optionsToDisable: ["true"],
            },
          }}
          pageID={PagesID["corporate.catalog.activities"]}
          title={t("survey-association")}
          enableMultiRowSelection={false}
        />
      )}
      {openAssociationUser && (
        <AssociationTableCst
          association={userAssociation}
          setAssociation={(user: any) => {
            delete errorInputForm?.["user_id"] &&
              setErrorInputForm(errorInputForm);
            const objToUserAssociation: any = Object.keys(user).map(
              (key: string) => {
                if (key.includes("||")) {
                  return {
                    id: key.split("||")[0],
                    label: key.split("||")[1],
                  };
                } else {
                  return userAssociation.find((elem: any) => elem.id === +key);
                }
              },
            );

            setUserAssociation(objToUserAssociation);
          }}
          callBackAssociation={onUserAssociation}
          setCloseAssociation={(value: boolean) => {
            scrollToElement("user_id");
            setOpenAssociation("user_id", value);
          }}
          service={`${
            associationAccesorKey === "teachers"
              ? ServicesURL.faculties
              : ServicesURL.getCustomer
          }?corporate_id=${id}`}
          pageID={PagesID["corporate.customers"]}
          title={t(`${associationAccesorKey}-association`)}
          filter="learning_objects"
          enableMultiRowSelection={false}
        />
      )}
      <ModalConfirmsCst
        open={openErrorModal}
        title={t("ecm-duplicate-title")}
        description={errorMessage}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpenErrorModal(false);
        }}
        removeButtons={true}
      />
    </>
  );
};

export default CoursesFormCst;
