import React, { useEffect, useState } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { useAuth } from "@context/auth";
import { useNavigaionRoutes } from "@hooks/useNavigation";
import HeaderCst from "@components/HeaderCst";
import FooterCst from "@components/FooterCst";
import { LayoutContainer, Main } from "./LayoutHome.styles";

const LayoutHome: React.FC = () => {
  const { user } = useAuth();
  let navigationMenu = useNavigaionRoutes();
  if (user) {
    // @ts-ignore
    // RolesIdEnum is not equal to BE response
    if (user.roles.includes("PRODUCT_OWNER") && user?.organization_id === 1) {
      // Render Digited Header
      navigationMenu = navigationMenu?.filter((el: { section: string }) =>
        user?.permissions?.includes(el.section)
      );
    } else {
      // Render Corporate Header
      navigationMenu = navigationMenu
        .find((el) => el.to === "corporate")
        ?.children?.filter((el: { section: string }) =>
          user?.permissions?.includes(el.section)
        );
      navigationMenu = navigationMenu.map((el) => {
        const elements = { ...el };
        elements.to =
          "corporate/" + user.organization_id + "/detail/" + elements.to;
        return elements;
      });
    }
  }

  const [hideNavigation, setHideNavigation] = useState(true);
  let lastScrollTop = 0;

  const handleWheel = (e: any) => {
    var actualScrollTop =
      window.pageYOffset || document.documentElement.scrollTop;
    if (actualScrollTop > lastScrollTop) {
      if (actualScrollTop > 100) {
        setHideNavigation(false);
      }
    } else if (actualScrollTop < lastScrollTop) {
      setHideNavigation(true);
    }
    lastScrollTop = actualScrollTop <= 0 ? 0 : actualScrollTop;
  };

  useEffect(() => {
    window.addEventListener("scroll", (e: any) => {
      handleWheel(e);
    });
  }, []);

  return (
    <LayoutContainer>
      <HeaderCst menu={navigationMenu} hideNavigation={hideNavigation} />
      <Main>
        <Outlet />
        <ScrollRestoration />
      </Main>
      <FooterCst />
    </LayoutContainer>
  );
};

export default LayoutHome;
