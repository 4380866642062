import { useState, useEffect } from "react";

import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { Association } from "src/interfaces/Common";
import { useParams } from "react-router-dom";

export const useDigitedUsersAddEdit = (dataDetail?: any) => {
  const { id, idUser } = useParams();

  const [openAssociations, setOpenAssociations] = useState<
    boolean | Association
  >(false);
  const [jobsAssociation, setJobsAssociation] = useState<any>([]);
  const [industriesAssociation, setIndustriesAssociation] = useState<any>([]);
  const [professionsAssociation, setProfessionsAssociation] = useState<any>([]);
  useEffect(() => {
    if (dataDetail) {
      setJobsAssociation(
        Array.isArray(dataDetail.job)
          ? dataDetail.job
          : dataDetail.job === null
            ? []
            : [dataDetail.job],
      ); //REFACTOR lato BE sarebbe meglio ci cambiassero la risposta (uguale a faculties)
      setIndustriesAssociation(
        Array.isArray(dataDetail.industry)
          ? dataDetail.industry
          : dataDetail.industry === null
            ? []
            : [dataDetail.industry],
      );
      setProfessionsAssociation(
        Array.isArray(dataDetail.profession)
          ? dataDetail.profession
          : dataDetail.profession === null
            ? []
            : [dataDetail.profession],
      );
    }
  }, [dataDetail]);
  const setOpenAssociation = (accessorKey: string, value: boolean) => {
    switch (accessorKey) {
      case "industry":
        setOpenAssociations(
          value
            ? {
                accessorKey: "industry",
                mediaType: "industry",
                type: "table",
                titleKey: "industry-association",
                pageId: PagesID["skills.industries"],
                service: `${ServicesURL.getIndustries}?corporateId=${
                  id || "1"
                }`,
                defaultFilters: { obsolete: false },
                manageConfigs: {
                  obsolete: {
                    optionsToDisable: ["true"],
                  },
                },
                multiChoice: false,
              }
            : value,
        );
        break;
      case "profession":
        setOpenAssociations(
          value
            ? {
                accessorKey: "profession",
                mediaType: "profession",
                type: "table",
                titleKey: "profession-association",
                pageId: PagesID["skills.professions"],
                service: `${ServicesURL.getProfessions.replace(
                  ":idCorporate",
                  "1",
                )}`,
                multiChoice: false,
                defaultFilters: {
                  obsolete: false,
                  industries:
                    industriesAssociation && industriesAssociation[0]
                      ? industriesAssociation[0]?.id
                      : undefined,
                },
                manageConfigs: {
                  obsolete: {
                    optionsToDisable: ["true"],
                  },
                },
              }
            : value,
        );
        break;
      case "job":
        setOpenAssociations(
          value
            ? {
                accessorKey: "job",
                mediaType: "job",
                type: "table",
                titleKey: "job-association",
                pageId: PagesID["skills.jobs"],
                service: `${ServicesURL.getJobs
                  .replace(":idCorporate", "1")
                  .replace(
                    ":idProfession",
                    professionsAssociation && professionsAssociation[0]
                      ? professionsAssociation[0].id
                      : "",
                  )}`,
                defaultFilters: { obsolete: false },
                manageConfigs: {
                  obsolete: {
                    optionsToDisable: ["true"],
                  },
                },
                multiChoice: false,
              }
            : value,
        );
        break;
    }
  };

  const getValues = (accessorKey: string) => {
    switch (accessorKey) {
      case "industry":
        return industriesAssociation;
      case "profession":
        return professionsAssociation;
      case "job":
        return jobsAssociation;
    }
  };

  const handleAssociation = (accessorKey: string, value: any) => {
    switch (accessorKey) {
      case "industry":
        const objToIndustriesAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return industriesAssociation.find(
                (elem: any) => elem.id === +key,
              );
            }
          },
        );
        setIndustriesAssociation(objToIndustriesAssociation);
        setProfessionsAssociation([]);
        break;
      case "profession":
        const objToProfessionsAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return professionsAssociation.find(
                (elem: any) => elem.id === +key,
              );
            }
          },
        );
        setProfessionsAssociation(objToProfessionsAssociation);
        setJobsAssociation([]);
        break;
      case "job":
        const objToJobsAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return jobsAssociation.find((elem: any) => elem.id === +key);
            }
          },
        );
        setJobsAssociation(objToJobsAssociation);
        break;
    }
  };

  const inputsException = (col: any, inputForm: any) => {
    if (col?.accessorKey === "profession") {
      if (industriesAssociation && industriesAssociation.length > 0) {
        col.disabled = false;
      } else {
        col.disabled = true;
        setJobsAssociation(null);
      }
    }
    if (col?.accessorKey === "job") {
      if (professionsAssociation && professionsAssociation.length > 0) {
        col.disabled = false;
      } else {
        col.disabled = true;
      }
    }
    return col;
  };

  const handleDelete = (accessorKey: string, e: any) => {
    switch (accessorKey) {
      case "industry":
        setIndustriesAssociation(
          industriesAssociation.filter((element: any) => element.id !== e.id),
        );
        break;
      case "profession":
        setProfessionsAssociation(
          professionsAssociation.filter((element: any) => element.id !== e.id),
        );
        break;
      case "job":
        setJobsAssociation(
          jobsAssociation.filter((element: any) => element.id !== e.id),
        );
        break;
    }
  };

  return {
    openAssociations,
    industriesAssociation,
    professionsAssociation,
    jobsAssociation,
    inputsException,
    setOpenAssociation,
    handleAssociation,
    handleDelete,
    getValues,
  };
};
