import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Container } from "@mui/material";
import { useFetchDetail, usePutDetail } from "@hooks/useFetchDetail";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ColumnInterface } from "@components/Table/types";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { isTrueValue } from "@utils/utilsValidators";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useDigitedUsersAddEdit } from "../hooks/useDigitedUsersAddEdit";

const EditDigitedUsers: React.FC = () => {
  const { t } = useTranslation(["customers-digited-page"]);
  const { idUser } = useParams();
  const { columns, dataDetail, actionsForForms, mutate } = useFetchDetail(
    ServicesURL.digitedUsers,
    idUser || "",
    PagesID["users"],
  );
  const {
    jobsAssociation,
    industriesAssociation,
    professionsAssociation,
    openAssociations,
    inputsException,
    setOpenAssociation,
    handleAssociation,
    handleDelete,
    getValues,
  } = useDigitedUsersAddEdit(dataDetail);
  const [dataDetailFlat, setDataDetailFlat] = useState();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  useEffect(() => {
    if (dataDetail?.uog) {
      const uogFlat = dataDetail.uog.map((el: any) => el.uog);
      // description is the single field
      setDataDetailFlat({
        ...dataDetail,
        uog: [...uogFlat],
        uog_description:
          dataDetail?.uog &&
          dataDetail?.uog[0] &&
          dataDetail?.uog[0].uog_description,
      });
    } else {
      setDataDetailFlat({ ...dataDetail });
    }
  }, [dataDetail]);

  const { trigger, setId } = usePutDetail(ServicesURL.putCustomer);

  const navigate = useNavigate();

  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    // TODO: change roles format
    const objToSend: any = {
      name: inputForm["name"] || "",
      surname: inputForm["surname"] || "",
      email: inputForm["email"] || "", // must be mail format
      phone_number: inputForm["phone_number"],
      address: inputForm["address"],
      gender: inputForm["gender"],
      fiscal_code: inputForm["fiscal_code"],
      country_code: inputForm["country_code"],
      country_description: inputForm["country_description"],
      birthday:
        inputForm["birthday"] && new Date(inputForm["birthday"]).getTime(),
      birth_country: inputForm["birth_country"],
      birth_province: inputForm["birth_province"],
      ateco_code: inputForm["ateco_code"],
      branch: inputForm["branch"],
      external_employee_flag:
        inputForm["external_employee_flag"] &&
        isTrueValue(inputForm["external_employee_flag"]),
      contractual_framework_code: inputForm["contractual_framework_code"],
      contractual_framework_type: inputForm["contractual_framework_type"],
      new_hire_flag:
        inputForm["new_hire_flag"] && isTrueValue(inputForm["new_hire_flag"]),
      hire_date:
        inputForm["hire_date"] && new Date(inputForm["hire_date"]).getTime(),
      termination_date:
        inputForm["termination_date"] &&
        new Date(inputForm["termination_date"]).getTime(),
      contract_type: inputForm["contract_type"],
      schedule_type: inputForm["schedule_type"],
      ccnl: inputForm["ccnl"],
      language: inputForm["language"],
      supervisor: inputForm["supervisor"], // ?
      professional_level: inputForm["professional_level"],
      privacy_consent_flag:
        inputForm["privacy_consent_flag"] &&
        isTrueValue(inputForm["privacy_consent_flag"]),
      smart_working_flag:
        inputForm["smart_working_flag"] &&
        isTrueValue(inputForm["smart_working_flag"]),
      teacher_picture: inputForm["teacher_picture"],
      internal_employee_flag:
        inputForm["internal_employee_flag"] &&
        isTrueValue(inputForm["internal_employee_flag"]),
      roles: inputForm["roles"]?.map((el: any) => el.value) || [], // must be between 1 and 6
      smart_learning_hours: inputForm["smart_learning_hours"]
        ? parseInt(inputForm["smart_learning_hours"])
        : null,
      persona: inputForm["persona"]?.map((el: any) => el.value) || [],
      admin_flag: isTrueValue(inputForm.admin_flag),
      receieved_credentials_flag: isTrueValue(
        inputForm.receieved_credentials_flag,
      ),
      uog: [
        ...inputForm["uog"].map((el: string) => {
          return { uog: el, uog_description: inputForm["uog_description"] };
        }),
      ],
      job: jobsAssociation?.map((el: any) => +el.id)[0] || null,
      industry: industriesAssociation?.map((el: any) => +el.id)[0] || null,
      profession: professionsAssociation?.map((el: any) => +el.id)[0] || null,
      manager_flag:
        inputForm["manager_flag"] && isTrueValue(inputForm["manager_flag"]),
    };
    try {
      await trigger(objToSend);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
    mutate && mutate(inputForm);
  };

  const onDownload = (url: string) => null;

  useEffect(() => {
    idUser && setId(idUser);
  }, [idUser]);

  const columnDisabled = useMemo(() => {
    return columns.map((column: ColumnInterface) => {
      if (column.accessorKey === "external_id") {
        return { ...column, disabled: true };
      }
      return column;
    });
  }, [columns]);

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-users.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        {columnDisabled && dataDetailFlat && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              isFaculties={true}
              row={dataDetailFlat}
              columns={columnDisabled}
              onSubmit={onSubmit}
              inputsException={inputsException}
              actionsForForms={
                actionsForForms &&
                actionsForForms.map((el: ColumnInterface) => {
                  if (el.type?.includes("download")) {
                    el.downloadCallback = () =>
                      onDownload((el && el.service) || "");
                  }
                  return el;
                })
              }
              formActionType={["download"]}
              setOpenAssociation={(accessorKey, open) =>
                setOpenAssociation(accessorKey, open)
              }
              handleAssociation={(accessorKey, value) =>
                handleAssociation(accessorKey, value)
              }
              handleDelete={handleDelete}
              getValues={(accessorKey) => getValues(accessorKey)}
              association={openAssociations}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default EditDigitedUsers;
