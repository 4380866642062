import { Container } from "@mui/material";
import BackgroundImageCst from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import { useFetchDetail, usePostDetail } from "@hooks/useFetchDetail";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ColumnInterface } from "@components/Table/types";
import InitiativeFormCst from "../InitiativeFormCst";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalCst from "@components/ModalCst";
import { TotalKeyURL } from "src/services/type";
import { useAddEditInitiative } from "../hooks/useAddEditInitiative";
import AnimationFadeIn from "@components/AnimationFadeIn";

const AddInitiative: React.FC = () => {
  const { t } = useTranslation(["initiative-page"]);
  const { columns, mutate } = useFetchDetail(
    ServicesURL.initiative,
    undefined,
    PagesID["corporate.initiative"],
  );
  const { columns: columnsSmartConfiguration, actionsForForms } =
    useFetchDetail(
      ServicesURL.getSmartConfigurator,
      undefined,
      PagesID.smartconfigurator,
    );
  const { id } = useParams();
  const navigate = useNavigate();

  const { dataDetail: corporateDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID["corporate.initiative"],
  );

  const getInitialOutcome = () => {
    return {
      initiative: {
        code: undefined,
        message: t("add.outcome.initiative.messageLoading"),
        title: t("add.outcome.initiative.title"),
      },
      smartConfiguration: {
        code: undefined,
        message: t("add.outcome.smart-configurator.messageLoading"),
        title: t("add.outcome.smart-configurator.title"),
      },
      uploadBranding: {
        code: undefined,
        message: t("add.outcome.upload-branding.messageLoading"),
        title: t("add.outcome.upload-branding.title"),
      },
      uploadTemplate: {
        code: undefined,
        message: t("add.outcome.upload-template.messageLoading"),
        title: `${t("add.outcome.upload-template.title")} - Mail`,
      },
      uploadTemplateWeb: {
        code: undefined,
        message: t("edit.outcome.upload-template.messageLoading"),
        title: `${t("add.outcome.upload-template.title")} - Web`,
      },
      uploadTemplatePush: {
        code: undefined,
        message: t("edit.outcome.upload-template.messageLoading"),
        title: `${t("add.outcome.upload-template.title")} - Push`,
      },
      uploadNotifications: {
        code: undefined,
        message: t("add.outcome.upload-notifications.messageLoading"),
        title: t("add.outcome.upload-notifications.title"),
      },
    };
  };

  const { trigger: triggerInitiative } = usePostDetail(
    ServicesURL.initiative + `/${id}/initiatives`,
  );

  const { onSubmit, onDownload, outcome, openOutcome, setOpenOutcome } =
    useAddEditInitiative(
      "add",
      triggerInitiative,
      getInitialOutcome,
      id,
      undefined,
      mutate,
    );

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-initiatives.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        <InitiativeFormCst
          idCorporate={id}
          row={{
            urlCorporateDomain: corporateDetail.corporate_domain,
            webDesktop: true,
            xapiTrackerType: "I",
            totalForYouSlots: 25,
            aiSuggestedAltSlots: 3,
            mandatoryAltSlots: 2,
            hrSuggestedAltSlots: 1,
            managerSuggestedAltSlots: 1,
            knowledgeDomainHRWeight: 17.5,
            skillsHRWeight: 17.5,
            knowledgeDomainManagerWeight: 40,
            knowledgeDomainUserWeight: 15,
            skillsUserWeight: 10,
          }}
          actionsForForms={
            actionsForForms &&
            actionsForForms.map((el: ColumnInterface) => {
              if (el.type?.includes("download")) {
                el.downloadCallback = () =>
                  onDownload((el && el.service) || "", el.accessorKey);
              }
              if (el.type?.includes("template")) {
                if (
                  el.accessorKey === "uploadNotification" ||
                  el.accessorKey === "uploadTemplate" ||
                  el.accessorKey === "uploadBrandIdentity" ||
                  el.accessorKey === "uploadTemplateWeb" ||
                  el.accessorKey === "uploadTemplatePush"
                ) {
                  el.templateCallback = () => {
                    const type = (el.key || "").split(",");
                    onDownload(
                      `${ServicesURL.smartconfiguratorDownloadTemplate}?type=${type[0]}` ||
                        "",
                      el.accessorKey,
                      "template",
                    );
                    if (type.length > 1)
                      onDownload(
                        `${ServicesURL.smartconfiguratorDownloadTemplate}?type=${type[1]}` ||
                          "",
                        el.accessorKey,
                        "template",
                      );
                  };
                } else {
                  const url: any = ServicesURL[el.service as TotalKeyURL];
                  el.templateCallback = () =>
                    onDownload(url || "", el.accessorKey, "template");
                }
              }
              return el;
            })
          }
          formActionType={["upload", "download"]}
          columns={columns}
          additionalColumns={columnsSmartConfiguration}
          onSubmit={onSubmit}
          sectionTooltip={t("smart-configuration-tooltip-description") || ""}
          sectionTooltipTitle={t("smart-configuration-tooltip-title") || ""}
          backButton={() => {
            navigate(`/corporate/${id}/detail/initiative`);
          }}
        />
        <ModalCst
          open={openOutcome}
          setOpen={setOpenOutcome}
          typeOfModal={"outcome"}
          data={{
            outcome: outcome,
            callBackSubmit: () => {
              setOpenOutcome(!openOutcome);
              navigate(`/corporate/${id}/detail/initiative`);
            },
          }}
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default AddInitiative;
