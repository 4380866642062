import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import {
  DataPickerCst,
  InputCst,
  MultiSelectCst,
  SelectCst,
  AutocompleteVirtualScrollCst,
} from "@components/InputCst";
import ButtonCst from "@components/ButtonCst";
import { Add, Delete } from "@mui/icons-material";
import TimePickerCst from "@components/InputCst/TimePickerCst";
import { PagesID } from "@utils/utilsConfigurations";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { defaultValidation } from "@utils/utilsValidators";

export interface MultiTextInterface {
  label?: string;
  value?: any;
  onChange: (value: any) => void;
  fields: any[];
  disabled?: boolean;
  getErrMsg?: any;
  minLength?: any;
  maxLength?: any;
  errorInputForm?: any;
  setErrorInputForm?: any;
  error: any;
  errorMessage: any;
}

const ConditionGroupsInputs: React.FC<MultiTextInterface> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation("input");
  const { t: tForm } = useTranslation(["form"]);
  const { id, idGroup } = useParams();

  const {
    label,
    value,
    fields,
    onChange,
    disabled,
    getErrMsg,
    minLength,
    maxLength,
    errorInputForm,
    setErrorInputForm,
    error,
    errorMessage,
  } = props;

  const PAGE_SIZE = 20;

  const [groupsList, setGroupsList] = useState<any>(undefined);
  const [pagination, setPagination] = useState<any>({
    pageIndex: 0,
    pageSize: PAGE_SIZE,
  });
  const [totalElement, setTotalElement] = useState(0);

  const { dataDetail: dataSet } = useFetchDetail(
    `${ServicesURL.dynamicGroups.replace(":idCorporate", `${id ? id : 1}`)}`,
    `rule-dataset`,
    PagesID["corporate.groups-dynamic.add"],
  );

  const extraOptions = useMemo(() => {
    let newRules = { ...dataSet };
    const { custom_fields } = dataSet;

    if (custom_fields && custom_fields.length > 0) {
      newRules.custom_fields = [
        ...custom_fields.map((fieldCustom: any) => {
          return {
            value: fieldCustom.key,
            label:
              fieldCustom.label.charAt(0).toUpperCase() +
              fieldCustom.label.slice(1),
          };
        }),
      ];
    } else {
      newRules.custom_fields = [];
    }

    return newRules.custom_fields;
  }, [dataSet]);

  const {
    dataTable: dynamicGroupsList,
    handleFetchData,
    isLoading,
  } = useFetchTable(
    `${ServicesURL.groups.replace(":idCorporate", `${id ? id : 1}`)}/groups`,
    PagesID["corporate.groups-dynamic.add"],
  );

  const { columns } = useFetchDetail(
    undefined,
    undefined,
    PagesID["corporate.groups-dynamic.users"],
  );

  useEffect(() => {
    if (dynamicGroupsList) {
      setTotalElement(dynamicGroupsList.metadata.total_elements);
    }
  }, [dynamicGroupsList]);

  const inputObject = fields.reduce(
    (acc: any, cur: any) => ({
      ...acc,
      [cur.accessorKey]: cur.accessorKey === "operator" ? "AND" : "",
    }),
    {},
  );
  const [object, setObject] = useState<any>(inputObject);

  const handleAddObject = () => {
    const newFields = [...fields];
    const valueIndex = newFields.findIndex(
      (config: any) => config.label === "value",
    );
    if (object?.condition === "NULL") {
      newFields[valueIndex].required = false;
    }

    const { canBeSubmit, showError } = defaultValidation(fields, object);
    setErrorInputForm(showError);
    if (canBeSubmit) {
      let array = value || [];
      const newObject = { ...object };
      const valueTypeIndex = Object.keys(newObject.value)[0];
      const valueCellElement = newObject.value[valueTypeIndex];
      switch (valueTypeIndex) {
        case "time":
        case "dateTime": {
          newObject.value = dayjs(new Date(valueCellElement)).format(
            "DD/MM/YYYY",
          );
          break;
        }
        case "select":
        case "select-checkbox": {
          newObject.value = Array.isArray(valueCellElement)
            ? valueCellElement?.map((el: any) => el.value).join(";")
            : valueCellElement.value;
          break;
        }
        case "autocompleteGroups": {
          newObject.value = valueCellElement.value;
          break;
        }
        default:
          newObject.value = Array.isArray(valueCellElement)
            ? valueCellElement.length > 0
              ? valueCellElement.join(";")
              : null
            : valueCellElement;
          break;
      }
      array.push({ ...newObject });
      onChange(array);
      setObject(inputObject);
      handleFetchData({ pageIndex: 0, pageSize: PAGE_SIZE });
      setPagination({ pageIndex: 0, pageSize: PAGE_SIZE });
    }
  };

  const handleDelete = (obj: any) => {
    let newList = value.filter((item: any) => item !== obj);
    if (newList.length === 1) {
      newList[0] = { ...newList[0], operator: "AND" };
    }

    onChange(newList.length === 0 ? null : newList);
  };

  const groupsOptions = useCallback(() => {
    try {
      const { output } = dynamicGroupsList;
      if (output) {
        setGroupsList(
          output.map((group: any) => {
            return {
              label: group?.name || "",
              value: group?.id || "",
            };
          }),
        );
      } else {
        setGroupsList([]);
      }
    } catch (error) {
      console.error(error);
      setGroupsList([]);
    }
  }, [dynamicGroupsList]);

  const getOptionDisabled = (row: any) => {
    if (idGroup && row.value === Number(idGroup)) {
      return true;
    } else if (value.find((el: any) => Number(el.value) === row.value)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!isLoading && `${object.condition_field}`.toLowerCase() === "group") {
      groupsOptions();
    }
  }, [object, pagination, dynamicGroupsList]);

  const onChangePagination = (newValue?: string) => {
    if (newValue || newValue === "") {
      if (newValue === "") {
        handleFetchData({
          pageIndex: 0,
          pageSize: PAGE_SIZE,
        });
        setPagination({
          pageIndex: 0,
          pageSize: PAGE_SIZE,
        });
      } else {
        handleFetchData({ pageIndex: 0, pageSize: PAGE_SIZE, name: newValue });
        setPagination({ pageIndex: 0, pageSize: PAGE_SIZE, name: newValue });
      }
    } else {
      if (pagination.pageSize <= totalElement) {
        handleFetchData({
          ...pagination,
          pageSize: pagination.pageSize + PAGE_SIZE,
        });
        setPagination({
          ...pagination,
          pageSize: pagination.pageSize + PAGE_SIZE,
        });
      }
    }
  };

  const getInputsList = (col: any) => {
    if (object?.condition === "NULL") {
      col.disabled = true;
    } else {
      col.disabled = false;
    }
    switch (col.type) {
      case "select":
      case "select-checkbox": {
        return (
          <MultiSelectCst
            id={col.accessorKey}
            label={t("value") || ""}
            optionsKey={col.optionsKey}
            value={object["value"][col.type]}
            optionsToDisable={col.optionsToDisable}
            disabled={col.disabled}
            onChange={(e: any) => {
              let newValue = JSON.parse(JSON.stringify(e));
              let newInputForm = JSON.parse(JSON.stringify(object));
              setObject({
                ...newInputForm,
                ...{ value: { [col.type]: newValue } },
              });
            }}
            options={col.options}
            required={object?.condition !== "NULL"}
            error={errorInputForm["value"] ? true : false}
            errorMessage={
              tForm(`error.field.${getErrMsg("value")}`, {
                min: minLength(col.accessorKey),
                max: maxLength(col.accessorKey),
              }) || "Error"
            }
          />
        );
      }
      case "autocompleteGroups": {
        return (
          <AutocompleteVirtualScrollCst
            key={col.accessorKey}
            id={col.accessorKey}
            label={t("value") || ""}
            value={object["value"][col.type]}
            getOptionDisabled={getOptionDisabled}
            required={object?.condition !== "NULL"}
            onChange={(e: any) => {
              setObject({ ...object, ...{ value: { [col.type]: e } } });
            }}
            onChangePagination={onChangePagination}
            totalElements={totalElement || 0}
            options={groupsList}
            error={errorInputForm["value"] ? true : false}
            errorMessage={
              tForm(`error.field.${getErrMsg("value")}`, {
                min: minLength(col.accessorKey),
                max: maxLength(col.accessorKey),
              }) || "Error"
            }
            disabled={col.disabled}
          />
        );
      }
      case "dateTime": {
        return (
          <DataPickerCst
            id={col.accessorKey}
            label={t("value") || ""}
            value={object["value"][col.type]}
            required={object?.condition !== "NULL"}
            onChange={(e: any) => {
              setObject({
                ...object,
                ...{ value: { [col.type]: dayjs(new Date(e)) } },
              });
            }}
            error={errorInputForm["value"] ? true : false}
            errorMessage={
              tForm(`error.field.${getErrMsg("value")}`, {
                min: minLength(col.accessorKey),
                max: maxLength(col.accessorKey),
              }) || "Error"
            }
            disabled={col.disabled}
          />
        );
      }
      case "time": {
        return (
          <TimePickerCst
            id={col.accessorKey}
            value={object["value"][col.type]}
            error={errorInputForm["value"] ? true : false}
            errorMessage={tForm(`error.field.${getErrMsg("value")}`) || "Error"}
            required={object?.condition !== "NULL"}
            label={t("value") || ""}
            disabled={col.disabled}
            onChange={(e: any) => {
              setObject({
                ...object,
                ...{ value: { [col.type]: dayjs(e).format("DD/MM/YYYY") } },
              });
            }}
          />
        );
      }
      default: {
        return (
          <InputCst
            key={col.accessorKey}
            id={col.accessorKey}
            label={t("value") || ""}
            value={object["value"][col.type]}
            required={object?.condition !== "NULL"}
            onChange={(e: any) => {
              setObject({
                ...object,
                ...{ value: { [col.type]: e.target.value } },
              });
            }}
            maxValue={col?.rules?.max?.value}
            multiline={col.multiline}
            rows={col.rows}
            error={errorInputForm["value"] ? true : false}
            errorMessage={
              tForm(`error.field.${getErrMsg("value")}`, {
                min: minLength(col.accessorKey),
                max: maxLength(col.accessorKey),
              }) || "Error"
            }
            disabled={col.disabled}
            textTooltip={col.tooltip}
            type={col.inpuType || "text"}
          />
        );
      }
    }
  };

  const getInputField = useMemo(() => {
    let valueInput = undefined;
    columns?.forEach((col: any) => {
      if (
        object &&
        `${object.condition_field}`.toLowerCase() ===
          `${col.accessorKey}`.toLowerCase()
      ) {
        valueInput = getInputsList(col);
      }
    });
    return valueInput;
  }, [columns, object, groupsList]);

  const cellRender = (label: any, condition_field: string) => {
    const optionsKey = columns.find(
      (el) => el.accessorKey.toLowerCase() === condition_field.toLowerCase(),
    )?.optionsKey;
    if (optionsKey) {
      if (label.split(";").length > 1) {
        const newLabel = label.split(";");
        return newLabel
          .map((el: string) => t(`options.${optionsKey}.${el.toLowerCase()}`))
          .join("; ");
      }
      return t(`options.${optionsKey}.${label.toLowerCase()}`);
    } else {
      return label;
    }
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          border: `1px solid ${
            error ? theme.palette.error.main : theme.palette.text.tertiary
          }`,
          padding: "32px 16px",
          display: "flex",
          flexDirection: "column",
          borderRadius: "4px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            padding: "5px",
            position: "absolute",
            top: 0,
            left: "10px",
            backgroundColor: theme?.palette.background.paper,
            transform: "translate(0, -50%)",
            color: disabled
              ? theme.palette.text.tertiary
              : error
                ? theme.palette.error.main
                : theme.palette.text.primary,
          }}
        >
          {label}
        </Box>
        <Grid container spacing={"24px"} sx={{ justifyContent: "flex-end" }}>
          {fields.map((field: any) => {
            if (field.accessorKey === "operator" && value.length === 0) {
              return;
            }
            return (
              <Grid key={field.accessorKey} item xs={12} md={6}>
                {field.accessorKey === "operator" && value.length === 0 ? (
                  <></>
                ) : (
                  field.accessorKey !== "value" && (
                    <SelectCst
                      key={field.accessorKey}
                      id={field.accessorKey}
                      optionsKey={field.optionsKey}
                      options={dataSet[field.accessorKey]}
                      extraOptions={
                        field.accessorKey === "condition_field"
                          ? extraOptions
                          : []
                      }
                      label={field.header}
                      value={object[field.accessorKey]}
                      required={field.required}
                      onChange={(e: any) => {
                        if (
                          e === "GROUP" &&
                          field.accessorKey === "condition_field"
                        ) {
                          setObject({
                            ...object,
                            ...{ [field.accessorKey]: e },
                            ...{ condition: "EQUALS" },
                          });
                        } else {
                          if (
                            e === "NULL" &&
                            field.accessorKey === "condition"
                          ) {
                            setObject({
                              ...object,
                              ...{ [field.accessorKey]: e },
                              ...{ value: { text: null } },
                            });
                          } else {
                            setObject({
                              ...object,
                              ...{ [field.accessorKey]: e },
                            });
                          }
                        }
                      }}
                      textTooltip={field.tooltip}
                      disabled={
                        (field.accessorKey === "operator" &&
                          value.length === 0) ||
                        (object.condition_field === "GROUP" &&
                          field.accessorKey === "condition")
                      }
                      error={errorInputForm[field.accessorKey] ? true : false}
                      errorMessage={
                        tForm(`error.field.${getErrMsg(field.accessorKey)}`, {
                          min: minLength(field.accessorKey),
                          max: maxLength(field.accessorKey),
                        }) || "Error"
                      }
                    />
                  )
                )}
                {field.accessorKey === "value" &&
                  (getInputField ? (
                    getInputField
                  ) : (
                    //PLACEHOLDER INPUT
                    <InputCst
                      key={field.accessorKey}
                      id={field.accessorKey}
                      label={field.header}
                      value={object["value"]["text"]}
                      required={object?.condition !== "NULL"}
                      onChange={(e: any) =>
                        setObject({
                          ...object,
                          ...{ value: { text: e.target.value } },
                        })
                      }
                      textTooltip={field.tooltip}
                      disabled={
                        !object["condition_field"] ||
                        (object.condition && object?.condition === "NULL")
                      }
                      error={errorInputForm[field.accessorKey] ? true : false}
                      errorMessage={
                        tForm(`error.field.${getErrMsg("value")}`, {
                          min: minLength(field.accessorKey),
                          max: maxLength(field.accessorKey),
                        }) || "Error"
                      }
                    />
                  ))}
              </Grid>
            );
          })}
          <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
            <ButtonCst
              id={"add-btn"}
              onClick={() => handleAddObject()}
              variant="contained"
              title={`Add ${label}`}
              disabled={disabled}
              sx={{
                textAlign: "right",
                [theme.breakpoints.down("sm")]: { width: "100%" },
              }}
            >
              <Add />
            </ButtonCst>
          </Grid>
        </Grid>
        {value && value.length > 0 && (
          <Box
            sx={{
              pt: 2,
              mt: 4,
              borderTop: `1px solid ${theme.palette.text.primary}`,
              [theme.breakpoints.up("sm")]: { pt: 3 },
            }}
          >
            {value.map((item: any, indexList: any) => {
              const { operator, condition_field, condition, value } = item;
              const keys = Object.keys({
                operator,
                condition_field,
                condition,
                value,
              });
              const labels = Object.values({
                operator,
                condition_field,
                condition,
                value,
              });
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    py: 2,
                    [theme.breakpoints.up("sm")]: {
                      display: "grid",
                      gridTemplateColumns: "0.5fr 1fr 1fr 1fr 1fr",
                      gridGap: "24px",
                      py: 1,
                    },
                    "&:not(:first-child)": {
                      borderTop: "1px solid rgba(255, 255, 255, 0.5)",
                    },
                  }}
                >
                  <ButtonCst
                    id={"delete-entry"}
                    onClick={() => handleDelete(item)}
                    title="Delete entry"
                    sx={{
                      p: 0,
                      minWidth: "auto",
                      height: "24px",
                      [theme.breakpoints.down("sm")]: {
                        order: 3,
                        alignSelf: "flex-end",
                      },
                    }}
                  >
                    <Delete />
                  </ButtonCst>
                  {labels.map((label: any, index: any) => (
                    <>
                      {!(keys[index] === "operator" && indexList === 0) ? (
                        <Typography sx={{ wordBreak: "break-word" }}>
                          {keys[index] === "value"
                            ? cellRender(label, condition_field)
                            : extraOptions.find(
                                  (option: any) => option.value === label,
                                )
                              ? extraOptions.find(
                                  (option: any) => option.value === label,
                                ).label
                              : t(
                                  `options.${keys[index]}-group.${label}`.toLowerCase(),
                                )}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ wordBreak: "break-word" }}
                        ></Typography>
                      )}
                    </>
                  ))}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginLeft: "10px",
          fontSize: "11px",
          color: `${theme.palette.error.main}`,
        }}
      >
        {error ? errorMessage : ""}
      </span>
    </Grid>
  );
};

export default ConditionGroupsInputs;
