import { styled as styledMUI } from "@mui/material";
import styled from "styled-components";

export const ButtonGroup = styled.div`
  margin: 32px 0 0 10px;
`;

export const CustomLi = styledMUI("li")`
    & ~ li {
        color: ${(props) => props.theme.table?.main};
        .MuiListItemIcon-root {
            color: ${(props) => props.theme.table?.main};
        }
    }
    & ~ li.Mui-disabled {
        color: unset;
        .MuiListItemIcon-root {
            color: unset;
        }
    }
    & + li {
        display: none;
        & + li {
            display: none;
        }
    }
`;

export const Row = styled.div`
  display: -webkit-box;
  max-width: auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
