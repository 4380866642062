import { useIsInclude } from "../../RoutersCst";
import { PagesID } from "@utils/utilsConfigurations";

import Corporate from "@pages/Contents/Corporate";

import AddCorporate from "@pages/Contents/Corporate/AddCorporate";
import EditCorporate from "@pages/Contents/Corporate/EditCorporate";
import DetailCorporate from "@pages/Contents/Corporate/DetailCorporate";
import ProtectedRoute from "@components/ProtectedRoute";

//CORPORATE DETAIL IMPORTS
import useCorporateUser from "./sections/CorporateUser";
import useCorporateBadgeCertificate from "./sections/CorporateBadgeCertificate";
import useCorporateMediaLibrary from "./sections/CorporateMediaLibrary";
import useCorporateInitiative from "./sections/CorporateInitiative";
import useCorporateTerms from "./sections/CorporateTerms";
import useCorporateSkills from "./sections/CorporateSkills";
import useCorporateCatalog from "./sections/CorporateCatalog";
import useCorporateGroups from "./sections/CorporateGroups";
import useCorporateEditions from "./sections/CorporateEditions";
import useCorporateLocations from "./sections/CorporateLocations";
import Runtastic from "@pages/Contents/Corporate/Runtastic";
// import useCorporateQuicksight from "./sections/CorporateQuicksight";
import useCorporateSurvey from "./sections/CorporateSurvey";
import useTeachersArea from "../teachersArea/teachersArea";
import useSelfSignupRoutes from "../SelfSignup/SelfSignupRoutes";

const useCorporateRoutes = () => {
  const { isInclude } = useIsInclude();

  const corporateUser = useCorporateUser();
  const corporateBadgeCertificate = useCorporateBadgeCertificate();
  const corporateMediaLibrary = useCorporateMediaLibrary();
  const corporateInitiative = useCorporateInitiative();
  const corporateTerms = useCorporateTerms();
  const corporateSkills = useCorporateSkills();
  const corporateCatalog = useCorporateCatalog();
  const corporateGroups = useCorporateGroups();
  const corporateEditions = useCorporateEditions();
  const corporateLocations = useCorporateLocations();
  // const corporateQuicksight = useCorporateQuicksight();
  const corporateSurvey = useCorporateSurvey();
  const teachersArea = useTeachersArea();
  const selfSignupRoutes = useSelfSignupRoutes();

  return {
    path: "corporate",
    children: [
      {
        index: true,
        path: "",
        element: (
          <ProtectedRoute isAllowed={isInclude("corporate")}>
            <Corporate />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute isAllowed={isInclude("corporate")}>
            <AddCorporate />
          </ProtectedRoute>
        ),
      },
      {
        path: "edit/:id",
        element: (
          <ProtectedRoute isAllowed={isInclude("corporate")}>
            <EditCorporate />
          </ProtectedRoute>
        ),
      },
      {
        path: ":id/runtastic",
        element: (
          <ProtectedRoute isAllowed={isInclude("corporate")}>
            <Runtastic />
          </ProtectedRoute>
        ),
      },
      {
        path: ":id/detail",
        children: [
          {
            index: true,
            path: "",
            loader: () => ({
              title: PagesID["corporate.detail"],
            }),
            element: (
              <ProtectedRoute isAllowed={isInclude("corporate")}>
                <DetailCorporate />
              </ProtectedRoute>
            ),
          },
          //CHILDREN SECTION
          ...corporateUser, //USER AND CUSTOM FIELD
          corporateBadgeCertificate, //BADGES AND CERTIFICATES
          corporateMediaLibrary, //MEDIA LIBRARY
          corporateInitiative, //INITIATIVE
          corporateTerms, //TERMS
          corporateSkills, //SKILLS
          corporateCatalog, //CATALOG
          ...corporateGroups, //GROUPS
          ...corporateEditions, //EDITIONS
          corporateLocations, //LOCATIONS
          // corporateQuicksight, //QUICKSIGHT
          corporateSurvey, //SURVEY
          teachersArea, //TEACHERS,
          selfSignupRoutes, // SELF-SIGNUP
        ],
      },
    ],
  };
};

export default useCorporateRoutes;
