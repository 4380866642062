import { useEffect, useState } from "react";
import { Association } from "../../../../../interfaces/Common";
import { PagesID } from "@utils/utilsConfigurations";
import { ServicesURL } from "@utils/utilsApi";

const useGroupsAssociationAddEdit = ({
  corporateId,
  dataDetail,
}: {
  corporateId: string;
  dataDetail?: any;
}) => {
  const [openAssociations, setOpenAssociations] = useState<
    boolean | Association
  >(false);
  const [groupsAssociation, setGroupsAssociation] = useState<any>([]);

  useEffect(() => {
    if (dataDetail) {
      if (!!dataDetail) {
        if (dataDetail?.group) {
          setGroupsAssociation([
            { id: dataDetail.group?.id, label: dataDetail.group?.name },
          ]);
        }
      }
    }
  }, [dataDetail]);
  const setOpenAssociation = (accessorKey: string, value: boolean) => {
    switch (accessorKey) {
      case "group":
        setOpenAssociations(
          value
            ? {
                accessorKey: "group",
                mediaType: "group",
                type: "table",
                titleKey: "groups-association",
                pageId: PagesID["corporate.groups"],
                service:
                  ServicesURL["static-groups"] + `?id_corporate=${corporateId}`,
                multiChoice: false,
              }
            : value,
        );
        break;
    }
  };

  const getValues = (accessorKey: string) => {
    switch (accessorKey) {
      case "group":
        return groupsAssociation;
    }
  };

  const handleAssociation = (accessorKey: string, value: any) => {
    switch (accessorKey) {
      case "group":
        const objToGroupsAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return groupsAssociation.find((elem: any) => elem.id === +key);
            }
          },
        );
        setGroupsAssociation(objToGroupsAssociation);
        break;
    }
  };

  const handleDelete = (accessorKey: string, e: any) => {
    switch (accessorKey) {
      case "group":
        setGroupsAssociation(
          groupsAssociation.filter((element: any) => element.id !== e.id),
        );
        break;
    }
  };

  return {
    openAssociations,
    groupsAssociation,
    setOpenAssociation,
    handleAssociation,
    handleDelete,
    getValues,
  };
};

export default useGroupsAssociationAddEdit;
