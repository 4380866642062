import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import BackgroundImage from "@components/BackgroundImageCst";
import CardHomeCst from "@components/CardHomeCst";
import HeroHomeCst from "@components/HeroHomeCst";
import { useMenu, useNavigaionRoutes } from "@hooks/useNavigation";
import { useEffect } from "react";
import { useAuth } from "@context/auth";
import { useNavigate } from "react-router-dom";
import ModalDownloadUploadCst from "@components/ModalDownloadUploadCst";

import AnimationFadeIn from "@components/AnimationFadeIn";
import { configurationPermissionToolBar } from "@utils/utilsConfigurations";

const catalogSection = [
  "catalog.activities",
  "catalog.courses",
  "catalog.paths",
  "catalog.educational-formats",
];

const Home: React.FC = () => {
  const { t } = useTranslation(["home-page"]);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [modalTitle, setModalTitle] = useState<string | null>();
  //REFACTOR temporary just to open right modal
  const [permissionModalOpen, setPermissionModalOpen] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (user && !user.roles?.includes("PRODUCT_OWNER")) {
      navigate(`/corporate/${user.organization_id}/detail`);
    }
  }, [user]);

  const menuItem = useMenu();
  const navigation = useNavigaionRoutes();

  let catalogMenu = [];
  if (menuItem.length > 0) {
    catalogMenu = navigation
      .find((el) => el.to === "catalog")
      ?.children?.filter((el: { section: string }) => {
        return (
          user?.permissions?.includes(el.section) &&
          catalogSection?.includes(el.section)
        );
      });
  }

  const handleUpload = (section: string, title: string) => {
    //REFACTOR temporary just to open right modal
    if (section === "permissions") {
      setPermissionModalOpen(!permissionModalOpen);
    }
    setModalTitle(title);
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"300px"}
        image="section-home.jpg"
        position={"absolute"}
        zIndex={-1}
        fullpage={true}
      />
      <Container maxWidth="xxl">
        <HeroHomeCst />
        <Grid container spacing={3}>
          {menuItem.map(
            (
              {
                text,
                section,
                image,
                to,
                disabled,
                description,
                buttonLabel,
                link,
                external,
              },
              index,
            ) => {
              if (section === "permissions") {
                return (
                  <Grid key={index} item xs={12} md={6} xl={4}>
                    <CardHomeCst
                      image={"section-paths.jpg"}
                      title={text}
                      description={description}
                      secondaryBtnLabel={buttonLabel}
                      uploadBtn={true}
                      handleUpload={() => handleUpload(section, text)}
                    />
                  </Grid>
                );
              } else if (section === "quicksight") {
                return (
                  <Grid key={index} item xs={12} md={6} xl={4}>
                    <CardHomeCst
                      image={image}
                      title={text}
                      description={description}
                      secondaryBtnLabel={buttonLabel}
                      to={to}
                      disabled={disabled}
                    />
                  </Grid>
                );
              } else {
                return (
                  <Grid key={index} item xs={12} md={6} xl={4}>
                    <CardHomeCst
                      image={image}
                      title={text}
                      description={description}
                      secondaryBtnLabel={buttonLabel}
                      to={to}
                      disabled={disabled}
                    />
                  </Grid>
                );
              }
            },
          )}

          <ModalDownloadUploadCst
            open={permissionModalOpen}
            setOpen={setPermissionModalOpen}
            title={modalTitle || ""}
            data={configurationPermissionToolBar}
          />
        </Grid>
      </Container>
    </AnimationFadeIn>
  );
};

export default Home;
