// Import
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import "dayjs/locale/it";
import { Box } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers";
import "./style.css";

// Interface
interface DataPickerCstInterface {
  id: string;
  label?: string;
  value: any;
  placeholder?: string;
  onChange: (value: any) => void;
  customWidth?: string;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  required?: boolean;
  sx?: { [key: string]: string };
}

// eslint-disable-next-line no-empty-pattern
const TimePickerCst: React.FC<DataPickerCstInterface> = (props) => {
  const {
    id,
    label,
    error,
    value,
    onChange,
    errorMessage,
    disabled,
    required,
    sx,
  } = props;

  // const [initial, setInitial] = useState<any>();

  return (
    <Box
      id={id}
      sx={{
        minWidth: "200px",
        width: "100%",
        ...sx,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
        <DemoContainer
          sx={{ width: "100%", pt: 0, overflow: "visible", display: "block" }}
          components={["TimePicker"]}
        >
          <DateTimePicker
            label={label}
            value={value ? dayjs(value) : null}
            onChange={(value: any) => {
              onChange(value);
            }}
            slotProps={{
              textField: {
                size: "small",
                error: error,
                helperText: error ? errorMessage : "",
                required: required,
              },
            }}
            sx={{
              width: "100%",
            }}
            disabled={disabled}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
};

export default TimePickerCst;
