import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { OutcomesInterface } from "@components/ModalCst";
import {
  useFetchDataDetail,
  useFetchDetail,
  usePostUploadDetail,
} from "@hooks/useFetchDetail";
import {
  ServicesURL,
  downloadFile,
  getAllErrorsAsString,
  onErrorMessage,
  uploadFile,
} from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { MethodType, TotalKeyURL } from "src/services/type";
import { useErrorContext } from "@context/ErrorContext";
import { isTrueValue } from "@utils/utilsValidators";
import useSWR from "swr";
import { customApi } from "src/services/config";

export const useAddEditCorporate = (
  triggetCorporate: (input: any) => any,
  getInitialOutcome: () => {
    [x: string]: { code?: string; message?: string; title?: string };
  },
  id?: string,
  mutate?: any,
) => {
  const { t } = useTranslation(["corporate-page"]);

  const [outcome, setOutcome] = React.useState<any>(getInitialOutcome());
  const [openOutcome, setOpenOutcome] = React.useState(false);

  const { setErrorContext, setOpenErrorContext } = useErrorContext();

  const { dataDetail: dataDetailSmartConfiguration } = useFetchDataDetail(
    id
      ? `${ServicesURL.getDetailSmartConfigurator.replace(
          ":idCorporate",
          `${id}`,
        )}/0`
      : null,
    PagesID.smartconfigurator,
  );

  const { dataDetail: dataRuntastic } = useFetchDetail(
    `${ServicesURL.getRuntastic}`,
    id || "",
    PagesID["corporate.runtastic"],
    {
      onError: (error: any) => {
        if (error.response.status === 404) {
          return;
        } else {
          const message = onErrorMessage(error);
          setErrorContext({
            title: "Error",
            content: message,
          });
          setOpenErrorContext(true);
        }
      },
    },
  );

  const { trigger: triggerUploadNotifications } = usePostUploadDetail(
    ServicesURL["smartconfigurator-notifications"],
    {
      onError: () => {},
    },
  );
  const { trigger: triggerUploadTemplate } = usePostUploadDetail(
    ServicesURL["smartconfigurator-template-mail"],
    {
      onError: () => {},
    },
  );

  const { trigger: triggerMultilanguageWeb } = usePostUploadDetail(
    `${ServicesURL.uploadMultilanguage
      .replace("{platform}", "WEB")
      .replace("{corporateId}", id || "")}`,
    {
      onError: () => {},
    },
  );

  const { trigger: triggerMultilanguageMobile } = usePostUploadDetail(
    `${ServicesURL.uploadMultilanguage
      .replace("{platform}", "MOBILE")
      .replace("{corporateId}", id || "")}`,
    {
      onError: () => {},
    },
  );

  const { data: multiLanguageWebData } = useSWR(
    `${`${ServicesURL.downloadMultilanguage}`
      .replace("{platform}", "WEB")
      .replace("{corporateId}", id || "")}` || "",
    (url: string) => customApi(url, MethodType.GET),
    {
      onError: () => {},
    },
  );

  const { data: multiLanguageMobileData } = useSWR(
    `${`${ServicesURL.downloadMultilanguage}`
      .replace("{platform}", "MOBILE")
      .replace("{corporateId}", id || "")}` || "",
    (url: string) => customApi(url, MethodType.GET),
    {
      onError: () => {},
    },
  );

  const onSubmit = async (
    inputForm: any,
    fileNotificationsAsset: any,
    fileTemplateAsset: any,
    participations: any,
    fileTheme: any,
    fileUploaded: { [x: string]: any },
  ) => {
    /**
     * Submit is composed by:
     * 1) Submit of form without upload --> just POST to corporate --> show confirm modal
     * 2) Submit of form and at least 1 upload --> POST to corporate and 2 POST for template and notifications --> show outcome modal
     */
    setOpenOutcome(true);
    const {
      uploadMultilanguageMobile = undefined,
      uploadMultilanguageWeb = undefined,
      uploadTemplateWeb = undefined,
      uploadTemplatePush = undefined,
    } = fileUploaded || {};
    const initialOutcomeState: any = getInitialOutcome();
    if (!fileNotificationsAsset) {
      delete initialOutcomeState.uploadNotifications;
    }
    if (!fileTemplateAsset) {
      delete initialOutcomeState.uploadTemplateMail;
    }
    if (!uploadTemplateWeb) {
      delete initialOutcomeState.uploadTemplateWeb;
    }
    if (!uploadTemplatePush) {
      delete initialOutcomeState.uploadTemplatePush;
    }
    if (!fileTheme) {
      delete initialOutcomeState.uploadTheme;
    }
    if (!uploadMultilanguageMobile) {
      delete initialOutcomeState.uploadMultilanguageMobile;
    }
    if (!uploadMultilanguageWeb) {
      delete initialOutcomeState.uploadMultilanguageWeb;
    }
    setOutcome(initialOutcomeState);

    const newOutcome: any = JSON.parse(JSON.stringify(initialOutcomeState));

    if (
      [false, "false"].includes(inputForm.onboarding) &&
      dataRuntastic?.yourGoals?.enabled === true
    ) {
      setErrorContext({
        title: "Error",
        content: `${t("modal-message.runtastic")}`,
      });
      setOpenErrorContext(true);
      setOpenOutcome(false);
    } else {
      try {
        const objTest = {
          ...inputForm,
          enable_mobile_code_sending: isTrueValue(
            inputForm.enable_mobile_code_sending,
          ),
          secondary_languages:
            inputForm.secondary_languages?.map((el: any) => el.value) || [],
          enable_skills_profession: isTrueValue(
            inputForm.enable_skills_profession,
          ),
        };
        const responseCorporate = await triggetCorporate(objTest);
        newOutcome.corporate.code = "OK";
        newOutcome.corporate.message = t("edit.outcome.corporate.messageOK");

        if (fileTemplateAsset) {
          try {
            const formattedParams: any = {
              fileZip: {
                contentFile:
                  fileTemplateAsset.base64?.split(",") &&
                  fileTemplateAsset.base64?.split(",")[1],
                nomeFile: fileTemplateAsset.file.name.split(".")[0],
                tipoFile:
                  fileTemplateAsset.file.type.split("/") &&
                  fileTemplateAsset.file.type.split("/")[1],
              },
              corporateId: responseCorporate.id,
            };
            await triggerUploadTemplate(formattedParams);
            newOutcome.uploadTemplateMail.code = "OK";
            newOutcome.uploadTemplateMail.message = t(
              "edit.outcome.upload-template.messageOK",
            );
          } catch (err) {
            newOutcome.uploadTemplateMail.code = "KO";
            newOutcome.uploadTemplateMail.message = t(
              "edit.outcome.upload-template.messageErrorDuringCorporate",
            );
          }
        }

        if (uploadTemplateWeb) {
          try {
            const formattedParams: any = {
              fileZip: {
                contentFile:
                  uploadTemplateWeb.base64?.split(",") &&
                  uploadTemplateWeb.base64?.split(",")[1],
                nomeFile: uploadTemplateWeb.file.name.split(".")[0],
                tipoFile:
                  uploadTemplateWeb.file.type.split("/") &&
                  uploadTemplateWeb.file.type.split("/")[1],
              },
              corporateId: responseCorporate.id,
            };
            await triggerUploadTemplate(formattedParams);
            newOutcome.uploadTemplateWeb.code = "OK";
            newOutcome.uploadTemplateWeb.message = t(
              "edit.outcome.upload-template.messageOK",
            );
          } catch (err) {
            newOutcome.uploadTemplateWeb.code = "KO";
            newOutcome.uploadTemplateWeb.message = t(
              "edit.outcome.upload-template.messageErrorDuringCorporate",
            );
          }
        }

        if (uploadTemplatePush) {
          try {
            const formattedParams: any = {
              fileZip: {
                contentFile:
                  uploadTemplatePush.base64?.split(",") &&
                  uploadTemplatePush.base64?.split(",")[1],
                nomeFile: uploadTemplatePush.file.name.split(".")[0],
                tipoFile:
                  uploadTemplatePush.file.type.split("/") &&
                  uploadTemplatePush.file.type.split("/")[1],
              },
              corporateId: responseCorporate.id,
            };
            await triggerUploadTemplate(formattedParams);
            newOutcome.uploadTemplatePush.code = "OK";
            newOutcome.uploadTemplatePush.message = t(
              "edit.outcome.upload-template.messageOK",
            );
          } catch (err) {
            newOutcome.uploadTemplatePush.code = "KO";
            newOutcome.uploadTemplatePush.message = t(
              "edit.outcome.upload-template.messageErrorDuringCorporate",
            );
          }
        }

        if (fileNotificationsAsset) {
          if (
            (uploadTemplateWeb &&
              fileTemplateAsset &&
              uploadTemplatePush &&
              newOutcome.uploadTemplateWeb.code === "OK" &&
              newOutcome.uploadTemplateMail.code === "OK" &&
              newOutcome.uploadTemplatePush.code === "OK") ||
            (dataDetailSmartConfiguration.templateMailName &&
              dataDetailSmartConfiguration.templateWebappName &&
              dataDetailSmartConfiguration.templatePushName)
          ) {
            try {
              const formattedParams: any = {
                fileCSV: {
                  contentFile:
                    fileNotificationsAsset.base64?.split(",") &&
                    fileNotificationsAsset.base64?.split(",")[1],
                  nomeFile: fileNotificationsAsset.file.name.split(".")[0],
                  tipoFile:
                    fileNotificationsAsset.file.type.split("/") &&
                    fileNotificationsAsset.file.type.split("/")[1],
                },
                corporateId: responseCorporate.id,
              };
              await triggerUploadNotifications(formattedParams);
              newOutcome.uploadNotifications.code = "OK";
              newOutcome.uploadNotifications.message = t(
                "edit.outcome.upload-notifications.messageOK",
              );
            } catch (error) {
              newOutcome.uploadNotifications.code = "KO";
              newOutcome.uploadNotifications.message =
                getAllErrorsAsString(error);
            }
          } else {
            newOutcome.uploadNotifications.code = "KO";
            newOutcome.uploadNotifications.message = t(
              "add.outcome.upload-notifications.messageErrorTemplates",
            );
          }
        }

        if (fileTheme) {
          try {
            await uploadFile(
              `${ServicesURL.uploadAdminTheme}/${responseCorporate.id}`,
              { file: fileTheme.file },
            );
            newOutcome.uploadTheme.code = "OK";
            newOutcome.uploadTheme.message = t(
              "edit.outcome.upload-template.messageOK",
            );
          } catch (error) {
            newOutcome.uploadTheme.code = "KO";
            newOutcome.uploadTheme.message = t(
              "edit.outcome.upload-theme.messageErrorDuringCorporate",
            );
          }
          setOutcome(newOutcome);
        }

        if (uploadMultilanguageMobile) {
          try {
            const formattedParams: any = {
              fileCsv: {
                contentFile:
                  uploadMultilanguageMobile.base64?.split(",") &&
                  uploadMultilanguageMobile.base64?.split(",")[1],
                nomeFile: uploadMultilanguageMobile.file.name.split(".")[0],
                tipoFile:
                  uploadMultilanguageMobile.file.type.split("/") &&
                  uploadMultilanguageMobile.file.type.split("/")[1],
              },
            };
            await triggerMultilanguageMobile(formattedParams);
            newOutcome.uploadMultilanguageMobile.code = "OK";
            newOutcome.uploadMultilanguageMobile.message = t(
              "edit.outcome.upload-template.messageOK",
            );
          } catch (error) {
            newOutcome.uploadMultilanguageMobile.code = "KO";
            newOutcome.uploadMultilanguageMobile.message =
              getAllErrorsAsString(error);
          }
        }

        if (uploadMultilanguageWeb) {
          try {
            const formattedParams: any = {
              fileCsv: {
                contentFile:
                  uploadMultilanguageWeb.base64?.split(",") &&
                  uploadMultilanguageWeb.base64?.split(",")[1],
                nomeFile: uploadMultilanguageWeb.file.name.split(".")[0],
                tipoFile:
                  uploadMultilanguageWeb.file.type.split("/") &&
                  uploadMultilanguageWeb.file.type.split("/")[1],
              },
            };
            await triggerMultilanguageWeb(formattedParams);
            newOutcome.uploadMultilanguageWeb.code = "OK";
            newOutcome.uploadMultilanguageWeb.message = t(
              "edit.outcome.upload-template.messageOK",
            );
          } catch (error) {
            newOutcome.uploadMultilanguageWeb.code = "KO";
            newOutcome.uploadMultilanguageWeb.message =
              getAllErrorsAsString(error);
          }
        }

        setOutcome(newOutcome);
      } catch (error) {
        newOutcome.corporate.code = "KO";
        newOutcome.corporate.message = getAllErrorsAsString(error);
        if (fileNotificationsAsset) {
          newOutcome.uploadNotifications.code = "KO";
          newOutcome.uploadNotifications.message = t(
            "edit.outcome.upload-notifications.messageErrorDuringCorporate",
          );
        }
        if (fileTemplateAsset) {
          newOutcome.uploadTemplate.code = "KO";
          newOutcome.uploadTemplate.message = t(
            "edit.outcome.upload-template.messageErrorDuringCorporate",
          );
        }
        if (fileTheme) {
          newOutcome.uploadTheme.code = "KO";
          newOutcome.uploadTheme.message = t(
            "edit.outcome.upload-theme.messageErrorDuringCorporate",
          );
        }
        setOutcome(newOutcome);
      }
      mutate && mutate(inputForm);
    }
  };

  const inputsException = (col: any, inputForm: any) => {
    if (col?.accessorKey === "secondary_languages") {
      if (
        inputForm.enable_multilanguage === "true" ||
        inputForm.enable_multilanguage === true
      ) {
        col.disabled = false;
      } else {
        col.disabled = true;
      }
    }
    if (col?.accessorKey === "enable_multilanguage") {
      if (
        inputForm.enable_multilanguage === "true" ||
        inputForm.enable_multilanguage === true
      ) {
        col.callBack = (e: any) => {
          return {
            ...inputForm,
            ...{ secondary_languages: [], [col.accessorKey]: e },
          };
        };
      }
    }
    return col;
  };

  const onDownload = (service: string, acessorKey: string, type?: string) => {
    const url: any = ServicesURL[service as TotalKeyURL];
    if (type === "template") {
      downloadFile(service);
      return;
    }
    switch (acessorKey) {
      case "uploadNotification": {
        downloadFile(
          url +
            `?corporateId=${id}&fileName=${encodeURIComponent(
              dataDetailSmartConfiguration.notificationName,
            )}`,
        );
        break;
      }
      case "uploadTemplate": {
        if (dataDetailSmartConfiguration.templateMailName) {
          downloadFile(
            url +
              `?corporateId=${id}&fileName=${encodeURIComponent(
                dataDetailSmartConfiguration.templateMailName,
              )}`,
          );
        }
        break;
      }
      case "uploadTemplateWeb": {
        if (dataDetailSmartConfiguration.templateWebappName) {
          downloadFile(
            url +
              `?corporateId=${id}&fileName=${encodeURIComponent(
                dataDetailSmartConfiguration.templateWebappName,
              )}`,
          );
        }
        break;
      }
      case "uploadTemplatePush": {
        if (dataDetailSmartConfiguration.templatePushName) {
          downloadFile(
            url +
              `?corporateId=${id}&fileName=${encodeURIComponent(
                dataDetailSmartConfiguration.templatePushName,
              )}`,
          );
        }
        break;
      }
      case "uploadTheme": {
        downloadFile(service);
        break;
      }
      case "uploadMultilanguageWeb":
      case "uploadMultilanguageMobile": {
        const type = acessorKey === "uploadMultilanguageWeb" ? "WEB" : "MOBILE";
        downloadFile(
          `${`${ServicesURL.downloadMultilanguage}`
            .replace("{platform}", type)
            .replace("{corporateId}", id || "")}` || "",
          false,
          type === "WEB"
            ? dataDetailSmartConfiguration.labelTranslationWebName
            : dataDetailSmartConfiguration.labelTranslationMobileName,
          {
            headers: {
              accept: "text/csv",
            },
          },
        );
        break;
      }
    }
  };

  return {
    inputsException,
    onSubmit,
    onDownload,
    outcome,
    setOutcome,
    openOutcome,
    setOpenOutcome,
    multiLanguageWebData:
      multiLanguageWebData &&
      multiLanguageWebData?.headers["content-disposition"]
        ? multiLanguageWebData?.headers["content-disposition"].split(
            "filename=",
          )[1]
        : "",
    multiLanguageMobileData:
      multiLanguageMobileData &&
      multiLanguageMobileData?.headers["content-disposition"]
        ? multiLanguageMobileData?.headers["content-disposition"].split(
            "filename=",
          )[1]
        : "",
  };
};
