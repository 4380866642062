import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";

import BadgeAndCertificates from "@pages/Contents/BadgeAndCertificates";
import Certificates from "@pages/Contents/BadgeAndCertificates/Certificates";
import Badge from "@pages/Contents/BadgeAndCertificates/Badge";
import AddBadge from "@pages/Contents/BadgeAndCertificates/Badge/AddBadge";
import EditBadge from "@pages/Contents/BadgeAndCertificates/Badge/EditBadge";
import EditCertificates from "@pages/Contents/BadgeAndCertificates/Certificates/EditCertificates";
import AddCertificates from "@pages/Contents/BadgeAndCertificates/Certificates/AddCertificates";

const useBadgeCertificatesRoutes = () => {
  const { isInclude } = useIsInclude();
  return {
    path: "badge-certificates",
    children: [
      {
        index: true,
        path: "",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              "corporate&&badge.template.read&&certificate.template.read",
            )}
          >
            <BadgeAndCertificates />
          </ProtectedRoute>
        ),
      },
      {
        path: "certificates",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("corporate&&certificate.template.read")}
              >
                <Certificates />
              </ProtectedRoute>
            ),
          },
          {
            path: "add",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&certificate.template.read&&certificate.template.write",
                )}
              >
                <AddCertificates />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit/:idCertificate",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&certificate.template.read&&certificate.template.write",
                )}
              >
                <EditCertificates />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "badges",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("corporate&&badge.template.read")}
              >
                <Badge />
              </ProtectedRoute>
            ),
          },
          {
            path: "add",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&badge.template.read&&badge.template.write",
                )}
              >
                <AddBadge />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit/:idBadge",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&badge.template.read&&badge.template.write",
                )}
              >
                <EditBadge />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  };
};

export default useBadgeCertificatesRoutes;
