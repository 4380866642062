import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";
import { LOType, PagesID } from "@utils/utilsConfigurations";

import CatalogCorporatePage from "@pages/Contents/Corporate/Catalog";

import Activities from "@pages/Contents/Corporate/Catalog/Activities";
import AddActivities from "@pages/Contents/Corporate/Catalog/Activities/AddActivities";
import EditActivities from "@pages/Contents/Corporate/Catalog/Activities/EditActivities";

import Courses from "@pages/Contents/Corporate/Catalog/Courses";
import AddCourses from "@pages/Contents/Corporate/Catalog/Courses/AddCourses";
import EditCourses from "@pages/Contents/Corporate/Catalog/Courses/EditCourses";

import Paths from "@pages/Contents/Corporate/Catalog/Paths";
import AddPaths from "@pages/Contents/Corporate/Catalog/Paths/AddPaths";
import EditPaths from "@pages/Contents/Corporate/Catalog/Paths/EditPaths";

import AssociationLODomain from "@pages/Contents/Corporate/Catalog/sharedComponents/AssociationDomain";
import LOReminderInitiatives from "@pages/Contents/Corporate/Catalog/sharedComponents/SolicitInitiatives";
import LOSolicitGroup from "@pages/Contents/Corporate/Catalog/sharedComponents/SolicitInitiatives/SolicitGroups";
import LOSolicitUser from "@pages/Contents/Corporate/Catalog/sharedComponents/SolicitInitiatives/SolicitUser";
import LOSuggestInitiatives from "@pages/Contents/Corporate/Catalog/sharedComponents/SuggestInitiatives";
import LOSuggestGroups from "@pages/Contents/Corporate/Catalog/sharedComponents/SuggestInitiatives/SuggestGroups";
import LOSuggestUsers from "@pages/Contents/Corporate/Catalog/sharedComponents/SuggestInitiatives/SuggestUser";
import LOPreregisteredUsers from "@pages/Contents/Corporate/Catalog/sharedComponents/PreregisteredUsers";

import AssociationLODomainUsers from "@pages/Contents/Corporate/Catalog/sharedComponents/AssociationDomainUsers";
import AssociationLOGroup from "@pages/Contents/Corporate/Catalog/sharedComponents/AssociationGroups";
import AssociationLOUser from "@pages/Contents/Corporate/Catalog/sharedComponents/AssociationUser";
import LOSubscribedUsers from "@pages/Contents/Corporate/Catalog/sharedComponents/SubscribedUsers";

import UsersWating from "@pages/Contents/Corporate/Catalog/sharedComponents/UsersWating";
import Enrollments from "@pages/Contents/Corporate/Catalog/sharedComponents/EnrollmentsForm";
import EnrollHistory from "@pages/Contents/Corporate/Catalog/sharedComponents/EnrollHistory";

const useCorporateCatalog = () => {
  const { isInclude } = useIsInclude();

  return {
    path: "catalog",
    children: [
      {
        path: "",
        element: (
          <ProtectedRoute isAllowed={isInclude("corporate&&corporate.catalog")}>
            <CatalogCorporatePage />
          </ProtectedRoute>
        ),
      },
      {
        path: "courses",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("corporate&&corporate.catalog")}
              >
                <Courses />
              </ProtectedRoute>
            ),
          },
          {
            path: "add",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning_catalogue.learning_object.write",
                )}
              >
                <AddCourses />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit/:idLO",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning_catalogue.learning_object.write",
                )}
              >
                <EditCourses />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/users-waiting",
            children: [
              {
                index: true,
                path: "",
                element: (
                  <ProtectedRoute isAllowed={isInclude("catalog")}>
                    <UsersWating />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: ":loId/domain-association",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning_catalogue.learning_object.associate.initiatives",
                )}
              >
                <AssociationLODomain
                  type={LOType.COURSES}
                  page={PagesID["corporate.catalog.courses.domain-association"]}
                />
              </ProtectedRoute>
            ),
          },
          {
            path: ":loId/domain-association/:domainId/enrollment",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning_catalogue.learning_object.associate.initiatives",
                )}
              >
                <Enrollments />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/domain-users-association",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <AssociationLODomainUsers type={LOType.COURSES} />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/domain-users-association/:userDomainId/enrollment",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <Enrollments />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/group-association",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <AssociationLOGroup type={LOType.COURSES} />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/pre-registered",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <LOPreregisteredUsers
                  page={PagesID["corporate.catalog.activities.pre-registered"]}
                />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/group-association/:groupType/:groupId/enrollment",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <Enrollments />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/users-association",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <AssociationLOUser type={LOType.COURSES} />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/users-association/:userId/enrollment",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <Enrollments />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/subscribed",
            children: [
              {
                index: true,
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <LOSubscribedUsers
                      page={PagesID["corporate.catalog.courses.subscribed"]}
                    />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":userId/enrollment",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <Enrollments />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":groupType/:groupId/enrollment",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <Enrollments />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":userId/enrollment-history",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <EnrollHistory />
                  </ProtectedRoute>
                ),
              },
              {
                path: "initiatives-solicit",
                children: [
                  {
                    index: true,
                    path: "",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude(
                          "corporate&&corporate.catalog&&learning.enrollment.read",
                        )}
                      >
                        <LOReminderInitiatives
                          type={LOType.COURSES}
                          page={
                            PagesID[
                              "corporate.catalog.courses.subscribed.initiatives-solicit"
                            ]
                          }
                        />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ":idInitiative/groups",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude(
                          "corporate&&corporate.catalog&&learning.enrollment.read",
                        )}
                      >
                        <LOSolicitGroup
                          type={LOType.COURSES}
                          page={
                            PagesID[
                              "corporate.catalog.courses.subscribed.initiatives-solicit.groups"
                            ]
                          }
                        />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ":idInitiative/domain",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude(
                          "corporate&&corporate.catalog&&learning.enrollment.read",
                        )}
                      >
                        <LOSolicitUser
                          type={LOType.COURSES}
                          page={
                            PagesID[
                              "corporate.catalog.courses.subscribed.initiatives-solicit.domain"
                            ]
                          }
                        />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: ":idLO/initiatives-suggest",
            children: [
              {
                index: true,
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <LOSuggestInitiatives
                      type={LOType.COURSES}
                      page={
                        PagesID[
                          "corporate.catalog.courses.subscribed.initiatives-suggest"
                        ]
                      }
                    />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":idInitiative/groups",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <LOSuggestGroups
                      type={LOType.COURSES}
                      page={
                        PagesID[
                          "corporate.catalog.courses.subscribed.initiatives-suggest.groups"
                        ]
                      }
                    />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":idInitiative/domain",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <LOSuggestUsers
                      type={LOType.COURSES}
                      page={
                        PagesID[
                          "corporate.catalog.courses.subscribed.initiatives-suggest.domain"
                        ]
                      }
                    />
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "activities",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("corporate&&corporate.catalog")}
              >
                <Activities />
              </ProtectedRoute>
            ),
          },
          {
            path: "add",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning_catalogue.learning_object.write",
                )}
              >
                <AddActivities />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit/:idLO",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning_catalogue.learning_object.write",
                )}
              >
                <EditActivities />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/users-waiting",
            children: [
              {
                index: true,
                path: "",
                element: (
                  <ProtectedRoute isAllowed={isInclude("catalog")}>
                    <UsersWating />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: ":loId/domain-association",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning_catalogue.learning_object.associate.initiatives",
                )}
              >
                <AssociationLODomain
                  type={LOType.ACTIVITIES}
                  page={
                    PagesID["corporate.catalog.activities.domain-association"]
                  }
                />
              </ProtectedRoute>
            ),
          },
          {
            path: ":loId/domain-association/:domainId/enrollment",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning_catalogue.learning_object.associate.initiatives",
                )}
              >
                <Enrollments />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/domain-users-association",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <AssociationLODomainUsers type={LOType.ACTIVITIES} />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/domain-users-association/:userDomainId/enrollment",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <Enrollments />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/group-association",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <AssociationLOGroup type={LOType.ACTIVITIES} />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/group-association/:groupType/:groupId/enrollment",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <Enrollments />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/users-association",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <AssociationLOUser type={LOType.ACTIVITIES} />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/users-association/:userId/enrollment",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <Enrollments />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/subscribed",
            children: [
              {
                index: true,
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <LOSubscribedUsers
                      page={PagesID["corporate.catalog.activities.subscribed"]}
                    />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":userId/enrollment",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <Enrollments />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":groupType/:groupId/enrollment",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <Enrollments />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":userId/enrollment-history",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <EnrollHistory />
                  </ProtectedRoute>
                ),
              },
              {
                path: "initiatives-solicit",
                children: [
                  {
                    index: true,
                    path: "",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude(
                          "corporate&&corporate.catalog&&learning.enrollment.read",
                        )}
                      >
                        <LOReminderInitiatives
                          type={LOType.ACTIVITIES}
                          page={
                            PagesID[
                              "corporate.catalog.activities.subscribed.initiatives-solicit"
                            ]
                          }
                        />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ":idInitiative/groups",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude(
                          "corporate&&corporate.catalog&&learning.enrollment.read",
                        )}
                      >
                        <LOSolicitGroup
                          type={LOType.ACTIVITIES}
                          page={
                            PagesID[
                              "corporate.catalog.activities.subscribed.initiatives-solicit.groups"
                            ]
                          }
                        />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ":idInitiative/domain",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude(
                          "corporate&&corporate.catalog&&learning.enrollment.read",
                        )}
                      >
                        <LOSolicitUser
                          type={LOType.ACTIVITIES}
                          page={
                            PagesID[
                              "corporate.catalog.activities.subscribed.initiatives-solicit.domain"
                            ]
                          }
                        />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: ":idLO/initiatives-suggest",
            children: [
              {
                index: true,
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <LOSuggestInitiatives
                      type={LOType.ACTIVITIES}
                      page={
                        PagesID[
                          "corporate.catalog.activities.subscribed.initiatives-suggest"
                        ]
                      }
                    />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":idInitiative/groups",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <LOSuggestGroups
                      type={LOType.ACTIVITIES}
                      page={
                        PagesID[
                          "corporate.catalog.activities.subscribed.initiatives-suggest.groups"
                        ]
                      }
                    />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":idInitiative/domain",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <LOSuggestUsers
                      type={LOType.ACTIVITIES}
                      page={
                        PagesID[
                          "corporate.catalog.activities.subscribed.initiatives-suggest.domain"
                        ]
                      }
                    />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: ":idLO/pre-registered",
            children: [
              {
                index: true,
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <LOPreregisteredUsers
                      page={
                        PagesID["corporate.catalog.activities.pre-registered"]
                      }
                    />
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "paths",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("corporate&&corporate.catalog")}
              >
                <Paths />
              </ProtectedRoute>
            ),
          },
          {
            path: "add",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning_catalogue.learning_object.write",
                )}
              >
                <AddPaths />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit/:idLO",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning_catalogue.learning_object.write",
                )}
              >
                <EditPaths />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/users-waiting",
            children: [
              {
                index: true,
                path: "",
                element: (
                  <ProtectedRoute isAllowed={isInclude("catalog")}>
                    <UsersWating />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: ":loId/domain-association",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning_catalogue.learning_object.associate.initiatives",
                )}
              >
                <AssociationLODomain
                  type={LOType.PATHS}
                  page={PagesID["corporate.catalog.path.domain-association"]}
                />
              </ProtectedRoute>
            ),
          },
          {
            path: ":loId/domain-association/:domainId/enrollment",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning_catalogue.learning_object.associate.initiatives",
                )}
              >
                <Enrollments />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/domain-users-association",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <AssociationLODomainUsers type={LOType.PATHS} />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/domain-users-association/:userDomainId/enrollment",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <Enrollments />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/group-association",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <AssociationLOGroup type={LOType.PATHS} />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/group-association/:groupType/:groupId/enrollment",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <Enrollments />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/users-association",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <AssociationLOUser type={LOType.PATHS} />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/users-association/:userId/enrollment",
            element: (
              <ProtectedRoute
                isAllowed={isInclude(
                  "corporate&&corporate.catalog&&learning.enrollment.read",
                )}
              >
                <Enrollments />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idLO/subscribed",
            children: [
              {
                index: true,
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <LOSubscribedUsers
                      page={PagesID["corporate.catalog.path.subscribed"]}
                    />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":userId/enrollment",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <Enrollments />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":groupType/:groupId/enrollment",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <Enrollments />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":userId/enrollment-history",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <EnrollHistory />
                  </ProtectedRoute>
                ),
              },
              {
                path: "initiatives-solicit",
                children: [
                  {
                    index: true,
                    path: "",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude(
                          "corporate&&corporate.catalog&&learning.enrollment.read",
                        )}
                      >
                        <LOReminderInitiatives
                          type={LOType.PATHS}
                          page={
                            PagesID[
                              "corporate.catalog.path.subscribed.initiatives-solicit"
                            ]
                          }
                        />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ":idInitiative/groups",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude(
                          "corporate&&corporate.catalog&&learning.enrollment.read",
                        )}
                      >
                        <LOSolicitGroup
                          type={LOType.PATHS}
                          page={
                            PagesID[
                              "corporate.catalog.path.subscribed.initiatives-solicit.groups"
                            ]
                          }
                        />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ":idInitiative/domain",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude(
                          "corporate&&corporate.catalog&&learning.enrollment.read",
                        )}
                      >
                        <LOSolicitUser
                          type={LOType.PATHS}
                          page={
                            PagesID[
                              "corporate.catalog.path.subscribed.initiatives-solicit.domain"
                            ]
                          }
                        />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: ":idLO/initiatives-suggest",
            children: [
              {
                index: true,
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <LOSuggestInitiatives
                      type={LOType.PATHS}
                      page={
                        PagesID[
                          "corporate.catalog.path.subscribed.initiatives-suggest"
                        ]
                      }
                    />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":idInitiative/groups",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <LOSuggestGroups
                      type={LOType.PATHS}
                      page={
                        PagesID[
                          "corporate.catalog.path.subscribed.initiatives-suggest.groups"
                        ]
                      }
                    />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":idInitiative/domain",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude(
                      "corporate&&corporate.catalog&&learning.enrollment.read",
                    )}
                  >
                    <LOSuggestUsers
                      type={LOType.PATHS}
                      page={
                        PagesID[
                          "corporate.catalog.path.subscribed.initiatives-suggest.domain"
                        ]
                      }
                    />
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "users-waiting",
        children: [
          {
            index: true,
            path: "",
            // loader: () => ({
            //   title: PagesID["corporate.catalog.users-waiting"],
            // }),
            element: (
              <ProtectedRoute isAllowed={isInclude("catalog")}>
                <UsersWating />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  };
};

export default useCorporateCatalog;
