import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { OutcomesInterface } from "@components/ModalCst";
import {
  useFetchDataDetail,
  usePatchDetail,
  usePostDetail,
  usePostUploadDetail,
  usePutUploadDetail,
} from "@hooks/useFetchDetail";
import {
  ServicesURL,
  downloadFile,
  getAllErrorsAsString,
} from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { MethodType, TotalKeyURL } from "src/services/type";
import useSWRMutation from "swr/mutation";
import { fetcher } from "src/services/config";

export const useAddEditInitiative = (
  messageType: string,
  triggerInitiative: (input: any) => any,
  getInitialOutcome: () => {
    [x: string]: {
      code?: string | null;
      message?: string | null;
      title?: string | null;
    };
  },
  id?: string,
  idInitiative?: string | number,
  mutate?: any,
  mutateSC?: any,
) => {
  const { t } = useTranslation(["initiative-page"]);

  const initialOutcome: OutcomesInterface = {
    initative: {
      code: undefined,
      message: undefined,
    },
    communityCreate: {
      code: undefined,
      message: undefined,
    },
    smartConfiguration: {
      code: undefined,
      message: undefined,
    },
    uploadBranding: {
      code: undefined,
      message: undefined,
    },
    uploadNotifications: {
      code: undefined,
      message: undefined,
    },
    uploadTemplate: {
      code: undefined,
      message: undefined,
    },
    uploadTemplateWeb: {
      code: undefined,
      message: undefined,
    },
    uploadTemplatePush: {
      code: undefined,
      message: undefined,
    },
  };

  const [outcome, setOutcome] =
    React.useState<OutcomesInterface>(initialOutcome);
  const [openOutcome, setOpenOutcome] = React.useState(false);

  const { dataDetail: dataDetailSmartConfiguration } = useFetchDataDetail(
    idInitiative
      ? `${ServicesURL.getDetailSmartConfigurator.replace(
          ":idCorporate",
          `${id}`,
        )}/${idInitiative}`
      : null,
    PagesID.smartconfigurator,
  );

  const idConfig =
    dataDetailSmartConfiguration &&
    dataDetailSmartConfiguration.idConfiguration;

  const { trigger: triggerPostSmartConfigurator } = usePostDetail(
    ServicesURL.postSmartconfigurator,
  );

  const { trigger: triggerSmartConfigurator, setId: setSmartConfiguratorId } =
    usePatchDetail(
      ServicesURL.patchDetailSmartConfigurator.replace(":idCorporate", `${id}`),
    );

  const { trigger: triggerPutUploadBrandingAsset, setId: setIdBrandingAsset } =
    usePutUploadDetail(ServicesURL["smartconfigurator-brandingAsset"]);

  const { trigger: triggerUploadBrandingAsset } = usePostUploadDetail(
    ServicesURL["smartconfigurator-brandingAsset"],
    {
      onError: () => {},
    },
  );

  const { trigger: triggerUploadNotifications } = usePostUploadDetail(
    ServicesURL["smartconfigurator-notifications"],
    {
      onError: () => {},
    },
  );
  const { trigger: triggerUploadTemplate } = usePostUploadDetail(
    ServicesURL["smartconfigurator-template-mail"],
    {
      onError: () => {},
    },
  );

  const { trigger: triggerCreateForum } = useSWRMutation(
    ServicesURL.postWrapper,
    (url: string, arg: { arg: any }) =>
      fetcher<any>(`${url}`, MethodType.POST, arg.arg),
  );

  useEffect(() => {
    setSmartConfiguratorId(`${idInitiative}`);
    setIdBrandingAsset(idConfig);
    // setIdNotifications(idConfig);
    // setIdTemplate(idConfig);
  }, [idConfig]);

  const onSubmit = async (
    initiativePayload: any,
    smartConfigurationPayload: any,
    fileBrandingAsset: any,
    fileNotificationsAsset: any,
    fileTemplateAsset: any,
    fileUploaded: { [x: string]: any },
  ) => {
    setOpenOutcome(true);

    const { uploadTemplateWeb = undefined, uploadTemplatePush = undefined } =
      fileUploaded || {};

    const initialOutcomeState: any = getInitialOutcome();

    if (!fileBrandingAsset) {
      delete initialOutcomeState.uploadBranding;
    }
    if (!fileNotificationsAsset) {
      delete initialOutcomeState.uploadNotifications;
    }
    if (!fileTemplateAsset) {
      delete initialOutcomeState.uploadTemplate;
    }
    if (!uploadTemplateWeb) {
      delete initialOutcomeState.uploadTemplateWeb;
    }
    if (!uploadTemplatePush) {
      delete initialOutcomeState.uploadTemplatePush;
    }

    setOutcome(initialOutcomeState);
    let newOutcome: any = initialOutcomeState;

    try {
      const responseInitiative = await triggerInitiative(initiativePayload);
      newOutcome.initiative.code = "OK";
      newOutcome.initiative.message = t(
        `${messageType}.outcome.initiative.messageOK`,
      );

      smartConfigurationPayload["initiativeId"] = responseInitiative?.id;
      smartConfigurationPayload["initiativeName"] = responseInitiative?.name;

      try {
        if (
          smartConfigurationPayload.funcCommunity &&
          !dataDetailSmartConfiguration.funcCommunity
        ) {
          try {
            await triggerCreateForum({
              body: {
                name: `initiative_${idInitiative}`,
                langcode: "en",
              },
              method: `${MethodType.POST}`,
              endpoint: `${ServicesURL.getForum}`,
              initiativeid: +idInitiative!,
              corporateid: +id!,
            });
            console.info("community creata");
          } catch (error: any) {
            if (error?.response?.status !== 406) {
              console.info("community NON creata", error);
              smartConfigurationPayload["funcCommunity"] = false;
              newOutcome = {
                ...newOutcome,
                communityCreate: {
                  code: "KO",
                  message: t(
                    `${messageType}.outcome.communityCreate.messageKO`,
                  ),
                  title: t(`${messageType}.outcome.communityCreate.title`),
                },
              };
            }
          }
        }

        let responseSmartConfigurator;
        if (!!idConfig) {
          responseSmartConfigurator = await triggerSmartConfigurator(
            smartConfigurationPayload,
          );
        } else {
          responseSmartConfigurator = await triggerPostSmartConfigurator(
            smartConfigurationPayload,
          );
        }

        newOutcome.smartConfiguration.code = "OK";
        newOutcome.smartConfiguration.message = t(
          `${messageType}.outcome.smart-configurator.messageOK`,
        );

        if (fileBrandingAsset) {
          const formattedParams: any = {
            fileZip: {
              contentFile:
                fileBrandingAsset.base64?.split(",") &&
                fileBrandingAsset.base64?.split(",")[1],
              nomeFile: fileBrandingAsset.file.name.split(".")[0],
              // responseInitiative?.name +
              //   "_" +
              //   fileBrandingAsset.file.name.split(".")[0],
              tipoFile:
                fileBrandingAsset.file.type.split("/") &&
                fileBrandingAsset.file.type.split("/")[1],
            },

            idConfiguration: responseSmartConfigurator?.idConfiguration,
            initiativeId: responseInitiative.id,
            corporateId: id && +id,
          };
          if (!!dataDetailSmartConfiguration.brandassetName) {
            try {
              await triggerPutUploadBrandingAsset(formattedParams);
              newOutcome.uploadBranding.code = "OK";
              newOutcome.uploadBranding.message = t(
                `${messageType}.outcome.upload-branding.messageOK`,
              );
            } catch (error) {
              newOutcome.uploadBranding.code = "KO";
              newOutcome.uploadBranding.message = getAllErrorsAsString(error);
            }
          } else {
            try {
              await triggerUploadBrandingAsset(formattedParams);
              newOutcome.uploadBranding.code = "OK";
              newOutcome.uploadBranding.message = t(
                `${messageType}.outcome.upload-branding.messageOK`,
              );
            } catch (error) {
              newOutcome.uploadBranding.code = "KO";
              newOutcome.uploadBranding.message = getAllErrorsAsString(error);
            }
          }
        }

        if (fileTemplateAsset) {
          const formattedParams: any = {
            fileZip: {
              contentFile:
                fileTemplateAsset.base64?.split(",") &&
                fileTemplateAsset.base64?.split(",")[1],
              nomeFile: fileTemplateAsset.file.name.split(".")[0],
              tipoFile:
                fileTemplateAsset.file.type.split("/") &&
                fileTemplateAsset.file.type.split("/")[1],
            },
            idConfiguration: responseInitiative?.idConfiguration,
            initiativeId: responseInitiative.id,
            corporateId: id && +id,
          };
          try {
            await triggerUploadTemplate(formattedParams);
            newOutcome.uploadTemplate.code = "OK";
            newOutcome.uploadTemplate.message = t(
              `${messageType}.outcome.upload-template.messageOK`,
            );
          } catch (error) {
            newOutcome.uploadTemplate.code = "KO";
            newOutcome.uploadTemplate.message = getAllErrorsAsString(error);
          }
        }

        if (uploadTemplateWeb) {
          try {
            const formattedParams: any = {
              fileZip: {
                contentFile:
                  uploadTemplateWeb.base64?.split(",") &&
                  uploadTemplateWeb.base64?.split(",")[1],
                nomeFile: uploadTemplateWeb.file.name.split(".")[0],
                tipoFile:
                  uploadTemplateWeb.file.type.split("/") &&
                  uploadTemplateWeb.file.type.split("/")[1],
              },
              idConfiguration: responseInitiative?.idConfiguration,
              initiativeId: responseInitiative.id,
              corporateId: id && +id,
            };
            await triggerUploadTemplate(formattedParams);
            newOutcome.uploadTemplateWeb.code = "OK";
            newOutcome.uploadTemplateWeb.message = t(
              "edit.outcome.upload-template.messageOK",
            );
          } catch (err) {
            newOutcome.uploadTemplateWeb.code = "KO";
            newOutcome.uploadTemplateWeb.message = t(
              "edit.outcome.upload-template.messageErrorDuringCorporate",
            );
          }
        }

        if (uploadTemplatePush) {
          try {
            const formattedParams: any = {
              fileZip: {
                contentFile:
                  uploadTemplatePush.base64?.split(",") &&
                  uploadTemplatePush.base64?.split(",")[1],
                nomeFile: uploadTemplatePush.file.name.split(".")[0],
                tipoFile:
                  uploadTemplatePush.file.type.split("/") &&
                  uploadTemplatePush.file.type.split("/")[1],
              },
              idConfiguration: responseInitiative?.idConfiguration,
              initiativeId: responseInitiative.id,
              corporateId: id && +id,
            };
            await triggerUploadTemplate(formattedParams);
            newOutcome.uploadTemplatePush.code = "OK";
            newOutcome.uploadTemplatePush.message = t(
              "edit.outcome.upload-template.messageOK",
            );
          } catch (err) {
            newOutcome.uploadTemplatePush.code = "KO";
            newOutcome.uploadTemplatePush.message = t(
              "edit.outcome.upload-template.messageErrorDuringCorporate",
            );
          }
        }

        if (fileNotificationsAsset) {
          if (
            (uploadTemplateWeb &&
              fileTemplateAsset &&
              uploadTemplatePush &&
              newOutcome.uploadTemplateWeb.code === "OK" &&
              newOutcome.uploadTemplateMail.code === "OK" &&
              newOutcome.uploadTemplatePush.code === "OK") ||
            (dataDetailSmartConfiguration.templateMailName &&
              dataDetailSmartConfiguration.templateWebappName &&
              dataDetailSmartConfiguration.templatePushName)
          ) {
            const formattedParams: any = {
              fileCSV: {
                contentFile:
                  fileNotificationsAsset.base64?.split(",") &&
                  fileNotificationsAsset.base64?.split(",")[1],
                nomeFile: fileNotificationsAsset.file.name.split(".")[0],
                // responseInitiative?.name +
                //   "_" +
                //   fileNotificationsAsset.file.name.split(".")[0],
                tipoFile:
                  fileNotificationsAsset.file.type.split("/") &&
                  fileNotificationsAsset.file.type.split("/")[1],
              },
              idConfiguration: responseInitiative?.idConfiguration,
              initiativeId: responseInitiative.id,
              corporateId: id && +id,
            };
            try {
              await triggerUploadNotifications(formattedParams);
              newOutcome.uploadNotifications.code = "OK";
              newOutcome.uploadNotifications.message = t(
                `${messageType}.outcome.upload-notifications.messageOK`,
              );
            } catch (error) {
              newOutcome.uploadNotifications.code = "KO";
              newOutcome.uploadNotifications.message =
                getAllErrorsAsString(error);
            }
          } else {
            newOutcome.uploadNotifications.code = "KO";
            newOutcome.uploadNotifications.message = t(
              "add.outcome.upload-notifications.messageErrorTemplates",
            );
          }
        }
      } catch (error) {
        newOutcome.communityCreate.code = "KO";
        newOutcome.communityCreate.message = t(
          `${messageType}.outcome.communityCreate.messageKO`,
        );
        newOutcome.smartConfiguration.code = "KO";
        newOutcome.smartConfiguration.message = getAllErrorsAsString(error);
      }
    } catch (error) {
      newOutcome.initiative.code = "KO";
      newOutcome.initiative.message = getAllErrorsAsString(error);

      newOutcome.smartConfiguration.code = "KO";
      newOutcome.smartConfiguration.message = getAllErrorsAsString(error);

      if (fileBrandingAsset) {
        // File notification upload but corporate got
        newOutcome.uploadBranding.code = "KO";
        newOutcome.uploadBranding.message = t(
          `${messageType}.outcome.upload-branding.messageErrorDuringCorporate`,
        );
      }

      if (fileNotificationsAsset) {
        // File notification upload but corporate got
        newOutcome.uploadNotifications.code = "KO";
        newOutcome.uploadNotifications.message = t(
          `${messageType}.outcome.upload-notifications.messageErrorDuringCorporate`,
        );
      }

      if (fileTemplateAsset) {
        // File template upload but corporate got
        newOutcome.uploadTemplate.code = "KO";
        newOutcome.uploadTemplate.message = t(
          `${messageType}.outcome.upload-template.messageErrorDuringCorporate`,
        );
      }
    }
    setOutcome(newOutcome);
    mutate && mutate(initiativePayload);
    mutateSC && mutateSC(smartConfigurationPayload);
  };

  const onDownload = (service: string, acessorKey: string, type?: string) => {
    const url: any = ServicesURL[service as TotalKeyURL];
    if (type === "template") {
      downloadFile(service);
      return;
    }
    if (acessorKey === "uploadBrandIdentity") {
      downloadFile(
        url +
          `?corporateId=${id}&fileName=${encodeURIComponent(
            dataDetailSmartConfiguration.brandassetName,
          )}`,
      );
    } else if (acessorKey === "uploadNotification") {
      downloadFile(
        url +
          `?corporateId=${id}&fileName=${encodeURIComponent(
            dataDetailSmartConfiguration.notificationName,
          )}`,
      );
    } else if (acessorKey === "uploadTemplate") {
      downloadFile(
        url +
          `?corporateId=${id}&fileName=${encodeURIComponent(
            dataDetailSmartConfiguration.templateMailName,
          )}`,
      );
    } else if (acessorKey === "uploadTemplateWeb") {
      if (dataDetailSmartConfiguration.templateWebappName) {
        downloadFile(
          url +
            `?corporateId=${id}&fileName=${encodeURIComponent(
              dataDetailSmartConfiguration.templateWebappName,
            )}`,
        );
      }
    } else if (acessorKey === "uploadTemplatePush") {
      if (dataDetailSmartConfiguration.templatePushName) {
        downloadFile(
          url +
            `?corporateId=${id}&fileName=${encodeURIComponent(
              dataDetailSmartConfiguration.templatePushName,
            )}`,
        );
      }
    }
  };

  return {
    onSubmit,
    onDownload,
    outcome,
    setOutcome,
    openOutcome,
    setOpenOutcome,
  };
};
