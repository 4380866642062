import AddEditFormCst from "@components/AddEditFormCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { ColumnInterface } from "@components/Table/types";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { Box, Container } from "@mui/material";
import { ServicesURL, onErrorMessage, uploadFile } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { MethodType } from "src/services/type";

const AddCertificates: React.FC = () => {
  const { t } = useTranslation(["certificates-page"]);
  const { id = 1 } = useParams();
  const { columns, actionsForForms } = useFetchDetail(
    ServicesURL.getCertificates,
    undefined,
    PagesID["certificates"],
  );

  const navigate = useNavigate();

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const onSubmit = async (
    inputForm: any,
    fileNotificationsAsset: any,
    fileTemplateAsset: any,
    participations: any,
    fileTheme: any,
    imagePath: any,
    pdfTemplatePath: any,
    image: any,
  ) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });

    let ObjToSend = {
      name: inputForm.name,
      description: inputForm.description,
      corporateId: id,
    };
    try {
      let formData = new FormData();
      formData.append("templateZip", pdfTemplatePath.file.get("file"));
      formData.append("image", image.file);
      formData.append("certificate", JSON.stringify(ObjToSend));
      await uploadFile(
        `${ServicesURL.getCertificates}`,
        formData,
        MethodType.POST,
      );
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      const message = onErrorMessage(error);
      setOpenToast({ s: StatusEnum.KO, message: message });
      console.error(error);
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-certificates.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              columns={columns}
              onSubmit={onSubmit}
              actionsForForms={
                actionsForForms &&
                actionsForForms.map((el: ColumnInterface) => {
                  if (el.accessorKey === "pdfTemplatePath") {
                    el.uploadCallback = (formData: FormData) => {
                      return formData;
                    };
                  }
                  return el;
                })
              }
              backButton={() => {
                navigate(-1);
              }}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={""}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default AddCertificates;
