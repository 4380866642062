import { Tooltip } from "@mui/material";
import React from "react";

import GroupIcon from "@mui/icons-material/Group";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PolicyIcon from "@mui/icons-material/Policy";
import TopicIcon from "@mui/icons-material/Topic";
import Add from "@mui/icons-material/Add";
import CategoryIcon from "@mui/icons-material/Category";
import RemoveRedEyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";
import IconCst from "@components/IconCst";
import PersonIcon from "@mui/icons-material/Person";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import PublicIcon from "@mui/icons-material/Public";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import GroupsIcon from "@mui/icons-material/Groups";
import EditIcon from "@mui/icons-material/Edit";
import {
  AccountCircle,
  AddRoadOutlined,
  AddToQueueOutlined,
  CreateNewFolder,
  Diversity3,
  MobiledataOff,
  Spellcheck,
  SettingsSuggest,
  AutoStories,
  RecentActors,
  FilterNoneTwoTone,
} from "@mui/icons-material";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";

import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";

import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import DeleteIcon from "@mui/icons-material/Delete";
import PsychologyIcon from "@mui/icons-material/Psychology";

const IconActionRow: React.FC<{ icon: string; labelTranslate: string }> = ({
  icon,
  labelTranslate,
}) => {
  const renderIcon = React.useMemo(() => {
    switch (icon) {
      case "edit":
        return <EditIcon />;
      case "link":
        return <ArrowForwardIcon />;
      case "delete":
        return <DeleteIcon />;
      case "group":
        return <GroupIcon />;
      case "CreateNewFolder":
        return <CreateNewFolder />;
      case "AddToQueueOutlined":
        return <AddToQueueOutlined />;
      case "AddRoadOutlined":
        return <AddRoadOutlined />;
      case "groups":
        return <GroupsIcon />;
      case "users":
        return <PersonIcon />;
      case "users-waiting":
        return <PeopleOutlineIcon />;
      case "footer":
        return <BorderColorIcon />;
      case "category":
        return <CategoryIcon />;
      case "privacy":
        return <PolicyIcon />;
      case "topic":
        return <TopicIcon />;
      case "visibility":
        return <RemoveRedEyeOutlined />;
      case "association":
        return <Add />;
      case "public":
        return <PublicIcon />;
      case "reducecapacity":
        return <ReduceCapacityIcon />;
      case "account-image":
        return <AccountCircle />;
      case "upload-download":
        return <MobiledataOff />;
      case "classroom":
        return <MeetingRoomIcon />;
      case "community":
        return <Diversity3 />;
      case "terms":
        return <Spellcheck />;
      case "approve":
        return <ThumbUpAltIcon />;
      case "disapprove":
        return <ThumbDownAltIcon />;
      case "edition_enrollment":
        return <WebAssetIcon />;
      case "runtastic":
        return <SettingsSuggest />;
      case "knowledge":
        return <AutoStories />;
      case "skills":
        return <PsychologyIcon />;
      case "exercise":
        return <FilterNoneTwoTone />;
      case "reports":
        return <RecentActors />;
      default:
        return <IconCst icon={icon || ""} />;
    }
  }, [icon]);

  return <Tooltip title={labelTranslate}>{renderIcon}</Tooltip>;
};

export default IconActionRow;
