import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { ServicesURL, downloadFile, uploadFile } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalDownloadUploadCst from "@components/ModalDownloadUploadCst";
import { ActionsType } from "src/interfaces/Common";
import { ActionUploadDownloadIterface } from "@components/ModalDownloadUploadCst/types";
import { KeyURL, MethodType } from "src/services/type";
import useSWRMutation from "swr/mutation";
import { fetcher } from "src/services/config";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import ButtonBackPage from "@components/ButtonBackPage";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "@context/auth";
import { usePostUploadDetail } from "@hooks/useFetchDetail";

const Subscribed: React.FC = () => {
  const { id = "1", idEdition } = useParams();
  const navigate = useNavigate();
  const [openUpload, setOpenUpload] = useState<ActionUploadDownloadIterface>();
  const { user } = useAuth();

  const {
    dataTable,
    columns,
    actionForRow,
    actionsTopToolbar,
    handleFetchData,
    mutate,
  } = useFetchTable(
    `${ServicesURL.getEnrollEditionDetail}${`?editionId=${idEdition}`}`,
    PagesID["corporate.teachersArea.my-classes-subscribed"],
  );

  const getBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const { trigger: triggerUploadParticipant } = usePostUploadDetail(
    ServicesURL.uploadParticipants +
      `/${user?.id_user}/attendanceReportEdition/${idEdition}/${id}`,
    {
      onError: () => {},
    },
  );

  const { t } = useTranslation(["teachers-area-my-classes-subscribed-page"]);

  const actionsRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "link": {
          return {
            ...action,
            callBack: (obj: any) => {
              navigate(`${obj.idUser}/revisions`);
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  const actionsToolbar: ActionsType[] | undefined = React.useMemo(() => {
    return actionsTopToolbar?.map((action: ActionsType) => {
      switch (action.type) {
        case "upload": {
          const actionModal: ActionUploadDownloadIterface = {
            ...action,
            ...{
              service: ServicesURL.postWrapper,
              types: ["upload", "download", "template"],
              onDownload: (el) => {
                if (!!el) {
                  const urlDownloadTemplatePaticipants: any =
                    ServicesURL["downloadTemplateParticipants"];
                  downloadFile(
                    urlDownloadTemplatePaticipants
                      .replace("{userEid}", user?.id_user)
                      .replace("{corporateId}", id)
                      .replace("{editionId}", idEdition),
                    false,
                    "",
                    {
                      headers: {
                        accept: "text/csv",
                      },
                    },
                  );
                } else {
                  const urlDownloadPaticipants: any =
                    ServicesURL["downloadParticipants"];

                  downloadFile(
                    urlDownloadPaticipants
                      .replace("{userEid}", user?.id_user)
                      .replace("{editionId}", idEdition),
                    false,
                    "",
                    {
                      headers: {
                        accept: "application/zip",
                      },
                      responseType: "blob",
                    },
                  );
                }
              },
              onUpload: async (formData: any) => {
                const file = formData.get("file");

                return await getBase64(formData.get("file")).then(
                  (newFile: any) => {
                    const formattedParams: any = {
                      contentFile:
                        newFile?.split(",") && newFile?.split(",")[1],
                      nomeFile: file.name.split(".csv")[0],
                      tipoFile:
                        file.type.split("/") && `.${file.type.split("/")[1]}`,
                    };

                    const response = triggerUploadParticipant(formattedParams);
                    console.log(response);
                    return response;
                  },
                );
              },
            },
          };
          return {
            ...action,
            ...{ callBack: () => setOpenUpload(actionModal) },
          };
        }
        default:
          return action;
      }
    });
  }, [actionsTopToolbar]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-my-classes.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["corporate.teachersArea.my-classes-subscribed"]}
            data={dataTable?.users || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsToolbar}
            configurationActionForRow={actionsRow}
            totalPages={dataTable?.metadata?.totalElements}
          />
        </Box>
      </Container>
      <ModalDownloadUploadCst
        open={!!openUpload}
        setOpen={() => {
          setOpenUpload(undefined);
        }}
        title={openUpload?.labelTranslate || ""}
        data={openUpload}
      />
    </AnimationFadeIn>
  );
};

export default Subscribed;
