//Configuration pages
import JsonLibraryConfig from "../assets/configurations/library-config.json";
import JsonSmartConfigurationConfig from "../assets/configurations/smart-configuration-config.json";
import JsonEditorialTextConfig from "../assets/configurations/editorial-text-config.json";
import JsonCorporateConfig from "../assets/configurations/corporate-config.json";
import JsonRuntasticConfig from "../assets/configurations/corporate-runtastic-config.json";
import JsonSelfSignupConfig from "../assets/configurations/corporate-selfsignup-config.json";
import JsonActivityConfig from "../assets/configurations/activity-config.json";
import JsonActivityDigitedConfig from "../assets/configurations/activity-digited-config.json";
import JsonCoursesConfig from "../assets/configurations/courses-config.json";
import JsonCoursesDigitedConfig from "../assets/configurations/courses-digited-config.json";
import JsonPathConfig from "../assets/configurations/path-config.json";
import JsonPathDigitedConfig from "../assets/configurations/path-digited-config.json";
import JsonCustomFieldsConfig from "../assets/configurations/custom-fields-config.json";
import JsonCustomerConfig from "../assets/configurations/customer-config.json";
import JsonDigitedUsersConfig from "../assets/configurations/digited-users-config.json";
import JsonFacultiesConfig from "../assets/configurations/faculties-config.json";
import JsonVibisilityConesConfig from "../assets/configurations/visibility-cones-config.json";
import JsonInitiativeConfig from "../assets/configurations/initiative-config.json";
import JsonTopicsConfig from "../assets/configurations/topics-config.json";
import JsonUserDomainConfig from "../assets/configurations/user-domain-config.json";
import JsonCategoriesConfig from "../assets/configurations/categories-config.json";
import JsonGroupsConfig from "../assets/configurations/groups-config.json";
import JsonGroupsDynamicConfig from "../assets/configurations/groups-dynamic-config.json";
import JsonGroupsDynamicUsersConfig from "../assets/configurations/groups-dynamic-users-config.json";
import JsonGroupsUserConfig from "../assets/configurations/groups-user-config.json";
import JsonUsersConfig from "../assets/configurations/users-config.json";
import JsonLocationsConfig from "../assets/configurations/locations-config.json";
import JsonRoomsConfig from "../assets/configurations/rooms-config.json";
import JsonPhysicalEditionsConfig from "../assets/configurations/physical-editions-config.json";
import JsonVirtualEditionsConfig from "../assets/configurations/virtual-editions-config.json";
import JsonTOJEditionsConfig from "../assets/configurations/toj-editions-config.json";
import JsonManageToj from "../assets/configurations/manage-toj-edition-config.json";
import JsonUserSubscribedToj from "../assets/configurations/user-subscribed-toj.json";
import JsonManageUserSubscribedToj from "../assets/configurations/manage-user-toj-edition-config.json";
import JsonBadgeConfig from "../assets/configurations/badge-config.json";
import JsonCertificatesConfig from "../assets/configurations/certificates-config.json";
import JsonDiscussionConfig from "../assets/configurations/discussion-config.json";
import JsonTermsConfig from "../assets/configurations/terms-config.json";
import JsonCommunityTags from "../assets/configurations/tags-config.json";
import JsonUsersWatingConfig from "../assets/configurations/users-waiting-config.json";
import JsonProfessionsConfig from "../assets/configurations/professions-config.json";
import JsonIndustriesConfig from "../assets/configurations/industries-config.json";
import JsonJobsConfig from "../assets/configurations/jobs-config.json";
import JsonKnowledgeAreasConfig from "../assets/configurations/knowledge-areas-config.json";
import JsonKnowledgeDomainsConfig from "../assets/configurations/knowledge-domains-config.json";
import JsonCapabilitiesConfig from "../assets/configurations/capabilities-config.json";
import JsonSuggestionsConfig from "../assets/configurations/suggestions-config.json";
import jobKnowledgeDomainsAssociation from "../assets/configurations/job-knowledge-domains-association.json";
import JsonDomainsSuggestionsConfig from "../assets/configurations/domain-suggestions-config.json";
import JsonCapabilitiesSuggestionsConfig from "../assets/configurations/capabilities-suggestions-config.json";
import JsonTeachersAreaReviewConfig from "../assets/configurations/teachers-area-review-config.json";
import JsonTeachersAreaRevisionsConfig from "../assets/configurations/teachers-area-revisions-config.json";
import JsonReviewAssociationConfig from "../assets/configurations/teachers-area-review-association-config.json";
import JsonTeachersAreaMyclassConfig from "../assets/configurations/teachers-area-my-classes-config.json";
import JsonTeachersAreaEditionAssociation from "../assets/configurations/teachers-area-edition-association.json";
import JsonTeachersAreaMyclassSubscribedConfig from "../assets/configurations/teachers-area-my-classes-subscribed-config.json";
import JsonTeachersAreaMyclassActivityDetailConfig from "../assets/configurations/teachers-area-my-classes-activity-detail-config.json";
import JsonTeachersAreaMyclassEditionDetailConfig from "../assets/configurations/teachers-area-my-classes-edition-detail-config.json";
// import JsonSkillsConfig from "../assets/configurations/users-waiting-config.json";
import JsonEnrollmentsConfig from "../assets/configurations/enrollments-config.json";
import JsonSurvey from "../assets/configurations/survey-config.json";

//Configurations pages associated
import JsonGroupInitiativeAssociationConfig from "../assets/configurations/associations/group-initiative-association-config.json";
import JsonCourseCategoryAssociationConfig from "../assets/configurations/associations/category-course-association-config.json";
import JsonActivityCategoryAssociationConfig from "../assets/configurations/associations/category-activity-association-config.json";
import JsonPathCategoryAssociationConfig from "../assets/configurations/associations/category-path-association-config.json";
import JsonCourseTopicAssociationConfig from "../assets/configurations/associations/topic-course-association-config.json";
import JsonActivityTopicAssociationConfig from "../assets/configurations/associations/topic-activity-association-config.json";
import JsonPathTopicAssociationConfig from "../assets/configurations/associations/topic-path-association-config.json";
import JsonSubscribedUsersConfig from "../assets/configurations/subscribedUsers-config.json";
import JsonPresubscribedUsersConfig from "../assets/configurations/preregisteredUsers-config.json";
import JsonInitiativesSolicitConfig from "../assets/configurations/initiatives-solicit-config.json";
import JsonGroupsSolicitConfig from "../assets/configurations/groups-solicit-config.json";
import JsonUsersSolicitConfig from "../assets/configurations/users-solicit-config.json";
import JsonInitiativesSuggestedConfig from "../assets/configurations/initiatives-suggested-config.json";
import JsonGroupsSuggestedConfig from "../assets/configurations/groups-suggested-config.json";
import JsonUsersSuggestedConfig from "../assets/configurations/users-suggested-config.json";
import JsonEnrollHistoryConfig from "../assets/configurations/enrollment-history-config.json";
import JsonEnrollUserEditionsConfig from "../assets/configurations/edition-enrollment-users-config.json";
import JsonSubscribedUserEditionsConfig from "../assets/configurations/subscribed-users-editions-config.json";
import { ListActionsType } from "src/interfaces/Common";
import { ServicesURL, downloadFile, uploadFile } from "./utilsApi";

type PagesIDType =
  | "catalog"
  | "activities"
  | "courses"
  | "path"
  | "users"
  | "users-waiting"
  | "educational-formats"
  | "corporate"
  | "corporate.skills"
  | "corporate.users"
  | "corporate.groups"
  | "corporate.groups-dynamic"
  | "corporate.groups.user"
  | "corporate.locations"
  | "corporate.library"
  | "corporate.initiative"
  | "corporate.activities"
  | "corporate.course"
  | "corporate.path"
  | "corporate.customFields"
  | "corporate.customers"
  | "corporate.customers.visibility-cones"
  | "corporate.initiative.topics"
  | "corporate.initiative.categories"
  | "corporate.initiative.userDomain"
  | "corporate.initiative.editorialText"
  | "skills"
  | "library"
  | "badge-certificates"
  | "faculties"
  | "external-vendor"
  | "smartconfigurator"
  | "corporate.initiative.group-association"
  | "corporate.initiative.category.course-association"
  | "corporate.initiative.category.activity-association"
  | "corporate.initiative.category.path-association"
  | "corporate.initiative.topic.course-association"
  | "corporate.initiative.topic.activity-association"
  | "corporate.initiative.topic.path-association"
  | "corporate.physical-editions"
  | "corporate.virtual-editions";

export enum PagesID {
  "home" = "home",
  // "educational-formats" = "educational-formats",
  "skills" = "skills",

  "enrollment-history" = "enrollment-history",

  "smartconfigurator" = "smartconfigurator",

  "corporate" = "corporate",
  "corporate.add" = "corporate.add",
  "corporate.edit" = "corporate.edit",
  "corporate.detail" = "corporate.detail",
  "corporate.users" = "corporate.users",
  "corporate.users.edit" = "corporate.users.edit",
  "corporate.users.add" = "corporate.users.add",
  "corporate.customFields" = "corporate.customFields",
  "corporate.library" = "corporate.library",
  "corporate.library.add" = "corporate.library.add",
  "corporate.library.edit" = "corporate.library.edit",

  "corporate.runtastic" = "corporate.runtastic",

  "corporate.selfsignup" = "corporate.selfsignup",
  "corporate.selfsignup.add" = "corporate.selfsignup.add",
  "corporate.selfsignup.edit" = "corporate.selfsignup.edit",

  "corporate.customers" = "corporate.customers",
  "corporate.customers.visibility-cones" = "corporate.customers.visibility-cones",

  "corporate.initiative" = "corporate.initiative",
  "corporate.initiative.add" = "corporate.initiative.add",
  "corporate.initiative.edit" = "corporate.initiative.edit",
  "corporate.initiative.editorialText" = "corporate.initiative.editorialText",
  "corporate.initiative.group-association" = "corporate.initiative.group-association",
  "corporate.initiative.categories" = "corporate.initiative.categories",
  "corporate.initiative.categories.add" = "corporate.initiative.categories.add",
  "corporate.initiative.categories.edit" = "corporate.initiative.categories.edit",
  "corporate.initiative.category.course-association" = "corporate.initiative.category.course-association",
  "corporate.initiative.category.activity-association" = "corporate.initiative.category.activity-association",
  "corporate.initiative.category.path-association" = "corporate.initiative.category.path-association",
  "corporate.initiative.topics" = "corporate.initiative.topics",
  "corporate.initiative.topics.add" = "corporate.initiative.topics.add",
  "corporate.initiative.topics.edit" = "corporate.initiative.topics.edit",
  "corporate.initiative.topic.course-association" = "corporate.initiative.topic.course-association",
  "corporate.initiative.topic.activity-association" = "corporate.initiative.topic.activity-association",
  "corporate.initiative.topic.path-association" = "corporate.initiative.topic.path-association",
  "corporate.initiative.discussion" = "corporate.initiative.discussion",
  "corporate.initiative.discussion.add" = "corporate.initiative.discussion.add",
  "corporate.initiative.discussion.edit" = "corporate.initiative.discussion.edit",
  "corporate.initiative.discussion.terms" = "corporate.initiative.discussion.terms",
  "corporate.initiative.discussion.terms.add" = "corporate.initiative.discussion.terms.add",
  "corporate.initiative.discussion.terms.edit" = "corporate.initiative.discussion.terms.edit",

  "corporate.skills" = "corporate.skills",

  "corporate.survey" = "corporate.survey",
  "corporate.survey.add" = "corporate.survey.add",
  "corporate.survey.edit" = "corporate.survey.edit",

  "skills.industries" = "skills.industries",
  "skills.industries.add" = "skills.industries.add",
  "skills.industries.edit" = "skills.industries.edit",

  "skills.professions" = "skills.professions",
  "skills.professions.add" = "skills.professions.add",
  "skills.professions.edit" = "skills.professions.edit",

  "skills.jobs" = "skills.jobs",
  "skills.jobs.add" = "skills.jobs.add",
  "skills.jobs.edit" = "skills.jobs.edit",

  "skills.knowledge-areas" = "skills.knowledge-areas",
  "skills.knowledge-areas.add" = "skills.knowledge-areas.add",
  "skills.knowledge-areas.edit" = "skills.knowledge-areas.edit",

  "skills.knowledge-domains" = "skills.knowledge-domains",
  "skills.knowledge-domains.add" = "skills.knowledge-domains.add",
  "skills.knowledge-domains.edit" = "skills.knowledge-domains.edit",

  "skills.capabilities" = "skills.capabilities",
  "skills.capabilities.add" = "skills.capabilities.add",
  "skills.capabilities.edit" = "skills.capabilities.edit",
  "skills.job-knowledge-domains-association" = "skills.job-knowledge-domains-association",

  "skills.suggestions-job" = "skills.suggestions-job",
  "skills.suggestions-job.domains" = "skills.suggestions-job.domains",
  "skills.suggestions-job.capabilities" = "skills.suggestions-job.capabilities",

  // "skills.skills"="skills.skills",
  // "skills.skills.add"="skills.skills.add",
  // "skills.skills.edit"="skills.skills.edit",

  "corporate.catalog" = "corporate.catalog",

  "corporate.catalog.users-waiting" = "corporate.catalog.users-waiting",
  "corporate.catalog.courses" = "corporate.catalog.courses",
  "corporate.catalog.courses.add" = "corporate.catalog.courses.add",
  "corporate.catalog.courses.edit" = "corporate.catalog.courses.edit",
  "corporate.catalog.courses.domain-association" = "corporate.catalog.courses.domain-association",
  "corporate.catalog.courses.domain-user-association" = "corporate.catalog.courses.domain-user-association",
  "corporate.catalog.courses.group-association" = "corporate.catalog.courses.group-association",
  "corporate.catalog.courses.users-association" = "corporate.catalog.courses.users-association",
  "corporate.catalog.courses.subscribed" = "corporate.catalog.courses.subscribed",
  "corporate.catalog.courses.subscribed.initiatives-solicit" = "corporate.catalog.courses.subscribed.initiatives-solicit",
  "corporate.catalog.courses.subscribed.initiatives-solicit.groups" = "corporate.catalog.courses.subscribed.initiatives-solicit.groups",
  "corporate.catalog.courses.subscribed.initiatives-solicit.domain" = "corporate.catalog.courses.subscribed.initiatives-solicit.domain",
  "corporate.catalog.courses.subscribed.initiatives-suggest" = "corporate.catalog.courses.subscribed.initiatives-suggest",
  "corporate.catalog.courses.subscribed.initiatives-suggest.groups" = "corporate.catalog.courses.subscribed.initiatives-suggest.groups",
  "corporate.catalog.courses.subscribed.initiatives-suggest.domain" = "corporate.catalog.courses.subscribed.initiatives-suggest.domain",

  "corporate.catalog.activities" = "corporate.catalog.activities",
  "corporate.catalog.activities.add" = "corporate.catalog.activities.add",
  "corporate.catalog.activities.edit" = "corporate.catalog.activities.edit",
  "corporate.catalog.activities.domain-association" = "corporate.catalog.activities.domain-association",
  "corporate.catalog.activities.domain-user-association" = "corporate.catalog.activities.domain-user-association",
  "corporate.catalog.activities.group-association" = "corporate.catalog.activities.group-association",
  "corporate.catalog.activities.users-association" = "corporate.catalog.activities.users-association",
  "corporate.catalog.activities.subscribed" = "corporate.catalog.activities.subscribed",
  "corporate.catalog.activities.subscribed.initiatives-solicit" = "corporate.catalog.activities.subscribed.initiatives-solicit",
  "corporate.catalog.activities.subscribed.initiatives-solicit.groups" = "corporate.catalog.activities.subscribed.initiatives-solicit.groups",
  "corporate.catalog.activities.subscribed.initiatives-solicit.domain" = "corporate.catalog.activities.subscribed.initiatives-solicit.domain",
  "corporate.catalog.activities.subscribed.initiatives-suggest" = "corporate.catalog.activities.subscribed.initiatives-suggest",
  "corporate.catalog.activities.subscribed.initiatives-suggest.groups" = "corporate.catalog.activities.subscribed.initiatives-suggest.groups",
  "corporate.catalog.activities.subscribed.initiatives-suggest.domain" = "corporate.catalog.activities.subscribed.initiatives-suggest.domain",
  "corporate.catalog.activities.pre-registered" = "corporate.catalog.activities.pre-registered",

  "corporate.catalog.path" = "corporate.catalog.path",
  "corporate.catalog.path.add" = "corporate.catalog.path.add",
  "corporate.catalog.path.edit" = "corporate.catalog.path.edit",
  "corporate.catalog.path.domain-association" = "corporate.catalog.path.domain-association",
  "corporate.catalog.path.domain-user-association" = "corporate.catalog.path.domain-user-association",
  "corporate.catalog.path.group-association" = "corporate.catalog.path.group-association",
  "corporate.catalog.path.users-association" = "corporate.catalog.path.users-association",
  "corporate.catalog.path.subscribed" = "corporate.catalog.path.subscribed",
  "corporate.catalog.path.subscribed.initiatives-solicit" = "corporate.catalog.path.subscribed.initiatives-solicit",
  "corporate.catalog.path.subscribed.initiatives-solicit.groups" = "corporate.catalog.path.subscribed.initiatives-solicit.groups",
  "corporate.catalog.path.subscribed.initiatives-solicit.domain" = "corporate.catalog.path.subscribed.initiatives-solicit.domain",
  "corporate.catalog.path.subscribed.initiatives-suggest" = "corporate.catalog.path.subscribed.initiatives-suggest",
  "corporate.catalog.path.subscribed.initiatives-suggest.groups" = "corporate.catalog.path.subscribed.initiatives-suggest.groups",
  "corporate.catalog.path.subscribed.initiatives-suggest.domain" = "corporate.catalog.path.subscribed.initiatives-suggest.domain",

  "corporate.groups" = "corporate.groups",
  "corporate.groups.add" = "corporate.groups.add",
  "corporate.groups.edit" = "corporate.groups.edit",
  "corporate.groups.user" = "corporate.groups.user",

  "corporate.groups-dynamic" = "corporate.groups-dynamic",
  "corporate.groups-dynamic.add" = "corporate.groups-dynamic.add",
  "corporate.groups-dynamic.edit" = "corporate.groups-dynamic.edit",
  "corporate.groups-dynamic.users" = "corporate.groups-dynamic.users",

  "corporate.physical-editions" = "corporate.physical-editions",
  "corporate.physical-editions.add" = "corporate.physical-editions.add",
  "corporate.physical-editions.edit" = "corporate.physical-editions.edit",
  "corporate.physical-editions.domain-users-association" = "corporate.physical-editions.domain-users-association",
  "corporate.physical-editions.group-association" = "corporate.physical-editions.group-association",
  "corporate.physical-editions.users-association" = "corporate.physical-editions.users-association",
  "corporate.physical-editions.subscribed" = "corporate.physical-editions.subscribed",
  "corporate.physical-editions.location-association" = "corporate.physical-editions.location-association",
  "corporate.physical-editions.rooms-association" = "corporate.physical-editions.rooms-association",

  "corporate.virtual-editions" = "corporate.virtual-editions",
  "corporate.virtual-editions.add" = "corporate.virtual-editions.add",
  "corporate.virtual-editions.edit" = "corporate.virtual-editions.edit",
  "corporate.virtual-editions.domain-users-association" = "corporate.virtual-editions.domain-users-association",
  "corporate.virtual-editions.group-association" = "corporate.virtual-editions.group-association",
  "corporate.virtual-editions.users-association" = "corporate.virtual-editions.users-association",
  "corporate.virtual-editions.subscribed" = "corporate.virtual-editions.subscribed",
  "corporate.virtual-editions.location-association" = "corporate.virtual-editions.location-association",
  "corporate.virtual-editions.rooms-association" = "corporate.virtual-editions.rooms-association",

  "corporate.toj-editions" = "corporate.toj-editions",
  "corporate.toj-editions.add" = "corporate.toj-editions.add",
  "corporate.toj-editions.edit" = "corporate.toj-editions.edit",
  "corporate.toj-manage" = "corporate.toj-manage",
  "corporate.toj-subscribed" = "corporate.toj-subscribed",
  "corporate.toj-manage-subscribed" = "corporate.toj-manage-subscribed",

  "corporate.locations" = "corporate.locations",
  "corporate.locations.add" = "corporate.locations.add",
  "corporate.locations.edit" = "corporate.locations.edit",
  "corporate.locations.rooms" = "corporate.locations.rooms",
  "corporate.locations.rooms.add" = "corporate.locations.rooms.add",
  "corporate.locations.rooms.edit" = "corporate.locations.rooms.edit",

  "corporate.tags" = "corporate.tags",
  "corporate.tags.add" = "corporate.tags.add",
  "corporate.tags.edit" = "corporate.tags.edit",
  "corporate.teachersArea.exercise-review" = "corporate.teachersArea.exercise-review",
  "corporate.teachersArea.exercise-revisions" = "corporate.teachersArea.exercise-revisions",
  "corporate.teachersArea.exercise-revisions.edit" = "corporate.teachersArea.exercise-revisions.edit",
  "corporate.teachersArea.exercise-review.edit" = "corporate.teachersArea.exercise-review.edit",
  "corporate.teachersArea.exercise-review.add" = "corporate.teachersArea.exercise-review.add",
  "corporate.teachersArea.exercise-review.association" = "corporate.teachersArea.exercise-review.association",
  "corporate.teachersArea.edition.association" = "corporate.teachersArea.edition.association",
  "corporate.teachersArea.my-classes" = "corporate.teachersArea.my-classes",
  "corporate.teachersArea.my-classes-subscribed" = "corporate.teachersArea.my-classes-subscribed",
  "corporate.teachersArea.my-classes.activity.detail" = "corporate.teachersArea.my-classes.activity.detail",
  "corporate.teachersArea.my-classes.edition.detail" = "corporate.teachersArea.my-classes.edition.detail",

  "catalog" = "catalog",
  "catalog.activities" = "catalog.activities",
  "catalog.activities.add" = "catalog.activities.add",
  "catalog.activities.edit" = "catalog.activities.edit",
  "catalog.activities.corporate-association" = "catalog.activities.corporate-association",
  "catalog.courses" = "catalog.courses",
  "catalog.courses.add" = "catalog.courses.add",
  "catalog.courses.edit" = "catalog.courses.edit",
  "catalog.courses.corporate-association" = "catalog.courses.corporate-association",
  "catalog.path" = "catalog.path",
  "catalog.path.add" = "catalog.path.add",
  "catalog.path.edit" = "catalog.path.edit",
  "catalog.path.corporate-association" = "catalog.path.corporate-association",
  "catalog.users-waiting" = "catalog.users-waiting",

  "enrollments" = "enrollments",
  "enrollment.edition.user" = "enrollment.edition.user",
  "enrollment.subscribed.edition" = "enrollment.subscribed.edition",

  "locations" = "locations",
  "locations.add" = "locations.add",
  "locations.edit" = "locations.edit",
  "locations.rooms" = "locations.rooms",
  "locations.rooms.add" = "locations.rooms.add",
  "locations.rooms.edit" = "locations.rooms.edit",

  "library" = "library",
  "library.add" = "library.add",
  "library.edit" = "library.edit",

  "third-parts" = "third-parts",

  "users" = "users",
  "users.add" = "users.add",
  "users.edit" = "users.edit",

  "faculties" = "faculties",
  "faculties.add" = "faculties.add",
  "faculties.edit" = "faculties.edit",

  "corporate.initiative.userDomain" = "corporate.initiative.userDomain",

  "badge-certificates" = "badge-certificates",

  "badge" = "badge",
  "badge.add" = "badge.add",
  "badge.edit" = "badge.edit",

  "certificates" = "certificates",
  "certificates.add" = "certificates.add",
  "certificates.edit" = "certificates.edit",
}

export const configurations: { [x in PagesID]: any } = {
  home: undefined,

  "enrollment-history": JsonEnrollHistoryConfig,

  corporate: JsonCorporateConfig,
  "corporate.add": undefined,
  "corporate.edit": undefined,

  "corporate.runtastic": JsonRuntasticConfig,

  "corporate.selfsignup": JsonSelfSignupConfig,
  "corporate.selfsignup.add": JsonSelfSignupConfig,
  "corporate.selfsignup.edit": JsonSelfSignupConfig,

  "corporate.detail": undefined,

  "corporate.users": JsonUsersConfig,
  "corporate.users.edit": undefined,
  "corporate.users.add": undefined,
  "corporate.customFields": JsonCustomFieldsConfig,

  "corporate.library": JsonLibraryConfig,
  "corporate.library.add": undefined,
  "corporate.library.edit": undefined,

  "corporate.initiative": JsonInitiativeConfig,
  "corporate.initiative.add": undefined,
  "corporate.initiative.edit": undefined,
  "corporate.initiative.editorialText": JsonEditorialTextConfig,
  "corporate.initiative.group-association":
    JsonGroupInitiativeAssociationConfig,
  "corporate.initiative.categories": JsonCategoriesConfig,
  "corporate.initiative.categories.add": undefined,
  "corporate.initiative.categories.edit": undefined,
  "corporate.initiative.category.course-association":
    JsonCourseCategoryAssociationConfig,
  "corporate.initiative.category.activity-association":
    JsonActivityCategoryAssociationConfig,
  "corporate.initiative.category.path-association":
    JsonPathCategoryAssociationConfig,
  "corporate.initiative.topics": JsonTopicsConfig,
  "corporate.initiative.topics.add": undefined,
  "corporate.initiative.topics.edit": undefined,
  "corporate.initiative.topic.course-association":
    JsonCourseTopicAssociationConfig,
  "corporate.initiative.topic.activity-association":
    JsonActivityTopicAssociationConfig,
  "corporate.initiative.topic.path-association": JsonPathTopicAssociationConfig,

  "corporate.initiative.discussion": JsonDiscussionConfig,
  "corporate.initiative.discussion.add": JsonDiscussionConfig,
  "corporate.initiative.discussion.edit": JsonDiscussionConfig,
  "corporate.initiative.discussion.terms": JsonTermsConfig,
  "corporate.initiative.discussion.terms.add": JsonTermsConfig,
  "corporate.initiative.discussion.terms.edit": JsonTermsConfig,

  "corporate.skills": undefined,

  "corporate.catalog": undefined,
  "corporate.catalog.users-waiting": JsonUsersWatingConfig,
  "corporate.catalog.courses": JsonCoursesConfig,
  "corporate.catalog.courses.add": undefined,
  "corporate.catalog.courses.edit": undefined,
  "corporate.catalog.courses.domain-association": undefined,
  "corporate.catalog.courses.domain-user-association": undefined,
  "corporate.catalog.courses.group-association": undefined,
  "corporate.catalog.courses.users-association": undefined,
  "corporate.catalog.courses.subscribed": JsonSubscribedUsersConfig,
  "corporate.catalog.courses.subscribed.initiatives-solicit":
    JsonInitiativesSolicitConfig,
  "corporate.catalog.courses.subscribed.initiatives-solicit.groups":
    JsonGroupsSolicitConfig,
  "corporate.catalog.courses.subscribed.initiatives-solicit.domain":
    JsonUsersSolicitConfig,
  "corporate.catalog.courses.subscribed.initiatives-suggest":
    JsonInitiativesSuggestedConfig,
  "corporate.catalog.courses.subscribed.initiatives-suggest.groups":
    JsonGroupsSuggestedConfig,
  "corporate.catalog.courses.subscribed.initiatives-suggest.domain":
    JsonUsersSuggestedConfig,
  "enrollment.subscribed.edition": JsonSubscribedUserEditionsConfig,

  "corporate.catalog.activities": JsonActivityConfig,
  "corporate.catalog.activities.add": undefined,
  "corporate.catalog.activities.edit": undefined,
  "corporate.catalog.activities.domain-association": undefined,
  "corporate.catalog.activities.domain-user-association": undefined,
  "corporate.catalog.activities.group-association": undefined,
  "corporate.catalog.activities.users-association": undefined,
  "corporate.catalog.activities.subscribed": JsonSubscribedUsersConfig,
  "corporate.catalog.activities.subscribed.initiatives-solicit":
    JsonInitiativesSolicitConfig,
  "corporate.catalog.activities.subscribed.initiatives-solicit.groups":
    JsonGroupsSolicitConfig,
  "corporate.catalog.activities.subscribed.initiatives-solicit.domain":
    JsonUsersSolicitConfig,
  "corporate.catalog.activities.subscribed.initiatives-suggest":
    JsonInitiativesSuggestedConfig,
  "corporate.catalog.activities.subscribed.initiatives-suggest.groups":
    JsonGroupsSuggestedConfig,
  "corporate.catalog.activities.subscribed.initiatives-suggest.domain":
    JsonUsersSuggestedConfig,
  "corporate.catalog.activities.pre-registered": JsonPresubscribedUsersConfig,

  "corporate.catalog.path": JsonPathConfig,
  "corporate.catalog.path.add": undefined,
  "corporate.catalog.path.edit": undefined,
  "corporate.catalog.path.domain-association": undefined,
  "corporate.catalog.path.domain-user-association": undefined,
  "corporate.catalog.path.group-association": undefined,
  "corporate.catalog.path.users-association": undefined,
  "corporate.catalog.path.subscribed": JsonSubscribedUsersConfig,
  "corporate.catalog.path.subscribed.initiatives-solicit":
    JsonInitiativesSolicitConfig,
  "corporate.catalog.path.subscribed.initiatives-solicit.groups":
    JsonGroupsSolicitConfig,
  "corporate.catalog.path.subscribed.initiatives-solicit.domain":
    JsonUsersSolicitConfig,
  "corporate.catalog.path.subscribed.initiatives-suggest":
    JsonInitiativesSuggestedConfig,
  "corporate.catalog.path.subscribed.initiatives-suggest.groups":
    JsonGroupsSuggestedConfig,
  "corporate.catalog.path.subscribed.initiatives-suggest.domain":
    JsonUsersSuggestedConfig,

  "corporate.groups": JsonGroupsConfig,
  "corporate.groups.add": undefined,
  "corporate.groups.edit": undefined,
  "corporate.groups.user": JsonGroupsUserConfig,

  "corporate.groups-dynamic": JsonGroupsDynamicConfig,
  "corporate.groups-dynamic.add": JsonGroupsDynamicConfig,
  "corporate.groups-dynamic.edit": JsonGroupsDynamicConfig,
  "corporate.groups-dynamic.users": JsonGroupsDynamicUsersConfig,

  "corporate.physical-editions": JsonPhysicalEditionsConfig,
  "corporate.physical-editions.add": undefined,
  "corporate.physical-editions.edit": undefined,
  "corporate.physical-editions.domain-users-association": undefined,
  "corporate.physical-editions.group-association": undefined,
  "corporate.physical-editions.users-association": undefined,
  "corporate.physical-editions.subscribed": JsonSubscribedUsersConfig, //TODO change configuration
  "corporate.physical-editions.location-association": undefined,
  "corporate.physical-editions.rooms-association": undefined,

  "corporate.virtual-editions": JsonVirtualEditionsConfig,
  "corporate.virtual-editions.add": undefined,
  "corporate.virtual-editions.edit": undefined,
  "corporate.virtual-editions.domain-users-association": undefined,
  "corporate.virtual-editions.group-association": undefined,
  "corporate.virtual-editions.users-association": undefined,
  "corporate.virtual-editions.subscribed": JsonSubscribedUsersConfig, //TODO change configuration
  "corporate.virtual-editions.location-association": undefined,
  "corporate.virtual-editions.rooms-association": undefined,

  "corporate.toj-editions": JsonTOJEditionsConfig,
  "corporate.toj-editions.add": JsonTOJEditionsConfig,
  "corporate.toj-editions.edit": JsonTOJEditionsConfig,
  "corporate.toj-manage": JsonManageToj,
  "corporate.toj-subscribed": JsonUserSubscribedToj,
  "corporate.toj-manage-subscribed": JsonManageUserSubscribedToj,

  "corporate.locations": JsonLocationsConfig,
  "corporate.locations.add": JsonLocationsConfig,
  "corporate.locations.edit": JsonLocationsConfig,
  "corporate.locations.rooms": JsonRoomsConfig,
  "corporate.locations.rooms.add": JsonRoomsConfig,
  "corporate.locations.rooms.edit": JsonRoomsConfig,

  "corporate.tags": JsonCommunityTags,
  "corporate.tags.add": JsonCommunityTags,
  "corporate.tags.edit": JsonCommunityTags,
  "corporate.teachersArea.exercise-review": JsonTeachersAreaReviewConfig,
  "corporate.teachersArea.exercise-review.edit": JsonTeachersAreaReviewConfig,
  "corporate.teachersArea.exercise-review.add": JsonTeachersAreaReviewConfig,
  "corporate.teachersArea.edition.association":
    JsonTeachersAreaEditionAssociation,
  "corporate.teachersArea.exercise-review.association":
    JsonReviewAssociationConfig,
  "corporate.teachersArea.exercise-revisions": JsonTeachersAreaRevisionsConfig,
  "corporate.teachersArea.exercise-revisions.edit":
    JsonTeachersAreaRevisionsConfig,
  "corporate.teachersArea.my-classes": JsonTeachersAreaMyclassConfig,
  "corporate.teachersArea.my-classes-subscribed":
    JsonTeachersAreaMyclassSubscribedConfig,
  "corporate.teachersArea.my-classes.activity.detail":
    JsonTeachersAreaMyclassActivityDetailConfig,
  "corporate.teachersArea.my-classes.edition.detail":
    JsonTeachersAreaMyclassEditionDetailConfig,

  "corporate.survey": JsonSurvey,
  "corporate.survey.add": JsonSurvey,
  "corporate.survey.edit": JsonSurvey,

  catalog: undefined,
  "catalog.users-waiting": JsonUsersWatingConfig,
  "catalog.activities": JsonActivityDigitedConfig,
  "catalog.activities.add": undefined,
  "catalog.activities.edit": undefined,
  "catalog.activities.corporate-association": undefined,
  "catalog.courses": JsonCoursesDigitedConfig,
  "catalog.courses.add": undefined,
  "catalog.courses.edit": undefined,
  "catalog.courses.corporate-association": undefined,
  "catalog.path": JsonPathDigitedConfig,
  "catalog.path.add": undefined,
  "catalog.path.edit": undefined,
  "catalog.path.corporate-association": undefined,

  enrollments: JsonEnrollmentsConfig,
  "enrollment.edition.user": JsonEnrollUserEditionsConfig,

  "corporate.initiative.userDomain": JsonUserDomainConfig,

  locations: undefined,
  "locations.add": undefined,
  "locations.edit": undefined,
  "locations.rooms": undefined,
  "locations.rooms.add": undefined,
  "locations.rooms.edit": undefined,

  library: JsonLibraryConfig,
  "library.add": undefined,
  "library.edit": undefined,

  "third-parts": undefined,

  users: JsonDigitedUsersConfig,
  "users.add": undefined,
  "users.edit": undefined,

  faculties: JsonFacultiesConfig,
  "faculties.add": undefined,
  "faculties.edit": undefined,

  "corporate.customers": JsonCustomerConfig,
  "corporate.customers.visibility-cones": JsonVibisilityConesConfig,

  skills: undefined,
  "skills.industries": JsonIndustriesConfig,
  "skills.industries.add": JsonIndustriesConfig,
  "skills.industries.edit": JsonIndustriesConfig,

  "skills.professions": JsonProfessionsConfig,
  "skills.professions.add": JsonProfessionsConfig,
  "skills.professions.edit": JsonProfessionsConfig,

  "skills.jobs": JsonJobsConfig,
  "skills.jobs.add": JsonJobsConfig,
  "skills.jobs.edit": JsonJobsConfig,

  "skills.knowledge-areas": JsonKnowledgeAreasConfig,
  "skills.knowledge-areas.add": JsonKnowledgeAreasConfig,
  "skills.knowledge-areas.edit": JsonKnowledgeAreasConfig,

  "skills.knowledge-domains": JsonKnowledgeDomainsConfig,
  "skills.knowledge-domains.add": JsonKnowledgeDomainsConfig,
  "skills.knowledge-domains.edit": JsonKnowledgeDomainsConfig,

  "skills.capabilities": JsonCapabilitiesConfig,
  "skills.capabilities.add": JsonCapabilitiesConfig,
  "skills.capabilities.edit": JsonCapabilitiesConfig,

  "skills.job-knowledge-domains-association": jobKnowledgeDomainsAssociation,
  "skills.suggestions-job": JsonSuggestionsConfig,
  "skills.suggestions-job.domains": JsonDomainsSuggestionsConfig,
  "skills.suggestions-job.capabilities": JsonCapabilitiesSuggestionsConfig,

  // "skills.skills":JsonSkills,
  // "skills.skills.add":undefined,
  // "skills.skills.edit":undefined,

  smartconfigurator: JsonSmartConfigurationConfig,

  "badge-certificates": undefined,

  badge: JsonBadgeConfig,
  "badge.add": undefined,
  "badge.edit": undefined,

  certificates: JsonCertificatesConfig,
  "certificates.add": undefined,
  "certificates.edit": undefined,
};

export enum LOType {
  "ACTIVITIES" = "ACTIVITY",
  "COURSES" = "COURSE",
  "PATHS" = "ROOT",
}

/* export const generatePermissions = () => {
  let permissions: string[] = [];
  const keys = Object.keys(configurations) as PagesIDType[];
  keys.forEach((key) => {
    const { actionForRow, actionsTopToolbar, actionsForForms }: any =
      configurations[key];
    permissions = [
      ...permissions,
      ...(actionForRow
        ? actionForRow.map((row: ActionsType) => `${key}.${row.permission}`)
        : []),
      ...(actionsTopToolbar
        ? actionsTopToolbar.map(
            (row: ActionsType) => `${key}.${row.permission}`
          )
        : []),
      ...(actionsForForms
        ? actionsForForms.map((row: ActionsType) => `${key}.${row.permission}`)
        : []),
    ];
  });
  return permissions;
}; */

export const configurationPermissionToolBar = {
  type: "download" as ListActionsType,
  icon: "",
  permission: "usermgmt.permissions.read",
  permissionUpload: "usermgmt.permissions.write",
  service: ``,
  label: {
    en: "",
    it: "",
  },
  types: ["upload", "download"],
  accept: "text/csv",
  tooltipFile: true,
  tooltipFileIta: "Permissions.pdf",
  tooltipFileEn: "Permissions.pdf",
  onDownload: () =>
    downloadFile(`${ServicesURL.permissions}/download?corporate_id=1`),
  onUpload: (formData: FormData) =>
    uploadFile(`${ServicesURL.permissions}?corporate_id=1`, formData),
};
