import { useAuth } from "@context/auth";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const useNavigaionRoutes = () => {
  const { t } = useTranslation(["menu", "skills-page", "common"]);
  const { t: modalT } = useTranslation(["modal"]);
  const { user } = useAuth();
  const { id } = useParams();

  let navigation: any[] = [];
  navigation = [
    {
      section: "catalog",
      text: t("catalog.title"),
      to: "catalog",
      description: t("catalog.description"),
      buttonLabel: t("manageButton", { ns: "common" }),
      iconText: "book",
      image: "section-catalog.jpg",
      children: [
        {
          section: "catalog.paths",
          text: t("paths"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "paths",
          image: "section-paths.jpg",
        },
        {
          section: "catalog.courses",
          text: t("courses"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "courses",
          image: "section-courses.jpg",
        },
        {
          section: "catalog.activities",
          text: t("activities"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "activities",
          image: "section-activities.jpg",
        },
        {
          section: "catalog.users-waiting",
          text: t("users-waiting"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "users-waiting",
          image: "section-activities.jpg",
        },
      ],
    },
    {
      section: "corporate",
      text: t("corporate.title"),
      description: t("corporate.description"),
      buttonLabel: t("manageButton", { ns: "common" }),
      to: "corporate",
      iconText: "corporate",
      image: "section-corporate.jpg",
      children: [
        {
          section: "skills.profession.retrieve",
          text: t("corporate.skills.title"),
          description: t("corporate.skills.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "skills",
          image: "section-skills.jpg",
        },
        {
          section: "corporate.users",
          text: t("corporate.users.title"),
          description: t("corporate.users.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "users",
          image: "section-users.jpg",
          iconText: "skills",
          settingsLink: "users-custom-fields",
          permissionSettingsLink: "usermgmt.corporates.custom_fields.read",
        },
        {
          section: "badge-certificates",
          text: t("badge-certificates.title"),
          description: t("badge-certificates.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "badge-certificates",
          image: "section-badge-certificate.jpg",
          children: [
            {
              section: "badge.template.read",
              text: t("badge-certificates.badge.title"),
              buttonLabel: t("manageButton", { ns: "common" }),
              to: "badges",
              image: "section-paths.jpg",
            },
            {
              section: "certificate.template.read",
              text: t("badge-certificates.certificates.title"),
              buttonLabel: t("manageButton", { ns: "common" }),
              to: "certificates",
              image: "section-courses.jpg",
            },
          ],
        },
        {
          section: "corporate.groups",
          text: t("corporate.groups.title"),
          description: t("corporate.groups.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "groups",
          image: "section-corp-groups.jpg",
        },
        {
          section: "usermgmt.corporates.dynamic_groups.read",
          text: `${t("corporate.groups-dynamic.title")}`,
          description: t("corporate.groups-dynamic.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "groups-dynamic",
          image: "section-corp-groups.jpg",
        },
        {
          section: "users.site.retrieve",
          text: t("corporate.locations.title"),
          description: t("corporate.locations.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "locations",
          image: "section-locations.jpg",
        },
        {
          section: "corporate.physical-editions",
          text: t("corporate.physical-editions.title"),
          description: t("corporate.physical-editions.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "physical-editions",
          image: "section-corp-editions-physical.jpg",
        },
        {
          section: "corporate.virtual-editions",
          text: t("corporate.virtual-editions.title"),
          description: t("corporate.virtual-editions.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "virtual-editions",
          image: "section-corp-editions-virtual.jpg",
        },
        {
          section: "corporate.mediaLibrary",
          text: t("corporate.media-library.title"),
          description: t("corporate.media-library.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          iconText: "library",
          to: "media-library",
          image: "section-library.jpg",
        },
        {
          section: "corporate.initiative",
          text: t("corporate.initiative.title"),
          description: t("corporate.initiative.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "initiative",
          image: "section-corp-initiatives.jpg",
        },
        {
          section: "cms.wrapper.moderatedterms.read",
          text: t("corporate.terms.title"),
          description: t("corporate.terms.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "terms",
          image: "section-corp-initiatives.jpg",
        },
        {
          section: "corporate.catalog",
          text: t("corporate.catalog.title"),
          description: t("corporate.catalog.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          iconText: "book",
          to: "catalog",
          image: "section-catalog.jpg",
          children: [
            {
              section: "corporate.catalog.paths",
              text: t("paths"),
              buttonLabel: t("manageButton", { ns: "common" }),
              to: "paths",
              image: "section-paths.jpg",
            },
            {
              section: "corporate.catalog.courses",
              text: t("courses"),
              buttonLabel: t("manageButton", { ns: "common" }),
              to: "courses",
              image: "section-courses.jpg",
            },
            {
              section: "corporate.catalog.activities",
              text: t("activities"),
              buttonLabel: t("manageButton", { ns: "common" }),
              to: "activities",
              image: "section-activities.jpg",
            },
            {
              section: "corporate.catalog.users-waiting",
              text: t("users-waiting"),
              buttonLabel: t("manageButton", { ns: "common" }),
              to: "users-waiting",
              image: "section-activities.jpg",
            },
          ],
        },
        {
          section: "mediamanager.media.exercise.read",
          text:
            id === "1"
              ? t("corporate.facultiesArea.title")
              : t("corporate.teachersArea.title"),
          description: t("corporate.teachersArea.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "teachers",
          image: "section-teachers.jpg",
          children: [
            {
              section: "mediamanager.media.exercise.read",
              text: t("exercise-and-reviews"),
              buttonLabel: t("manageButton", { ns: "common" }),
              to: "exercise-and-review",
              image: "section-exercise.jpg",
            },
            {
              section: "mediamanager.media.exercise.read",
              text: t("my-classes"),
              buttonLabel: t("manageButton", { ns: "common" }),
              to: "my-classes",
              image: "section-my-classes.jpg",
            },
          ],
        },
        // {
        //   section: "corporate.quicksight",
        //   text: t("corporate.quicksight.title"),
        //   description: t("corporate.quicksight.description"),
        //   buttonLabel: t("manageButton", { ns: "common" }),
        //   to: "quicksight",
        //   image: "section-quicksight.jpg",
        // },
        {
          section: "corporate.survey",
          text: t("corporate.survey.title"),
          description: t("corporate.survey.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "survey",
          image: "section-survey.jpg",
        },
        {
          section: "editions.toj.retrieve",
          text: t("corporate.toj.title"),
          description: t("corporate.toj.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "toj-edition",
          image: "section-toj.jpg",
        },
        {
          section: "selfsignup",
          text: t("corporate.selfsignup.title"),
          description: t("corporate.selfsignup.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "selfsignup",
          image: "selfsignup.jpg",
        },
      ],
    },
    {
      section: "faculties",
      text: t("faculties.title"),
      description: t("faculties.description"),
      buttonLabel: t("manageButton", { ns: "common" }),
      to: "faculties",
      image: "section-faculties.jpg",
    },
    {
      section: "skills.profession.retrieve",
      text: t("skills.title"),
      description: t("skills.description"),
      buttonLabel: t("manageButton", { ns: "common" }),
      to: "skills",
      image: "section-skills.jpg",
      children: [
        {
          section: "skills.industry.retrieve",
          text: t("skills.industries.title"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "industries",
          image: "section-skills-industry.jpg",
        },
        {
          section: "skills.profession.retrieve",
          text: t("skills.jobs-catalog.title"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "professions",
          image: "section-skills-jobs.jpg",
        },
        {
          section: "skills.knowledge.area.retrieve",
          text: t("skills.knowledge-catalog.title"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "knowledge-areas",
          image: "section-skills-knowledge.jpg",
        },
        {
          section: "skills.capabilities.retrieve",
          text: t("skills.capabilities.title"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "capabilities",
          image: "section-skills-capabilities.jpg",
        },
        {
          section: "skills.job.knowledge.suggestion.read",
          text: t("skills.suggestions.title"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "suggestions-job",
          image: "section-skills-suggestions.jpg",
        },
        {
          section: "skills.validation-autotagging",
          text: t("skills.validation-autotagging.title"),
          buttonLabel: t("uploadButton", { ns: "common" }),
          to: "validation-autotagging",
          image: "section-skills-validation.jpg",
          modalTitle: modalT("permissions"),
        },
      ],
    },
    {
      section: "users.site.retrieve",
      text: t("corporate.locations.title"),
      description: t("corporate.locations.description"),
      buttonLabel: t("manageButton", { ns: "common" }),
      to: "locations",
      image: "section-locations.jpg",
    },
    {
      section: "badge-certificates",
      text: t("badge-certificates.title"),
      description: t("badge-certificates.description"),
      buttonLabel: t("manageButton", { ns: "common" }),
      to: "badge-certificates",
      image: "section-badge-certificate.jpg",
      children: [
        {
          section: "badge-certificates.badge",
          text: t("badge-certificates.badge.title"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "badges",
          image: "section-badges.jpg",
        },
        {
          section: "badge-certificates.certificates",
          text: t("badge-certificates.certificates.title"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "certificates",
          image: "section-certificates.jpg",
        },
      ],
    },
    {
      section: "media-library",
      text: t("media-library.title"),
      description: t("media-library.description"),
      buttonLabel: t("manageButton", { ns: "common" }),
      to: "media-library",
      iconText: "library",
      image: "section-library.jpg",
    },
    {
      section: "users",
      text: t("users-digited.title"),
      description: t("users-digited.description"),
      buttonLabel: t("manageButton", { ns: "common" }),
      to: "users",
      iconText: "skills",
      image: "section-users.jpg",
    },
    {
      section: "third-parts",
      text: t("third-parts.title"),
      description: t("third-parts.description"),
      buttonLabel: t("manageButton", { ns: "common" }),
      to: "third-parts",
      // iconText: "skills",
      image: "section-third-parts.jpg",
      children: [
        {
          section: "third-parts.freshdesk",
          image: "freshdesk.png",
          text: t("third-parts.freshdesk.title"),
          description: t("third-parts.freshdesk.description"),
          buttonLabel: t("externalButton", { ns: "common" }),
          link: "https://support.freshdesk.com/en/support/home",
          external: true,
        },
        {
          section: "third-parts.accredible",
          image: "accredible.png",
          text: t("third-parts.accredible.title"),
          description: t("third-parts.accredible.description"),
          buttonLabel: t("externalButton", { ns: "common" }),
          link: "https://www.accredible.com/?utm_term=digital%20credential&utm_campaign=Generic+Research+Keywords&utm_source=adwords&utm_medium=ppc&hsa_cam=17825193541&hsa_tgt=kwd-304131253183&hsa_kw=digital%20credential&hsa_ver=3&hsa_ad=628295742609&hsa_acc=1256367924&hsa_grp=144967924771&hsa_src=g&hsa_net=adwords&hsa_mt=b&gclid=Cj0KCQjwmZejBhC_ARIsAGhCqnfJELwKtyOYAkkLsvtTE-p5LVIS3HF27ZyX7aoQKy8xRbf7GiDvxOMaAtHXEALw_wcB",
          external: true,
        },
      ],
    },
    {
      section: "editions.toj.retrieve",
      text: t("corporate.toj.title"),
      description: t("corporate.toj.description"),
      buttonLabel: t("manageButton", { ns: "common" }),
      to: "toj-edition",
      image: "section-toj.jpg",
    },
    {
      section: "permissions",
      text: t("permissions.title"),
      description: t("permissions.description"),
      buttonLabel: t("uploadButton", { ns: "common" }),
      to: "permissions",
      // iconText: "file",
      image: "section-permissions.jpg",
      modalTitle: modalT("permissions"),
    },
    // {
    //   section: "quicksight",
    //   text: t("corporate.quicksight.title"),
    //   description: t("corporate.quicksight.description"),
    //   buttonLabel: t("manageButton", { ns: "common" }),
    //   to: `quicksight`,
    //   image: "section-quicksight.jpg",
    // },
    {
      section: "mediamanager.media.exercise.read",
      text: t("corporate.facultiesArea.title"),
      description: t("corporate.teachersArea.description"),
      buttonLabel: t("manageButton", { ns: "common" }),
      to: "teachers",
      image: "section-teachers.jpg",
      children: [
        {
          section: "mediamanager.media.exercise.read",
          text: t("exercise-and-reviews"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "exercise-and-review",
          image: "section-exercise.jpg",
        },
        {
          section: "mediamanager.media.exercise.read",
          text: t("my-classes"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "my-classes",
          image: "section-my-classes.jpg",
        },
      ],
    },
  ];

  return navigation;
};

export const useMenu = () => {
  const { user } = useAuth();
  let navigation = useNavigaionRoutes();

  return (
    (navigation &&
      navigation.filter(
        (menuItem) => user && user.permissions?.includes(menuItem.section),
      )) ||
    []
  );
};
