import ProtectedRoute from "@components/ProtectedRoute";

import { useIsInclude } from "src/router/RoutersCst";
import SelfSignup from "src/pages/Contents/Corporate/SelfSignup";
import AddRegistrationRule from "@pages/Contents/Corporate/SelfSignup/Add";
import EditRegistrationRule from "@pages/Contents/Corporate/SelfSignup/Edit";

const useSelfSignupRoutes = () => {
  const { user, isInclude } = useIsInclude();
  return {
    path: "selfsignup",
    children: [
      {
        index: true,
        path: "",
        // loader: () => ({
        //   title: PagesID.skills,
        // }),
        element: (
          <ProtectedRoute
            isAllowed={isInclude("corporate&&usermgmt.selfsignup.configs.read")}
          >
            <SelfSignup />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              "corporate&&usermgmt.selfsignup.configs.write",
            )}
          >
            <AddRegistrationRule />
          </ProtectedRoute>
        ),
      },
      {
        path: "edit/:idConfig",
        element: (
          <ProtectedRoute
            isAllowed={isInclude(
              "corporate&&usermgmt.selfsignup.configs.read&&usermgmt.selfsignup.configs.write",
            )}
          >
            <EditRegistrationRule />
          </ProtectedRoute>
        ),
      },
    ],
  };
};

export default useSelfSignupRoutes;
