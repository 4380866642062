import { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/config";
import {
  MethodType,
  KeyPathsURL,
  KeyCertificateBadgesURL,
  KeyEnrollmentRemainderURL,
  KeyEnrollmentSuggestedURL,
  KeyEnrollmentURL,
  KeyURL,
  KeyUserURL,
  TotalKeyURL,
  KeyActivitiesURL,
  KeySmartConfiguratorURL,
  KeyPermissionsURL,
  KeyEditionURL,
  keyOnboarding,
  keyMediaManager,
  keySessionManager,
  keyWrapper,
  keyCommunity,
  keyUsersWaiting,
  keyIndustries,
  keyProfessions,
  keyJobs,
  keyKnowledgeAreas,
  keyKnowledgeDomains,
  keyCapabilities,
  KeyknowledgeDomainJobs,
  keyRuntastic,
  keySuggestionsKnowledgeDomains,
  keySuggestionsCapabilities,
  keySurvey,
  keyTeachersAreaExercise,
  keyTeachersAreaExerciseAssociation,
  keyTeachersAreaRevision,
  keyTeachersAreaReport,
  keyEditionTOJ,
  keySelfSignupRules,
} from "src/services/type";

export const getQueryString = (
  url: string,
  filters: { [x: string]: string },
) => {
  let queryString = "";
  Object.keys(filters).forEach((key, index) => {
    let formatKey =
      key === "pageIndex" ? "pageIndex" : key === "pageSize" ? "pageSize" : key;
    if (!url.includes("enrollment")) {
      formatKey =
        key === "pageIndex" ? "page" : key === "pageSize" ? "page_size" : key;
    }
    if (index === 0 && !url.includes("?")) {
      queryString += `?${formatKey}=${filters[key]}`;
    } else {
      queryString += `&${formatKey}=${filters[key]}`;
    }
  });
  return `${url}${queryString}`;
};

// IMPORTANT!!!! USE: METHOD + PAGE + optional:NAMEOFCALL -> PATHPARAMS MUST BE WRITE WITH :IDPARAM (LIKE IN SWAGGER - REPLACE IN COMPONENT)
const userServicesUrl: { [x in KeyUserURL]: string } = {
  getFaculties: "usermgmt/users/faculties",
  postFaculties: "usermgmt/users/faculties",
  postFaculty: "usermgmt/corporates/users/faculties",
  putFaculties: "usermgmt/corporates/users/faculties",
  putFacultiesDownload: "usermgmt/users/faculties",
  getCustomer: "usermgmt/users",
  postCustomers: "usermgmt/users",
  postCustomer: "usermgmt/corporates/users",
  putCustomer: "usermgmt/corporates/users",
  deleteCustomer: "usermgmt/users",
  putCustomerStatus: "usermgmt/users/status",
  getCorporateCustomFields: "usermgmt/corporates/custom-fields",
  postCorporateCustomFields: "usermgmt/corporates/custom-fields",
  getFacultiesDownload: "/usermgmt/users/faculties/download",
  getCustomersDownload: "/usermgmt/users/download",
  getCustomersDownloadTemplate: "/usermgmt/users/download/template",
};

const certificateBadgesServicesUrl: { [x in KeyCertificateBadgesURL]: string } =
  {
    getBadges: "badges",
    getCertificates: "certificates",
  };

const enrollmentServicesUrl: { [x in KeyEnrollmentURL]: string } = {
  getEnrollment: "enrollment/enrollments",
  postEnrollment: "enrollment/enrollments",
  deleteEnrollment: "enrollment/enrollments",
  patchEnrollment: "enrollment/enrollments",
  postEnrollmentMassive: "enrollment/massive-enrollments",
  getEnrollHistory: "enrollment/eventHistory",
  getEnrollEditionDetail: "enrollment/editions/details",
  getEnrollEdition: "enrollment/editions",
};

const enrollmentRemainderServicesUrl: {
  [x in KeyEnrollmentRemainderURL]: string;
} = {
  getEnrollmentRemainder: "enrollment/reminders",
  postEnrollmentRemainder: "enrollment/reminders",
  postEnrollmentMassiveRemainder: "enrollment/massive-reminders",
};

const enrollmentSuggestedServicesUrl: {
  [x in KeyEnrollmentSuggestedURL]: string;
} = {
  getEnrollmentSuggested: "enrollment/suggested",
  postEnrollmentSuggested: "enrollment/suggested",
  postEnrollmentMassiveSuggested: "enrollment/massive-suggested",
};

export const pathServicesUrl: { [x in KeyPathsURL]: string } = {
  getPaths: "learning-catalogue/:idCorporate/learning-objects/paths",
  postPaths: "learning-catalogue/:idCorporate/learning-objects/paths",
  putPath: "learning-catalogue/:idCorporate/learning-objects/paths",
  getActivityAssociation:
    "learning-catalogue/:idCorporate/learning-objects/didactical-activities",
  getCoursesAssociation:
    "learning-catalogue/:idCorporate/learning-objects/courses",
};

const activitiesServicesURL: { [x in KeyActivitiesURL]: string } = {
  getActivities:
    "learning-catalogue/:idCorporate/learning-objects/didactical-activities",
  getFormats: "learning-catalogue/formats",
  postActivities:
    "learning-catalogue/:idCorporate/learning-objects/didactical-activities",
  putActivities:
    "learning-catalogue/:idCorporate/learning-objects/didactical-activities",
};

const smartConfiguratorServicesURL: { [x in KeySmartConfiguratorURL]: string } =
  {
    getDownloadSmartConfigurator: "smartconfigurator/download",
    getSmartConfigurator: "smartconfigurator/configurations",
    getDetailSmartConfigurator: "smartconfigurator/configurations/:idCorporate",
    postSmartconfigurator: "smartconfigurator/configurations",
    genericSmartConfigurator: "smartconfigurator/generic",
    patchDetailSmartConfigurator:
      "smartconfigurator/configurations/:idCorporate",
    "smartconfigurator-brandingAsset":
      "smartconfigurator/configurationsBrandingAsset",
    "smartconfigurator-template-mail":
      "smartconfigurator/configurationsTemplate",
    "smartconfigurator-notifications":
      "smartconfigurator/configurationsNotifications",
    smartconfiguratorDownloadTemplate: "smartconfigurator/download/example",
  };

export const permissions: { [x in KeyPermissionsURL]: string } = {
  getSelftPermissions: "/usermgmt/permissions/self",
  postPermissions: "/usermgmt/permissions",
  putPermissions: "/usermgmt/permissions",
};

const editionsServicesUrl: { [x in KeyEditionURL]: string } = {
  getEditions: "/learning-catalogue/editions",
  deleteVirtual: "/learning-catalogue/editions/virtual",
  deletePhysical: "/learning-catalogue/editions/physical",
  uploadParticipants: "/progressTracker",
  downloadTemplateParticipants:
    "/progressTracker/{userEid}/attendanceReportTemplate/{corporateId}/{editionId}",
  downloadParticipants:
    "/progressTracker/{userEid}/attendanceReportHistory/{editionId}",
};

const onboarding: { [x in keyOnboarding]: string } = {
  postWelcomemail: "/usermgmt/onboarding/welcome",
};

const mediaManager: { [x in keyMediaManager]: string } = {
  uploadAdminTheme: "/mediamanager/admintheme",
};

const sessionManager: { [x in keySessionManager]: string } = {
  getSessionByID: "/sessions/{sessionId}",
  postSessionByID: "/sessions",
  getSessionCognito: "/cognito/{sessionId}",
};

const wrapper: { [x in keyWrapper]: string } = {
  postWrapper: "/cmswrapper",
};

const community: { [x in keyCommunity]: string } = {
  getTerms: "/api/v1/fe_admin/taxonomy/moderated_terms",
  getDiscussion: "/api/v1/fe_admin/discussion",
  getForum: "/api/v1/fe_admin/forum",
};

const user_waiting: { [x in keyUsersWaiting]: string } = {
  getUsersWaiting: "/enrollment/pendingUsers",
  patchUsersApproveDeny: "/enrollment/approveOrDecline",
};

const industries: { [x in keyIndustries]: string } = {
  getIndustries: "/skills/industries",
  postIndustries: "/skills/industries",
  putIndustries: "/skills/industries",
};

const professions: { [x in keyProfessions]: string } = {
  getProfessions: "/skills/:idCorporate/professions",
  postProfessions: "/skills/:idCorporate/professions",
  putProfessions: "/skills/:idCorporate/professions",
  putProfessionsObsolete:
    "/skills/:idCorporate/professions/:idProfession/obsolete",
};

const jobs: { [x in keyJobs]: string } = {
  getJobs: "/skills/:idCorporate/professions/:idProfession/jobs",
  postJobs: "/skills/:idCorporate/professions/:idProfession/jobs",
  putJobs: "/skills/:idCorporate/professions/:idProfession/jobs",
  putJobsObsolete:
    "/skills/:idCorporate/professions/:idProfession/jobs/:idJobs/obsolete",
  getAllJobs: "/skills/:idCorporate/jobs",
};

const knowledgeAreas: { [x in keyKnowledgeAreas]: string } = {
  getKnowledgeAreas: "/skills/:idCorporate/knowledge-areas",
  postKnowledgeAreas: "/skills/:idCorporate/knowledge-areas",
  putKnowledgeAreas: "/skills/:idCorporate/knowledge-areas/:idKnowledgeArea",
  putKnowledgeAreasObsolete:
    "/skills/:idCorporate/knowledge-areas/:idKnowledgeArea/obsolete",
};

const knowledgeDomains: { [x in keyKnowledgeDomains]: string } = {
  getKnowledgeDomainsAll: "/skills/:idCorporate/knowledge-areas/domains",
  getKnowledgeDomains:
    "/skills/:idCorporate/knowledge-areas/:idKnowledgeArea/domains",
  postKnowledgeDomains:
    "/skills/:idCorporate/knowledge-areas/:idKnowledgeArea/domains",
  putKnowledgeDomains:
    "/skills/:idCorporate/knowledge-areas/:idKnowledgeArea/domains/:idKnowledgeDomain",
  putKnowledgeDomainsObsolete:
    "/skills/:idCorporate/knowledge-areas/:idKnowledgeArea/domains/:idKnowledgeDomain/obsolete",
};
const capabilities: { [x in keyCapabilities]: string } = {
  getCapabilities: "/skills/:idCorporate/capabilities",
  postCapabilities: "/skills/:idCorporate/capabilities",
  putCapabilities: "/skills/:idCorporate/capabilities/:idCapabilities",
  putCapabilitiesObsolete:
    "/skills/:idCorporate/capabilities/:idCapabilities/obsolete",
};
const knowledgeDomainJobs: { [x in KeyknowledgeDomainJobs]: string } = {
  getKnowledgeDomainJob: "/skills/:idCorporate/knowledge-domain-job",
  postKnowledgeDomainJob: "/skills/:idCorporate/knowledge-domain-job",
  deleteKnowledgeDomainJob:
    "/skills/:idCorporate/knowledge-domain-job/:idKnowledgeDomain/:idJob",
};

const suggestionsKnowledgeDomains: {
  [x in keySuggestionsKnowledgeDomains]: string;
} = {
  getAllKnowledgeDomainsSuggestion:
    "/skills/:idCorporate/suggestions/job-knowledge-domains/suggestions/:idJob",
  getAllKnowledgeDomainsNoPaginationSuggestion:
    "/skills/:idCorporate/suggestions/job-knowledge-domains/:idJob",
  postUploadKnowledgeDomainsSuggestion:
    "/skills/:idCorporate/suggestions/job-knowledge-domains",
  getTemplateKnowledgeDomainsSuggestion:
    "/skills/:idCorporate/suggestions/job-knowledge-domains/download",
  postKnowledgeDomainsSuggestion:
    "/skills/:idCorporate/suggestions/job-knowledge-domains/suggestions/:idJob",
  deteleKnowledgeDomainSuggestion:
    "/skills/:idCorporate/suggestions/job-knowledge-domains/:idJob",
};
const suggestionsCapabilities: { [x in keySuggestionsCapabilities]: string } = {
  getAllCapabilitiesSuggestion:
    "/skills/:idCorporate/suggestions/job-capabilities/suggestions/:idJob",
  getAllCapabilitiesNoPaginationSuggestion:
    "/skills/:idCorporate/suggestions/job-capabilities/:idJob",
  postUploadCapabilitiesSuggestion:
    "/skills/:idCorporate/suggestions/job-capabilities",
  getTemplateCapabilitiesSuggestion:
    "/skills/:idCorporate/suggestions/job-capabilities/download",
  postCapabilitiesSuggestion:
    "/skills/:idCorporate/suggestions/job-capabilities/suggestions/:idJob",
  deteleCapabilitySuggestion:
    "/skills/:idCorporate/suggestions/job-capabilities/:idJob",
};
const runtastic: { [x in keyRuntastic]: string } = {
  getRuntastic: "/runtastic/configs",
};

const survey: { [x in keySurvey]: string } = {
  getSurvey: "/survey/models",
  postSurvey: "/survey/models",
  putSurvey: "/survey/models/:surveyId",
  deleteSurvey: "/survey/models/:surveyId",
};

const getLanguages = "/usermgmt/corporates/languages";
const smartconfiguratorTranslation =
  "/smartconfigurator/labelTranslations/{platform}/{corporateId}";

const multilanguage: {
  uploadMultilanguage: string;
  downloadMultilanguage: string;
} = {
  uploadMultilanguage:
    "/smartconfigurator/labelTranslations/{platform}/{corporateId}",
  downloadMultilanguage:
    "/smartconfigurator/labelTranslations/download/{platform}/{corporateId}",
};
const teachersAreaExercise: { [x in keyTeachersAreaExercise]: string } = {
  getExercises: "/mediamanager/exercises?corporateId=:corporateId",
  getSingleExercise: "/mediamanager/exercises?mediaId=:mediaId",
  putExercise: "/mediamanager/exercises/:mediaId?corporateId=:corporateId",
  postExercise: "/mediamanager/exercises/persist",
  deleteExercise: "/mediamanager/exercises/:mediaId?corporateId=:corporateId",
  getUploadUrl: "/mediamanager/exercises/presignedurl",
};
const teachersAreaExerciseAssociation: {
  [x in keyTeachersAreaExerciseAssociation]: string;
} = {
  getExercisesAssociation:
    "/learning-catalogue/:corporateId/exercises/:exerciseId/editions",
  getEditionsAssociable:
    "/learning-catalogue/:corporateId/exercises/:exerciseId/editions/all",
  putExerciseAssociation:
    "/learning-catalogue/:corporateId/editions/:editionId/exercises",
};

const teachersAreaRevisions: { [x in keyTeachersAreaRevision]: string } = {
  getRevisions:
    "/learning-catalogue/:corporateId/exercises/:exerciseId/editions/revisions",
  getrevisionsByEditions:
    "/learning-catalogue/:corporateId/editions/:editionId/revisions",
  getSingleRevisions:
    "/learning-catalogue/:corporateId/editions/:editionId/exercises/:exerciseId/revisions/:userId",
  putRevisions:
    "/learning-catalogue/:corporateId/editions/:editionId/exercises/:exerciseId/revisions/:revisionId",
  presignedRevision:
    "/learning-catalogue/:corporateId/editions/:editionId/exercises/:exerciseId/presignedurl",
  downloadRevisionsFile:
    "/learning-catalogue/:corporateId/editions/:editionId/exercises/:exerciseId/revisions/download",
};

const teachersAreaReport: { [x in keyTeachersAreaReport]: string } = {
  getReport:
    "/learning-catalogue/:corporateId/editions/exercises/:exerciseId/Report",
  getSingleReport: "",
  putReport: "",
};

const editionTOJ: { [x in keyEditionTOJ]: string } = {
  getTOJ: "/learning-catalogue/editions/toj",
  postTOJ: `/learning-catalogue/editions/toj`,
  getEditTOJ: `/learning-catalogue/editions/toj`,
  putTOJ: `/learning-catalogue/editions/toj`,
  getTOJManage: "/learning-catalogue/editions/toj/:tojId/didactical-activities",
  putTOJManage: "/learning-catalogue/editions/toj/:tojId/didactical-activities",
  getManageEnrollTOJ:
    "/enrollment/:corporateId/:userId/:tojCourseId/:tojEditionId/tojActivitiesStatus",
  postManageEnrollTOJ: "/enrollment/tojActivityStatus",
  getEnrolledUserTOJEdition:
    "/enrollment/usersAssociatedWithTojEdition/:editionId",
  deleteEnrollmentTOJ: "/enrollment/tojEdition/:corporateId",
  getUserEnrollCourseTOJ:
    "/enrollment/:corporateId/usersEnrolledToToj/:objectId",
  postEnrollToTOJEdition: "/enrollment/tojEditions",
};

const selfSignupRules: { [x in keySelfSignupRules]: string } = {
  getRule: "/usermgmt/signup/configs",
  postRule: "/usermgmt/signup/configs",
  putRule: "/usermgmt/signup/configs",
  deleteRule: "/usermgmt/signup/configs/:configId",
};

export const ServicesURL: {
  [x in TotalKeyURL]: string;
} = {
  ...sessionManager,
  ...certificateBadgesServicesUrl,
  ...userServicesUrl,
  ...enrollmentServicesUrl,
  ...enrollmentRemainderServicesUrl,
  ...enrollmentSuggestedServicesUrl,
  ...pathServicesUrl,
  ...activitiesServicesURL,
  ...smartConfiguratorServicesURL,
  ...permissions,
  ...editionsServicesUrl,
  ...onboarding,
  ...mediaManager,
  ...wrapper,
  ...community,
  ...user_waiting,
  ...industries,
  ...professions,
  ...jobs,
  ...knowledgeAreas,
  ...knowledgeDomains,
  ...capabilities,
  ...knowledgeDomainJobs,
  ...suggestionsKnowledgeDomains,
  ...suggestionsCapabilities,
  ...runtastic,
  ...multilanguage,
  ...survey,
  ...teachersAreaExercise,
  ...teachersAreaExerciseAssociation,
  ...teachersAreaRevisions,
  ...teachersAreaReport,
  ...editionTOJ,
  smartconfiguratorTranslation: smartconfiguratorTranslation,
  getLanguages: getLanguages,
  login: "login",
  achievements: "achievements",
  permissions: "/usermgmt/permissions",
  smartconfigurator: "smartconfigurator/configurations",
  corporate: "usermgmt/corporates",
  organizationTree: "/usermgmt/corporates/organizational-tree",
  visibilityCones: "/usermgmt/corporates/visibility-cones",
  categories: "usermgmt/initiatives",
  groups: "usermgmt/corporates/:idCorporate",
  staticGroups: "usermgmt/groups",
  dynamicGroups: "usermgmt/corporates/:idCorporate/dynamic-groups",
  skills: "professions-and-skills",
  initiative: "usermgmt/corporates",
  topics: "usermgmt/initiatives",
  activities: "activities",
  activitiesDigited: "activities-digited",
  catalogCorporateAssociation: "/learning-catalogue/1/learning-objects",
  catalogCorporateDisassociation: "/learning-catalogue/1/learning-objects",
  catalogDomainAssociation: "/learning-catalogue",
  catalogDomainDisassociation: "/learning-catalogue",
  courses: "courses",
  coursesDigited: "courses-digited",
  path: "path",
  pathDigited: "path-digited",
  educationalFormatsDigited: "/learning-catalogue/formats",
  catalogCategoryAssociation:
    "/learning-catalogue/:idCorporate/learning-objects/:idLO/initiatives/:initiativeId/categories",
  catalogTopicAssociation:
    "/learning-catalogue/:idCorporate/learning-objects/:idLO/initiatives/:initiativeId/topics",
  catalogCategoryDisassociation:
    "/learning-catalogue/:idCorporate/learning-objects/:idLO/initiatives/:initiativeId/categories/:idCategory",
  catalogTopicDisassociation:
    "/learning-catalogue/:idCorporate/learning-objects/:idLO/initiatives/:initiativeId/topics/:idTopic",
  digitedUsers: "usermgmt/users",
  customer: "usermgmt/users",
  faculties: "usermgmt/users/faculties",
  users: "/usermgmt/users",
  usersDomain: "user-domain",
  "static-groups": "/usermgmt/groups/static",
  library: "mediamanager",
  "external-vendors": "external-vendors",
  locations: "locations",
  sites: "usermgmt/corporates/{corporateId}/sites",
  rooms: "usermgmt/corporates/{corporateId}/rooms",
  editorialText: "editorial-text",
  enrollment: "enrollment",
  "user-initiative-association": "group-initiative-association",
  "course-category-association": "course-category-association",
  "activity-category-association": "activity-category-association",
  "path-category-association": "path-category-association",
  "course-topic-association": "course-topic-association",
  "activity-topic-association": "activity-topic-association",
  "path-topic-association": "path-topic-association",
  badges: "/badges",
  loAssociated: "/learning-catalogue/{corporateId}/learning-objects",
  "physical-editions": "/learning-catalogue/editions",
  "virtual-editions": "/learning-catalogue/editions",
  corporateCustomFields: "/usermgmt/corporates/custom-fields",
  [KeyURL.group_initiative_association]: "",
  "learning-catalogue": "learning-catalogue",
  ...selfSignupRules,
};

export const downloadFile = (
  url: string,
  isProfileImage?: boolean,
  name?: string,
  config?: any,
) => {
  axiosInstance
    .get(url, config)
    .then((res: AxiosResponse) => {
      let downloadUrl;
      let filename;

      if (res.data.public_url) {
        downloadUrl = res.data.public_url;
      } else if (!!isProfileImage) {
        const urlSplit = res.data.split("/");
        downloadUrl = res.data;
        filename = urlSplit[urlSplit.length - 1];
      } else if (res.config.url?.startsWith("http")) {
        downloadUrl = res.config.url;
        filename = res.config.url;
      } else {
        filename =
          res.headers["content-disposition"]?.split("filename=")[1] || "file";
        const type = res.headers["content-type"];
        const blob = new Blob([res.data], { type: type });
        downloadUrl = URL.createObjectURL(blob);
      }

      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.href = downloadUrl;
      link.download = name || filename;
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => console.error(error));
};

export const downloadFileMassived = (url: string, body: any, name?: string) => {
  axiosInstance
    .post(url, body)
    .then((res: AxiosResponse) => {
      let downloadUrl = res.data.data;
      let fileName = name || "csv";
      const linkSource = `${downloadUrl}`;
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      downloadLink.href = linkSource;
      downloadLink.target = "_self";
      downloadLink.download = fileName;
      downloadLink.click();
    })
    .catch((error) => console.error(error));
};

export const uploadFile: any = (
  url: string,
  formData: FormData,
  method: MethodType = MethodType.POST,
  contentType = "multipart/form-data",
) => {
  switch (method) {
    case MethodType.PUT:
      return axiosInstance.put(url, formData, {
        headers: { "Content-Type": contentType },
      });
    default:
      return axiosInstance.post(url, formData, {
        headers: { "Content-Type": contentType },
      });
  }
};

export const onErrorMessage = (error: any) => {
  let message = error.message;
  if (error.response && error.response.data.errors) {
    const keysError = Object.keys(error.response.data.errors);
    if (keysError.includes("FATAL")) {
      message = error.response.data.errors.FATAL[0].message;
    }
    if (keysError.includes("UNKNOWN")) {
      message = error.response.data.errors.UNKNOWN[0].message;
    }
  }
  return message;
};

// Send as parameter response.data of throw Error
export const getAllErrorsAsString = (error: any) => {
  let errorsString = "";
  let data = error?.response?.data;
  try {
    if (data && data.errors) {
      const errorsArray: any[] = Object.values(data.errors);
      const lastIndex = errorsArray.length - 1;
      errorsArray.forEach((errorArray, index) => {
        errorArray.forEach((errorObj: any) => {
          errorsString += `${errorObj.message}${index < lastIndex ? "," : ""} `;
        });
      });
    }
  } catch (e) {}

  if (!errorsString) {
    errorsString = "An Error Occured";
  }

  return errorsString;
};

export const getAllErrorsKeys = (error: any) => {
  let errorsKeys: any = [];
  let data = error?.response?.data;
  try {
    if (data && data.errors) {
      const errorsArray: any[] = Object.values(data.errors);
      errorsArray.forEach((errorArray) => {
        errorArray.forEach((errorObj: any) => {
          errorsKeys.push(errorObj.key);
        });
      });
    }
  } catch (e) {}

  return errorsKeys;
};

// Return an array of objects as string concat with "," all properties
// sent as parameter of function
export const arrayInString = (array: any[], key: string) => {
  if (Array.isArray(array)) {
    let arrayAsString = "";
    array.forEach((element: any, index: number) => {
      arrayAsString += `${element[`${key}`]}${
        index < array.length - 1 ? ", " : ""
      }`;
    });
    if (arrayAsString.length === 0) {
      arrayAsString = "-";
    }
    return arrayAsString;
  }
};

// Return a File object
export const readFile = (file: string | URL): string | null => {
  let responseText = null;
  let rawFile = new XMLHttpRequest();
  rawFile.open("GET", file, false);
  rawFile.onreadystatechange = function () {
    if (rawFile.readyState === 4) {
      if (rawFile.status === 200 || rawFile.status === 0) {
        responseText = rawFile.responseText;
        return responseText;
      } else {
        console.error("error");
      }
    }
  };
  rawFile.send(null);
  return responseText;
};

// Enrollment API services returns with status 200 also errors, because some
// ids could go to OK and others could go to an error
// analize reponse and return an object with messages
export const getMessageObjectForModalEnrollementsAPI = (
  response: any,
  translations: {
    usersAddedLabel: string;
    usersNotAddedLabel: string;
    errorsOccuredLabel: string;
  },
) => {
  const { usersAddedLabel, usersNotAddedLabel, errorsOccuredLabel } =
    translations;
  let messageObject: {
    numberSaved: string;
    numberNotSaved: string;
    errorMessage: string;
    errorMessages: string[];
    genericMassiveMessage: string;
    noResponseReadable: boolean;
  } = {
    numberSaved: "",
    numberNotSaved: "",
    errorMessage: "",
    errorMessages: [],
    genericMassiveMessage: "",
    noResponseReadable: false,
  };
  if (response) {
    if (typeof response === "string") {
      // In massive enrollments API response is just a string, if string has a value
      // just render it, if not just render a simple message "Richiesta presa in carico"
      messageObject.genericMassiveMessage = response;
    }
    /* 
    Response from BE is like this example:
    {
      "numberSaved": 0,
      "numberNotSaved": 1,
      "errorMessage": "1 Ennrollment can not be saved because already exist"
    } 
    */
    if (response.numberSaved) {
      messageObject.numberSaved = `${usersAddedLabel} ${response.numberSaved}`;
    }
    if (response.numberRowSaved) {
      messageObject.numberSaved = `${usersAddedLabel} ${response.numberRowSaved}`;
    }
    if (response.numberNotSaved) {
      // if numberNotSaved is provided !== 0 somenthing with some user went wrong
      messageObject.numberNotSaved = `${usersNotAddedLabel} ${response.numberNotSaved}`;
    }
    if (response.numberRowNotSaved) {
      // if numberRowNotSaved is provided !== 0 somenthing with some user went wrong
      messageObject.numberNotSaved = `${usersNotAddedLabel} ${response.numberRowNotSaved}`;
    }
    if (response.errorMessage) {
      messageObject.errorMessage = `${errorsOccuredLabel} ${response.errorMessage}`;
    }
    if (response.errorMessages && Array.isArray(response.errorMessages)) {
      if (response.errorMessages.length > 0) {
        messageObject.errorMessages = [
          `${errorsOccuredLabel} `,
          ...response.errorMessages,
        ];
      }
    }
  } else {
    messageObject.noResponseReadable = true;
  }
  return messageObject;
};

export const getMessageObjectForModalMassiveEnrollmentAPI = (
  response: any,
  translations: {
    suggestionLabel: string;
  },
) => {
  const { suggestionLabel } = translations;
  let messageObject: {
    totalDistinctPart: string;
    error: string;
  } = {
    totalDistinctPart: "0",
    error: "",
  };
  if (response) {
    if (response.error) {
      messageObject.error = `${response.error}`;
    }
    if (response.totalDistinctPart) {
      messageObject.totalDistinctPart = `${suggestionLabel} ${response.totalDistinctPart}`;
    }
  }
  return messageObject;
};
