//REACT AND REACT BASED LIBRARIES
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import { Box, Container } from "@mui/material";
import AddEditFormCst from "@components/AddEditFormCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { ColumnInterface } from "@components/Table/types";
//CUSTOM HOOKS AND UTILS
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import useGroupsAssociationAddEdit from "@pages/Contents/Corporate/SelfSignup/hooks/useGroupsAssociationAddEdit";
import dayjs from "dayjs";

//LEGENDA
// !! IMPORTANTE/ CORE PAGINA
// ?? FACOLTATIVO/ DIPENDE DALLA NECESSITÀ
// ## USATO PER DATI MOCKATI, NON NECESSARIO UNA VOLTA RICEVUTI I SERVIZI BE

const EditRegistrationRule: React.FC = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["selfsignup-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE

  //?? HOOK PER PARAMETRI DA URL (IL NOME è LO STESSO DI QUELLO INSERITO IN ROUTER es. /:idLO)
  const { id, idConfig } = useParams();

  //?? HOOK PER NAVIGAER IN ALTRE PAGINE
  const navigate = useNavigate();

  //!! STATO PER GESTIONE MODALE DI CONFERMA/RISPOSTA/ERRORE
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  //!! CHIAMATA GET
  const { columns, mutate, dataDetail } = useFetchDetail(
    ServicesURL.getRule,
    `${idConfig}?corporate_id=${id}`,
    PagesID["corporate.selfsignup.edit"],
  );

  const {
    openAssociations,
    setOpenAssociation,
    handleAssociation,
    handleDelete,
    groupsAssociation,
    getValues,
  } = useGroupsAssociationAddEdit({ corporateId: id as string, dataDetail });

  //!! GESTIONE PER CHIAMATA PUT/POST/PATCH (A SECONDA DELLE NECESSITÀ)
  const { trigger } = useSWRMutation(
    `${ServicesURL.putRule}/${idConfig}?corporate_id=${id}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}`, MethodType.PUT, { ...arg.arg }); //SE NECESSARIO CAMBIARE METHODTYPE o AGGIUNGERE QUERY ALL'URL
    },
  );

  //!! FUNZIONE PER GESTIRE ONSUBMIT DELLA FORM
  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" }); //!!
    console.log("inputForm", inputForm);
    const inputFormCopy = {
      rule_name: inputForm["rule_name"],
      group_id: groupsAssociation[0]?.id
        ? Number(groupsAssociation[0]?.id)
        : null,
      expiration_registration_date:
        inputForm["expiration_registration_date"] || "",
      expiration_user_date: inputForm["expiration_user_date"] || "",
      max_uses: inputForm["max_uses"],
      domains: inputForm.domains, // domains: ["digited.it", ...]
      mode: inputForm.mode,
    };

    try {
      //!!CHIAMATA ON SUBMIT AWAIT
      console.info("CHIAMATA", inputFormCopy);
      await trigger(inputFormCopy);
      ////////
      setOpenToast({ s: StatusEnum.OK, message: "" }); //!!
    } catch (error) {
      setOpenToast(undefined); //!!
      console.error(error); //!!
    }
    mutate && mutate(inputForm); //!! REFRESH DEI DATI GET
  };

  const inputsException = (col: any, inputForm: any) => {
    if (col?.accessorKey === "domains") {
      col.required = inputForm["mode"] === "CODE_AND_DOMAIN";
      col.disabled = inputForm["mode"] !== "CODE_AND_DOMAIN";
    }
    return col;
  };

  // TODO: pulsante reset non ripopola i gruppi

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corporate.jpg" //PAGINA DI SFONDO
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              row={dataDetail || []}
              inputsException={inputsException}
              columns={columns.map((col) => {
                if (col.accessorKey === "mode") {
                  return { ...col, disabled: true };
                }
                // Non si può inserire un valore inferiore a count_uses (utenti già registrati)
                if (col.accessorKey === "max_uses") {
                  return {
                    ...col,
                    rules: {
                      minValue: {
                        value: Math.max(
                          dataDetail.count_uses,
                          col.rules?.minValue?.value || 1,
                        ),
                        msg: "invalid-number",
                      },
                    },
                  };
                }
                if (
                  col.accessorKey === "expiration_registration_date" ||
                  col.accessorKey === "expiration_user_date"
                ) {
                  return {
                    ...col,
                    minDate: dayjs(new Date(dataDetail?.creation_date)).add(
                      1,
                      "day",
                    ),
                  };
                }
                if (col.accessorKey === "domains") {
                  if (dataDetail?.mode === "CODE") {
                    return { ...col, disabled: true };
                  } else {
                    return { ...col, undeletableValues: dataDetail["domains"] }; // indica i chips per cui è disabilitato il tasto delete (in questo modo si possono solo eliminare i nuovi)
                  }
                }
                return col;
              })}
              onSubmit={onSubmit}
              actionsForForms={[]} //ABILITARE SOLO SE PRESENTE COLONNE ALTRIMENTI []
              backButton={() => {
                navigate(-1);
              }}
              setOpenAssociation={(accessorKey, open) =>
                setOpenAssociation(accessorKey, open)
              }
              handleAssociation={(accessorKey, value) =>
                handleAssociation(accessorKey, value)
              }
              getValues={getValues}
              handleDelete={handleDelete}
              association={openAssociations}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("edit.hero-title")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default EditRegistrationRule;
