import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { useFetchDataDetail, usePostDetail } from "@hooks/useFetchDetail";
import { useAuth } from "@context/auth";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";

const CustomFieldsForm: React.FC = () => {
  const { t } = useTranslation(["custom-fields-users"]);
  const { id } = useParams();
  const { user } = useAuth();
  const { columns, dataDetail, mutate } = useFetchDataDetail(
    `${ServicesURL.corporateCustomFields}?corporate_id=${id}`,
    PagesID["corporate.customFields"]
  );

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const navigate = useNavigate();

  const { trigger: triggerCustomer } = usePostDetail(
    `${ServicesURL.corporateCustomFields}?corporate_id=${id}`
  );

  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    try {
      await triggerCustomer(inputForm);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
    mutate && mutate(inputForm);
  };

  // Disable columns if permission is not write
  let readOnly: boolean = false;
  if (
    !user?.permissions.find(
      (permission) => permission === "usermgmt.corporates.custom_fields.write"
    )
  ) {
    readOnly = true;
  }

  return (
    <>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corporate.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              row={dataDetail.catalog}
              columns={columns}
              onSubmit={onSubmit}
              backButton={() => {
                navigate(`/corporate/${id}/detail`);
              }}
              readOnly={readOnly}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </>
  );
};

export default CustomFieldsForm;
